import { z } from "zod";
import { messages } from "@/config/messages";
import { validateEmail } from "@/utils/validators/common-rules";
import formatPhoneNumber from "@/utilities/functions/format-phone-number";
const isValidUrl = (urlString: string): boolean => {
  try {
    new URL(urlString);
    return true;
  } catch (e) {
    return false;
  }
};

// form zod validation schema
export const editUserSchema = z.object({
  firstName: z.string().min(1, { message: messages.firstNameRequired }),
  lastName: z.string().min(1, { message: messages.lastNameRequired }),
  email: validateEmail,
  photo: z
    .object({
      id: z.string().optional(),
      url: z.string().optional(),
    })
    .nullable(),
  adRerportUrl: z
    .string()
    .optional()
    .refine((val) => val === "" || (val && isValidUrl(val)), {
      message: "Invalid URL",
    }),
  // measuringId: z.string().nullable().optional(),
  headerScript: z.string().optional(),
  bodyScript: z.string().optional(),
  enquiryScript: z.string().optional(),

  domain: z
    .string()
    .refine(
      (value) =>
        /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,11}?$/.test(`${value}`),
      "This is domain field"
    )
    .optional(),
  phone: z
    .string({ message: "Phone Number is require" })
    .trim()
    .transform((val) => {
      const format = formatPhoneNumber(val);
      return format.replace(/\s+/g, "");
    })
    .refine((val) => val && val.length === 8, {
      message: "Invalid phone number",
    }),
});

// generate form types from zod validation schema
export type EditUserSchema = z.infer<typeof editUserSchema>;
