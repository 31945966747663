import { Controller, useFormContext } from "react-hook-form";
import { Switch } from "rizzui";

export interface DirectoryPageProps {
  isSuperAdmin?: boolean;
  name: string;
}

export default function DirectoryPage({
  isSuperAdmin,
  name,
}: DirectoryPageProps) {
  const { control } = useFormContext();

  return (
    <div className="grid grid-cols-1 gap-2 w-full">
      <div className="grid w-full items-start gap-32grid-cols-1">
        <h6 className="text-foreground md:text-base">Directory Module</h6>
      </div>
      <div className="grid w-full items-start gap-3 grid-cols-1">
        {isSuperAdmin && (
          <Controller
            control={control}
            name={`${name}.enable`}
            render={({ field, fieldState, formState }) => (
              <Switch
                label="Enable"
                size="lg"
                className=" w-auto"
                {...field}
                {...formState}
                checked={field.value || ""}
                error={fieldState.error?.message}
              />
            )}
          />
        )}
      </div>
    </div>
  );
}
