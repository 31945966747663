import { LDP_DOMAIN, LDP_SUBDOMAINS } from "@/config/constants";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import { BiPlanet, BiRocket } from "react-icons/bi";
import DomainConfigModal, { CustomizeDomainForm } from "./DomainConfigModal";
import DomainOption from "./DomainOption";

const DomainCustomization = ({
  projectId,
  domainInitialValues,
  onSave,
}: {
  projectId: string;
  domainInitialValues: CustomizeDomainForm;
  onSave?: (values: CustomizeDomainForm) => Promise<void>;
}) => {
  const [selectedOption, setSelectedOption] = useState<
    "custom" | "subdomain" | null
  >(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCustomDomain = () => {
    toast("Coming soon");
    // setSelectedOption("custom");
    // setIsModalOpen(true);
  };

  const handleSubdomain = () => {
    setSelectedOption("subdomain");
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedOption(null);
  };

  const form = useFormContext();

  const handleSubmit = async (values: CustomizeDomainForm) => {
    setIsModalOpen(false);
    form.setValue("domain", values);

    await onSave?.(values);
  };

  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-2xl font-semibold text-center mb-4">
        How to Publish Your Page?
      </h1>

      <div className="text-center text-gray-500 mb-8">
        SECURELY HOSTED BY US
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        <DomainOption
          icon={BiPlanet}
          title="Custom Domain"
          description="Use your own domain to deploy your website"
          onChoose={handleCustomDomain}
        />

        <DomainOption
          icon={BiRocket}
          title="Subdomain"
          description={`Use a free subdomain using our <strong>${LDP_DOMAIN}</strong> name`}
          onChoose={handleSubdomain}
        />
      </div>

      {isModalOpen && (
        <DomainConfigModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          type={selectedOption || "custom"}
          onSubmit={handleSubmit}
          suffixDomains={LDP_SUBDOMAINS}
          defaultSuffixDomain={LDP_SUBDOMAINS[0]}
          projectId={projectId}
          initialValues={domainInitialValues}
        />
      )}
    </div>
  );
};

export default DomainCustomization;
