import { useAuthHelper } from "@/components/auth/helper";
import PaymentDone from "@/components/payment/PaymentDone";
import Checkout from "@/components/pricing-plans/checkout";
import PricingPlans, {
  E_PRICING_PLANS,
} from "@/components/pricing-plans/pricing-plans";
import SettingsRepository from "@/utilities/repositories/Settings";
import UserRepository from "@/utilities/repositories/Users";
import { SignInResponse } from "@/utilities/types/Auths";
import { RegisterAgencyInput } from "@/utils/validators/create-user.schema";
import React from "react";
import toast from "react-hot-toast";
import { BiCreditCard } from "react-icons/bi";
import { FaFaceSmile } from "react-icons/fa6";
import { HiDocumentMagnifyingGlass } from "react-icons/hi2";
import { PiUserCircleGearDuotone } from "react-icons/pi";
import { Stepper } from "rizzui";
import SignUp from "../sign-up/SignUpPage";
import Logo from "@/components/logo";

export interface Plan {
  duration: E_PRICING_PLANS;
  price: number;
  monthlyPrice: number;
  description: string;
  isPopular: boolean;
}

const step = [
  {
    title: "Account Creation",
    description: "Create your account",
    icon: <PiUserCircleGearDuotone className="h-5 w-5" />,
  },
  {
    title: "Select Plan",
    description: "Choose your package",
    icon: <HiDocumentMagnifyingGlass className="h-5 w-5" />,
  },
  {
    title: "Payment",
    description: "Complete payment",
    icon: <BiCreditCard className="h-5 w-5" />,
  },
  {
    title: "Done",
    description: "Payment successful",
    icon: <FaFaceSmile className="h-5 w-5" />,
  },
];

export default function RegistrationStepper() {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [selectedPlan, setSelectedPlan] = React.useState<Plan | null>(null);

  const { saveAuth, clearAuth } = useAuthHelper();

  const handlePlanSelection = (plan: Plan) => {
    setSelectedPlan(plan);
    setCurrentStep(2);
  };

  const [loading, setLoading] = React.useState(false);

  const handleSettingAgencySetting = async (
    data: SignInResponse,
    salesTeamInfo: RegisterAgencyInput["salesTeamInfo"]
  ) => {
    try {
      const { user } = data || {};
      const subdomain = await SettingsRepository.generateUniqueAgencySubdomain(
        user?.id || "",
        user?.email || ""
      );
      const response = await SettingsRepository.createAgencySetting({
        domain: subdomain || "",
        siteTitle: "",
        siteDescription: "",
        salesTeamInfo,
        phoneNumber: user?.phone || "",
      });
      //
    } catch (error: any) {
      throw new Error("Failed to create agency setting");
    }
  };

  const handleSignUpComplete = async (
    data: SignInResponse,
    salesTeamInfo: RegisterAgencyInput["salesTeamInfo"]
  ) => {
    try {
      setLoading(true);
      saveAuth(data); // Save the auth token
      await handleSettingAgencySetting(data, salesTeamInfo); // Create the agency setting
      setCurrentStep(1); // Go to the next step
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleBackToPlans = () => {
    setCurrentStep(1);
  };

  const handlePaymentComplete = async () => {
    try {
      setLoading(true);
      const response = await UserRepository.updateMe({
        pricingPlans: "NAVISVIP",
        statusAccount: "active",
      });
      setCurrentStep(3);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <SignUp onComplete={handleSignUpComplete} />;
      case 1:
        return <PricingPlans onPlanSelect={handlePlanSelection} />;
      case 2:
        return selectedPlan ? (
          <Checkout
            planName={selectedPlan.duration as E_PRICING_PLANS}
            price={selectedPlan.price}
            monthlyPrice={selectedPlan.monthlyPrice}
            onBack={handleBackToPlans}
            onComplete={handlePaymentComplete}
          />
        ) : null;
      case 3:
        return <PaymentDone />;
      default:
        return null;
    }
  };

  const beforeUnload = async () => {
    await clearAuth();
  };

  React.useEffect(() => {
    window.addEventListener("beforeunload", beforeUnload);
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, []);

  return (
    <div className="container mx-auto max-w-screen-xl px-4 py-16">
      <div className="mb-12 flex w-full items-center justify-center">
        <Logo className="min-w-[200px]" />
      </div>
      <div className="mb-12 w-full">
        <Stepper currentIndex={currentStep} className="w-full">
          {step.map((item, idx) => (
            <Stepper.Step
              key={idx}
              variant="outline"
              active={currentStep === idx}
              completed={currentStep > idx}
              title={item.title}
              description={item.description}
              icon={item.icon}
            />
          ))}
        </Stepper>
      </div>
      {renderStep()}
    </div>
  );
}
