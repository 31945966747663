import { EFeatureMode, IUserFeature } from "./AdvanceFeature";
import { TImageAsset } from "./Asset";
import { TAgencySetting, TDomain } from "./Setting";

export enum Roles {
  ADMIN = "admin",
  AGENCY = "agency",
  USER = "user",
}

export type IUserData = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  email: string;
  status: string;
  role: Roles;
  firstName: string;
  lastName: string;
  phone: string | null;
  photo: TImageAsset | null;
  photoId: string | null;
  whatsapp: string;
  domains: TDomain[];
  company?: string;
  additionalEmail: null | string[];
  // measuringId?: null | string;
  headerScript?: string | null;
  bodyScript?: string | null;
  enquiryScript?: string | null;
  config?: TAgencySetting;
  password?: string;
  userFeatures?: IUserFeature[];
  userFeature?: IUserFeature[];
  dynamicLayoutMode?: EFeatureMode;

  agencyLogoId?: string | null;
  agencyLogo?: TImageAsset;
  adRerportUrl?: string | null;
  isFlowSignUp: boolean;
  pricingPlans: string | null;
  statusAccount: "active" | "inactive";
};

export type IUserDataResponse = {
  items: IUserData[];
  total: number;
  totalPage: number;
  currentPage: number;
  limit: number;
};

export type CreateUserDto = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone?: string;
  role: Roles;
  additionalEmail?: string[];
  // measuringId?: null | string;
  headerScript?: string;
  bodyScript?: string;
  enquiryScript?: string;
  isFlowSignUp?: boolean;
  pricingPlans?: string | null;
  statusAccount?: "active" | "inactive";
};

export type TSetPasswordDto = {
  userId: string;
  password: string;
};
