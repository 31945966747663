import { z } from "zod";

export const mediaSchema = z.object({
  id: z.string().min(1, { message: "This field is required" }),
  size: z.number().or(z.string()).optional(),
  url: z.string().min(1, { message: "This field is required" }),
  name: z.string().optional(),
  type: z.string().min(1, { message: "This field is required" }),
});

export const mediasSchema = z.array(mediaSchema);
export type MediaSchema = z.infer<typeof mediaSchema>;
export type MediasSchema = z.infer<typeof mediasSchema>;
