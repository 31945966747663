import { SUFFIX_DOMAIN } from "@/config/constants";
import { motion } from "framer-motion";
import { HiCheckCircle } from "react-icons/hi2";
import { Button } from "rizzui";
import { useAuthHelper } from "../auth/helper";

export default function PaymentDone() {
  const signInHref = `https://admin${SUFFIX_DOMAIN}/sign-in`;

  const { clearAuth } = useAuthHelper();

  const navigateToSignIn = async () => {
    await clearAuth();
    window.location.replace(signInHref);
  };

  return (
    <div className="min-h-[60vh] flex items-center justify-center">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-center max-w-2xl mx-auto p-8"
      >
        <div className="flex justify-center mb-8">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
          >
            <HiCheckCircle className="w-24 h-24 text-green-500" />
          </motion.div>
        </div>

        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="text-3xl font-bold mb-4"
        >
          Payment Pending!
        </motion.h1>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="mb-8"
        >
          <p className="text-gray-600 text-lg font-medium">
            While we are verifying your payment, you may start creating your
            landing page.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="space-y-4"
        >
          <Button onClick={navigateToSignIn} size="lg">
            Login to Dashboard
          </Button>
        </motion.div>
      </motion.div>
    </div>
  );
}
