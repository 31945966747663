"use client";

import CustomPopover from "@/app/shared/custom-popover";
import DeletePopover from "@/app/shared/delete-popover";
import EyeIcon from "@/components/icons/eye";
import PencilIcon from "@/components/icons/pencil";
import UserSettingsIcon from "@/components/icons/user-settings";
import { HeaderCell } from "@/components/ui/table";
import { routes } from "@/config/routes";
import { SortConfig } from "@/hooks/useTableServer";
import { getParamsCurrentPage } from "@/utilities/functions";
import { IUserData, Roles } from "@/utilities/types/Users";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { ActionIcon, Badge, Button, SelectOption, Tooltip } from "rizzui";
import SelectDomain from "@/app/app-shared/user/users-table/select-domain.tsx";
import StatusAccount from "./status-account";
import UserRepository from "@/utilities/repositories/Users";
import { findFeatureByType } from "../../advanced-features/ultil";
import { EFeatureType } from "@/utilities/types/AdvanceFeature";

type UserSortBy = "email" | "location";

type Columns = {
  data: IUserData[];
  sortConfig?: SortConfig<UserSortBy>;
  onDeleteItem: (id: string) => void;
  onHeaderCellClick?: (value: string) => void;
  onLoginAccountAgent: (email: string) => Promise<void>;
  handleReset: () => void;
  refresh?: () => void;
};

export const getColumns = ({
  sortConfig,
  onDeleteItem,
  onHeaderCellClick,
  onLoginAccountAgent,
  handleReset,

  refresh,
}: Columns) => [
  {
    title: <HeaderCell title="Name" />,

    dataIndex: "lastName",
    key: "lastName",
    render: (_: string, row: IUserData) => {
      return row.firstName + " " + row.lastName;
    },
  },

  {
    title: (
      <HeaderCell
        title="Email"
        sortable
        align="center"
        ascending={
          sortConfig?.direction === "asc" && sortConfig?.key === "email"
        }
      />
    ),
    dataIndex: "email",
    key: "email",
    align: "center",
    onHeaderCell: () => onHeaderCellClick?.("email"),
  },

  {
    title: "Domain",
    dataIndex: "domain",
    key: "domain",
    align: "center",
    render: (_: string, row: IUserData) => {
      const domain = row?.domains?.find((item) => !!item.primary);

      const isHasDirectoryPermission = !!row?.userFeature?.find(
        (item) => item?.feature?.type === EFeatureType.Directory
      )?.enabled;

      if (!isHasDirectoryPermission) return <></>;

      const mapOptions = row?.domains?.map((item) => {
        return {
          primary: item.primary,
          value: item.id,
          label: item.name,
          configID: item.configId,
        };
      });

      let defaultOptions = null;
      domain &&
        (defaultOptions = {
          primary: domain.primary,
          value: domain.id,
          label: `${domain.name}`,
          configID: domain.configId,
        });

      return (
        mapOptions?.length > 0 && (
          <SelectDomain
            options={mapOptions}
            defaultOptions={defaultOptions}
            configID={row.domains}
            reset={refresh}
          />
        )
      );
    },
  },

  {
    title: "Status",
    dataIndex: "statusAccount",
    key: "statusAccount",
    width: 140,

    align: "center",
    render: (_: string, row: IUserData) => {
      return (
        <StatusAccount
          onChange={async (value: SelectOption) => {
            await UserRepository.updateUser(row.id, {
              statusAccount: value.value as "active" | "inactive",
            });
            await refresh?.();
          }}
          status={row.statusAccount}
        />
      );
    },
  },
  {
    title: (
      <HeaderCell
        title="Registered"
        sortable
        align="center"
        ascending={
          sortConfig?.direction === "asc" && sortConfig?.key === "createdAt"
        }
      />
    ),
    onHeaderCell: () => onHeaderCellClick?.("createdAt"),

    dataIndex: "createdAt",
    key: "createdAt",
    align: "center",
    render: (_: string, row: IUserData) => {
      return dayjs(row.createdAt).format("MMMM DD, YYYY");
    },
  },

  {
    title: <HeaderCell title="Login account Agent" align="center" />,

    dataIndex: "signIn",
    key: "signIn",
    align: "center",
    render: (_: string, row: IUserData) => {
      return (
        <CustomPopover
          title={`Login account Agent`}
          description={`This action will terminate agent's session. Are you sure you want to continue?`}
          onAccept={async () => {
            await onLoginAccountAgent(row.email);
          }}
        >
          <Button onClick={() => {}}>Login</Button>
        </CustomPopover>
      );
    },
  },

  {
    title: (
      <HeaderCell
        className="ps-3"
        title={<span className="whitespace-nowrap">Actions</span>}
      />
    ),
    dataIndex: "action",
    key: "action",
    width: 120,
    render: (_: string, row: IUserData) => {
      return (
        <div className="flex items-center justify-end gap-3 pe-4">
          <Tooltip
            size="sm"
            content={"Edit User"}
            placement="top"
            color="invert"
          >
            <Link
              to={{
                pathname: routes.user.edit(row.id),
                search: getParamsCurrentPage({ rowKey: row?.id }),
              }}
            >
              <ActionIcon size="sm" variant="outline">
                <PencilIcon className="h-4 w-4" />
              </ActionIcon>
            </Link>
          </Tooltip>

          <Tooltip
            size="sm"
            content={"Advance Features"}
            placement="top"
            color="invert"
          >
            <Link
              to={{
                pathname: routes.user.advanceFeature(row.id),
                search: getParamsCurrentPage({ rowKey: row?.id }),
              }}
            >
              <ActionIcon size="sm" variant="outline">
                <UserSettingsIcon className="h-4 w-4" />
              </ActionIcon>
            </Link>
          </Tooltip>

          <Tooltip
            size="sm"
            content={"Login Activity"}
            placement="top"
            color="invert"
          >
            <Link
              to={{
                pathname: routes.user.loginActivity(row.id),
                search: getParamsCurrentPage({ rowKey: row?.id }),
              }}
              state={{
                user: row,
              }}
            >
              <ActionIcon size="sm" variant="outline">
                <EyeIcon className="h-4 w-4" />
              </ActionIcon>
            </Link>
          </Tooltip>
          <DeletePopover
            disabled={row.role === Roles.ADMIN}
            title={`Delete User`}
            description={`Are you sure you want to delete this user?`}
            onDelete={() => onDeleteItem(row.id)}
          />
        </div>
      );
    },
  },
];
