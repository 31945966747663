import { Form } from "@/components/ui/form";
import formatPhoneNumber from "@/utilities/functions/format-phone-number";
import {
  agencyLoginSchema,
  AgencyLoginSchema,
} from "@/utils/validators/login.schema";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { PiArrowRightBold } from "react-icons/pi";
import { Button, Input } from "rizzui";
import { useAuthHelper } from "./helper";
import { COUNTRY_SG_CODE } from "@/config/constants";

export default function AgentSignInForm() {
  const { sendOtpByPhone } = useAuthHelper();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (data: AgencyLoginSchema) => {
    if (isSubmitted) return; // Prevent further submissions after success
    setIsSubmitting(true);
    const phoneWithCountryCode = `${COUNTRY_SG_CODE}${data.phone}`;
    try {
      await sendOtpByPhone({
        phone: phoneWithCountryCode,
      });
      setIsSubmitted(true); // Mark form as successfully submitted
    } catch (error) {
      console.error("Error during form submission", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Form<AgencyLoginSchema>
        validationSchema={agencyLoginSchema}
        onSubmit={handleSubmit}
      >
        {({ register, formState: { errors }, control }) => (
          <div className="space-y-5">
            <Controller
              control={control}
              name="phone"
              render={({ field, fieldState, formState }) => (
                <Input
                  label="Phone"
                  type="text"
                  prefix="+65"
                  size="lg"
                  placeholder="Enter phone number"
                  className="col-span-full"
                  {...field}
                  {...formState}
                  value={formatPhoneNumber(field?.value)}
                  error={fieldState.error?.message}
                />
              )}
            />

            {/* <div className="flex items-center justify-between pb-2">
             
              <Link
                to={routes.auth.forgotPassword}
                className="h-auto p-0 text-sm font-semibold text-blue underline transition-colors hover:text-gray-900 hover:no-underline"
              >
                Forget Password?
              </Link>
            </div> */}
            <Button
              className="w-full"
              type="submit"
              size="lg"
              disabled={isSubmitting || isSubmitted} // Disable on submitting or after successful submit
            >
              <span>{isSubmitting ? "Sending OTP..." : "Send OTP"}</span>
              {!isSubmitting && !isSubmitted && (
                <PiArrowRightBold className="ms-2 mt-0.5 h-5 w-5" />
              )}
            </Button>
          </div>
        )}
      </Form>
    </>
  );
}
