import { siteConfig } from "@/config/site.config";
import { Link } from "react-router-dom";
import NotFoundImg from "@public/not-found.png";
import { Button, Title } from "rizzui";
import { PiHouseLineBold } from "react-icons/pi";
import SocialItems from "@/components/ui/social-shares";
import useProfile from "@/hooks/use-profile";
import { findAccessiblePath } from "@/utilities/functions";
import Logo from "@/components/logo";
function NotFound() {
  // react-auth-kit will auto navigate to sign in page if not authenticated
  const { profile } = useProfile();
  const defaultPath = findAccessiblePath(profile?.role);

  return (
    <div className="flex min-h-screen flex-col bg-[#F8FAFC]">
      <div className="sticky top-0 z-40 flex justify-center py-5 backdrop-blur-lg lg:backdrop-blur-none xl:py-10">
        <Logo className="min-w-[200px]" />
      </div>

      <div className="flex grow items-center px-6 xl:px-10">
        <div className="mx-auto text-center">
          <img
            src={NotFoundImg}
            alt="not found"
            className="mx-auto mb-8 aspect-[360/326] max-w-[256px] xs:max-w-[370px] lg:mb-12 2xl:mb-16"
          />
          <Title
            as="h1"
            className="text-[22px] font-bold leading-normal text-gray-1000 lg:text-3xl"
          >
            Sorry, the page not found
          </Title>

          <Link to={defaultPath || "/"}>
            <Button
              as="span"
              size="xl"
              color="primary"
              className="mt-8 h-12 px-4 xl:h-14 xl:px-6"
            >
              <PiHouseLineBold className="mr-1.5 text-lg" />
              Back to home
            </Button>
          </Link>
        </div>
      </div>
      {/* <SocialItems /> */}
    </div>
  );
}

export default NotFound;
