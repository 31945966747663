import { Enum } from "./Enum";

export const E_ENQUIRY = Object.freeze({
  MAIN: "/contact-sale-submission",
  GET_CEA_DETAILS: "/contact-sale-submission/get-cea-details",

  DETAIL: "/contact-sale-submission/:id",
  LOGS: "/contact-sale-submission/logs/:id",
});

export type E_ENQUIRY = Enum<typeof E_ENQUIRY>;
