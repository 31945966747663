import useProfile from "@/hooks/use-profile";
import { useState } from "react";

export default function AdReportPage() {
  const { profile } = useProfile();
  const [isLoading, setIsLoading] = useState(true);

  if (!profile?.adRerportUrl) {
    return <></>;
  }

  return (
    <div className="h-screen w-full">
      {isLoading && (
        <div className="flex items-center justify-center h-full">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
        </div>
      )}
      <iframe
        src={profile.adRerportUrl}
        style={{
          minHeight: "100svh",
        }}
        className={`w-full min-h-screen flex justify-center items-center h-full border-0 ${isLoading ? "hidden" : ""}`}
        title="Ad Report"
        onLoad={() => setIsLoading(false)}
      />
    </div>
  );
}
