import useProfile from "@/hooks/use-profile";
import useApi from "@/hooks/useApi";
import RoundRobinRepository from "@/utilities/repositories/RoundRobin";
import { ETimeSlotConfigMode } from "@/utilities/types/RoundRobin";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { Select, SelectOption } from "rizzui";

export interface TimeSlotConfigModeProps {}

export default function TimeSlotConfigMode(props: TimeSlotConfigModeProps) {
  const { profile } = useProfile();
  const {
    request: getTimeSlotConfig,
    response,
    loading,
    refetch,
  } = useApi({
    request: RoundRobinRepository.getTimeSlotConfig,
  });

  const { request: updateTimeSlotConfig, loading: updating } = useApi({
    request: RoundRobinRepository.updateTimeSlotConfig,
  });

  useEffect(() => {
    if (!profile?.id) return;
    getTimeSlotConfig(profile.id)
      .then((response) => {
        if (!response?.data) {
          // Means no data found => create new config
          updateTimeSlotConfig({
            id: profile.id,
            data: {
              mode: ETimeSlotConfigMode.DEFAULT,
            },
          });
        }
      })
      .catch((err) => {
        // Error =>
      });
  }, [profile?.id]);

  return (
    <Select
      disabled={loading || updating}
      options={[
        { value: ETimeSlotConfigMode.ROUND_ROBIN, label: "Round Robin" },
        { value: ETimeSlotConfigMode.DEFAULT, label: "Default" },
        { value: ETimeSlotConfigMode.OFF, label: "Off" },
      ]}
      label="Mode"
      onChange={(value) => {
        if (!profile?.id) {
          toast.error("Not found agency profile");
          return;
        }
        updateTimeSlotConfig({
          id: profile.id,
          data: {
            mode: value,
          },
        }).then(async () => {
          await refetch();
        });
      }}
      getOptionValue={(option: SelectOption) => option.value}
      displayValue={(value: string) => {
        switch (value) {
          case ETimeSlotConfigMode.ROUND_ROBIN:
            return "Round Robin";
          case ETimeSlotConfigMode.DEFAULT:
            return "Default";
          case ETimeSlotConfigMode.OFF:
            return "Off";
          default:
            return "";
        }
      }}
      value={response?.data?.mode || ETimeSlotConfigMode.DEFAULT}
    />
  );
}
