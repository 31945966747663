export function emailToSubdomain(email: string) {
  const prefix = email.split("@")[0]; // Get the email prefix

  return prefix
    .normalize("NFKD") // Normalize to decomposed form
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritic marks
    .replace(/đ/g, "d") // Convert Vietnamese-specific characters
    .replace(/[^a-z0-9-]/gi, "") // Remove invalid characters
    .replace(/^-+|-+$/g, "") // Remove leading/trailing hyphens
    .toLowerCase()
    .slice(0, 63); // Limit to 63 characters (subdomain max length)
}

export function toValidSubdomain(str: string) {
  return str
    .toLowerCase()
    .replace(/[^a-z0-9-]/g, "") // Remove invalid characters
    .replace(/^-+|-+$/g, "") // Remove leading/trailing hyphens
    .slice(0, 63); // Limit to 63 characters (subdomain max length)
}
