import React from "react";
import cn from "@/utils/class-names";
import { Button, Text, Title } from "rizzui";
import { Plan } from "@/page/registration/RegistrationStepper";

export enum E_PRICING_PLANS {
  "1_MONTH" = "1 Month",
  "6_MONTHS" = "6 Months",
  "12_MONTHS" = "12 Months",
}
interface PlanProps {
  duration: E_PRICING_PLANS;
  price: number;
  monthlyPrice: number;
  description: string;
  features?: string[];
  isPopular?: boolean;
  onGetStarted?: () => void;
}

const PricingPlan: React.FC<PlanProps> = ({
  duration,
  price,
  monthlyPrice,
  description,
  isPopular,
  onGetStarted,
}) => {
  return (
    <div className={cn("relative", isPopular && "py-5")}>
      <div
        className={cn(
          "rounded-2xl flex flex-col gap-6 h-full p-8 transition-all duration-200",
          isPopular
            ? "bg-blue-600 shadow-[0_8px_28px_rgba(59,130,246,0.25)]"
            : "bg-white shadow-[0_4px_20px_rgba(0,0,0,0.08)] hover:shadow-[0_8px_28px_rgba(0,0,0,0.12)]"
        )}
      >
        {isPopular && (
          <Title as="h5" className={cn("text-lg", isPopular && "text-white")}>
            Most Popular!
          </Title>
        )}
        <Title as="h3" className={cn("text-2xl", isPopular && "text-white")}>
          {duration}
        </Title>
        <div className="mt-4">
          <Text className={cn("text-3xl font-bold", isPopular && "text-white")}>
            ${price}
          </Text>
          <Text
            className={cn(
              "ml-2 text-lg",
              isPopular ? "text-gray-100" : "text-gray-500"
            )}
          >
            ${monthlyPrice}/mo
          </Text>
        </div>
        <Text
          className={cn(
            "my-4 text-lg",
            isPopular ? "text-gray-100" : "text-gray-600"
          )}
        >
          {description}
        </Text>
        <div className="flex flex-col gap-3 mt-10">
          <Title as="h3" className={cn("text-lg", isPopular && "text-white")}>
            Unlimited Website Creation
          </Title>
          <Button
            onClick={onGetStarted}
            rounded="lg"
            className={cn(
              "mt-auto my-8 w-full font-bold rounded-full max-w-fit",
              isPopular
                ? "bg-white text-black hover:bg-gray-100"
                : "bg-black text-white hover:bg-gray-900"
            )}
            size="lg"
            variant={isPopular ? "flat" : "solid"}
          >
            Get Started
          </Button>
        </div>
      </div>
    </div>
  );
};

interface PricingPlansProps {
  onPlanSelect?: (plan: Plan) => void;
}

const PricingPlans: React.FC<PricingPlansProps> = ({ onPlanSelect }) => {
  const plans = [
    {
      duration: E_PRICING_PLANS["1_MONTH"],
      price: 150,
      monthlyPrice: 150,
      description:
        "Launch your new launch website for 3 months and start showcasing properties with ease.",
      isPopular: false,
    },
    {
      duration: E_PRICING_PLANS["6_MONTHS"],
      price: 720,
      monthlyPrice: 120,
      description:
        "Keep your website running smoothly for 6 months and continue building your online presence.",
      isPopular: false,
    },
    {
      duration: E_PRICING_PLANS["12_MONTHS"],
      price: 1200,
      monthlyPrice: 100,
      description:
        "Enjoy a full year of seamless new launch website management to drive long-term success.",
      isPopular: true,
    },
  ];

  return (
    <div className="container max-w-screen-xl w-full mx-auto px-4">
      <Title as="h2" className="mb-12 text-center text-4xl">
        Package to suit your plan.
      </Title>
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {plans.map((plan) => (
          <PricingPlan
            key={plan.duration}
            {...plan}
            onGetStarted={() => onPlanSelect?.(plan)}
          />
        ))}
      </div>
    </div>
  );
};

export default PricingPlans;
