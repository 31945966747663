import CreateUserLP from "@/app/shared/roles-permissions/create-user-lp";
import { SignInResponse } from "@/utilities/types/Auths";
import { RegisterAgencyInput } from "@/utils/validators/create-user.schema";

interface SignUpProps {
  onComplete?: (
    userData: SignInResponse,
    salesTeamInfo: RegisterAgencyInput["salesTeamInfo"]
  ) => Promise<void>;
}

export default function SignUp({ onComplete }: SignUpProps) {
  return (
    <div className="container mx-auto">
      <div className="py-2">
        <CreateUserLP
          onComplete={async (userData, salesTeamInfo) =>
            await onComplete?.(userData, salesTeamInfo)
          }
        />
      </div>
    </div>
  );
}
