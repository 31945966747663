"use client";

import { Form } from "@/components/ui/form";
import formatPhone from "@/utilities/functions/format-phone";
import formatPhoneNumber from "@/utilities/functions/format-phone-number";
import AuthRepository from "@/utilities/repositories/Auths";
import EnquiryEntriesRepository from "@/utilities/repositories/EnquiryEntries";
import { SignInResponse } from "@/utilities/types/Auths";
import { Roles } from "@/utilities/types/Users";
import {
  RegisterAgencyInput,
  registerAgencySchema,
} from "@/utils/validators/create-user.schema";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Input, Password, Title } from "rizzui";

export default function CreateUserLP({
  onComplete,
}: {
  onComplete?: (
    responseUser: SignInResponse,
    salesTeamInfo: RegisterAgencyInput["salesTeamInfo"]
  ) => Promise<void>;
}) {
  const [reset, setReset] = useState({
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    phone: "",
    salesTeamInfo: {
      name: "",
      registrationNumber: "",
      currentEa: "",
      licenseNumber: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loadState, setLoadState] = useState(false);

  const createUser = async (data: RegisterAgencyInput) => {
    try {
      setIsLoading(true);

      const { salesTeamInfo, ...userRegister } = data;
      const responseUser = await AuthRepository.signUp({
        ...userRegister,
        phone: userRegister.phone
          ? formatPhone(userRegister.phone).trim().split(" ").join("")
          : "",
        role: Roles.AGENCY,
      });
      toast.success(`Sign up successfully`);

      await onComplete?.(responseUser.data, salesTeamInfo);
      setIsLoading(false);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
      setIsLoading(false);
    }
  };
  const onSubmit: SubmitHandler<RegisterAgencyInput> = (data) => {
    createUser(data);
  };

  useEffect(() => {
    setReset({
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      phone: "",
      salesTeamInfo: {
        name: "",
        registrationNumber: "",
        currentEa: "",
        licenseNumber: "",
      },
    });
    setLoadState(false);
  }, [loadState]);

  useEffect(() => {
    setLoadState(true);
  }, []);

  const [filledCEA, setFilledCEA] = useState(false);
  const [ceaDetails, setCeaDetails] = useState<any | null>(null);

  const onFetchCeaDetails = async (phoneNumber: string) => {
    try {
      const ceaDetails = await EnquiryEntriesRepository.getCeaDetails({
        phoneNumber,
      });
      if (ceaDetails) {
        setFilledCEA(true);
        setCeaDetails(ceaDetails);
        return ceaDetails;
      }
    } catch (error: any) {
      setFilledCEA(false);
      setCeaDetails(null);
      throw new Error(error?.message || "CEA information not found");
    }
  };

  return (
    <Form<RegisterAgencyInput>
      resetValues={reset}
      onSubmit={(data) => {
        return onSubmit(data);
      }}
      onInvalid={(errors) => {
        if (
          errors.salesTeamInfo &&
          Object.keys(errors).length === 1 &&
          !filledCEA
        ) {
          toast.error("Please get CEA details");
        }
      }}
      useFormProps={{
        mode: "onChange",
      }}
      validationSchema={registerAgencySchema}
    >
      {({ register, control, formState: { errors }, setValue }) => {
        return (
          <div className="flex flex-col gap-6 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900 form-create-user">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div className="col-span-full">
                <Title as="h4" className="font-semibold">
                  Agent Information
                </Title>
              </div>
              <Input
                label="First Name"
                size="lg"
                placeholder="Enter user's first name"
                {...register("firstName")}
                className="[&>label>span]:font-medium "
                error={errors.firstName?.message}
              />
              <Input
                label="Last Name"
                size="lg"
                placeholder="Enter user's last name"
                {...register("lastName")}
                className="[&>label>span]:font-medium "
                error={errors.lastName?.message}
              />
              <Input
                label="Email"
                type="text"
                size="lg"
                placeholder="Enter user's Email Address"
                {...register("email")}
                autoComplete="off"
                className="[&>label>span]:font-medium col-span-full"
                error={errors.email?.message}
              />
              <Password
                label="Password"
                placeholder="Enter your password"
                size="lg"
                className="col-span-full"
                inputClassName="text-sm"
                {...register("password")}
                error={errors.password?.message}
              />
              <Controller
                control={control}
                name="phone"
                render={({ field, fieldState, formState }) => (
                  <Input
                    label="Phone number"
                    type="text"
                    prefix="+65"
                    suffix={
                      filledCEA ? (
                        <Button
                          variant="solid"
                          size="sm"
                          onClick={() => {
                            setFilledCEA(false);
                            setCeaDetails(null);
                            setValue("salesTeamInfo", {
                              name: "",
                              registrationNumber: "",
                              currentEa: "",
                              licenseNumber: "",
                            });
                          }}
                        >
                          Change
                        </Button>
                      ) : (
                        <Button
                          variant="solid"
                          size="sm"
                          disabled={!field.value || !!fieldState?.invalid}
                          onClick={async () => {
                            const phoneNumber = formatPhone(field.value)
                              .trim()
                              .split(" ")
                              .join("");
                            const ceaDetails = await toast.promise(
                              onFetchCeaDetails(phoneNumber),
                              {
                                error: "CEA information not found",
                                loading: "Finding CEA information...",
                                success: "CEA information is filled",
                              }
                            );

                            if (ceaDetails) {
                              setValue(
                                "salesTeamInfo",
                                {
                                  name: ceaDetails.businessName,
                                  registrationNumber:
                                    ceaDetails.registrationNumber,
                                  currentEa: ceaDetails.currentEa,
                                  licenseNumber: ceaDetails.licenseNumber,
                                },
                                {
                                  shouldDirty: true,
                                  shouldTouch: true,
                                  shouldValidate: true,
                                }
                              );
                            }
                          }}
                        >
                          Get CEA Details
                        </Button>
                      )
                    }
                    size="lg"
                    placeholder="Enter phone number"
                    className="col-span-full"
                    {...field}
                    {...formState}
                    disabled={filledCEA}
                    value={formatPhoneNumber(field.value)}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </div>

            {filledCEA && (
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                <div className="col-span-full">
                  <Title as="h4" className="font-semibold">
                    Sales Team Information
                  </Title>
                </div>
                <Controller
                  control={control}
                  name="salesTeamInfo.name"
                  render={({ field, fieldState, formState }) => (
                    <Input
                      label="Agent Name"
                      size="lg"
                      placeholder="Enter Agent Name"
                      className="col-span-full"
                      readOnly={!!ceaDetails?.businessName}
                      {...field}
                      {...formState}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="salesTeamInfo.registrationNumber"
                  render={({ field, fieldState, formState }) => (
                    <Input
                      label="CEA Registration Number"
                      size="lg"
                      placeholder="Enter CEA Registration Number"
                      className="col-span-full"
                      readOnly={!!ceaDetails?.registrationNumber}
                      {...field}
                      {...formState}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="salesTeamInfo.currentEa"
                  render={({ field, fieldState, formState }) => (
                    <Input
                      label="Current Agency"
                      size="lg"
                      placeholder="Enter Current Agency"
                      className="col-span-full"
                      readOnly={!!ceaDetails?.currentEa}
                      {...field}
                      {...formState}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="salesTeamInfo.licenseNumber"
                  render={({ field, fieldState, formState }) => (
                    <Input
                      label="Agency License Number"
                      size="lg"
                      placeholder="Enter Agency License Number"
                      className="col-span-full"
                      readOnly={!!ceaDetails?.licenseNumber}
                      {...field}
                      {...formState}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </div>
            )}

            <div className="col-span-full flex items-center justify-end gap-4">
              <Button
                type="submit"
                isLoading={isLoading}
                className="w-full lg:w-fit"
              >
                Register
              </Button>
            </div>
          </div>
        );
      }}
    </Form>
  );
}
