import { IMAGES } from "@/assets/images";
import { useAuthHelper } from "@/components/auth/helper";
import TrashIcon from "@/components/icons/trash";
import ImageApp from "@/components/ui/image-app";
import { APP_SUPPORT_DOMAIN } from "@/config/constants";
import { routes } from "@/config/routes";
import useProfile from "@/hooks/use-profile";
import { MAPPING_NAME } from "@/utilities/functions/ldp-options";
import { ILandingPage } from "@/utilities/types/LandingPage";
import { Roles } from "@/utilities/types/Users";
import React, { useCallback, useMemo, useRef } from "react";
import toast from "react-hot-toast";
import { BiLink } from "react-icons/bi";
import { BsCode, BsEye, BsLayoutWtf } from "react-icons/bs";
import { IoOptions, IoSettings } from "react-icons/io5";
import { Link } from "react-router-dom";
import { ActionIcon, Badge, Button, Dropdown, Text, Title } from "rizzui";
import { CustomizeDomainForm } from "../create-update/DomainConfigModal";
import cn from "@/utils/class-names";
import { ProjectLandingGridListingStates } from "./types";

interface ProjectGridCardProps {
  ldpData: ILandingPage;
  onDeleteItem?: (id: string) => Promise<void>;
  handleDomainModal?: (
    data: Partial<CustomizeDomainForm> & {
      id: string;
      projectId?: string;
      isSetupScript?: boolean;
      useProjectSgTrackingId?: boolean;
    }
  ) => void;
  handleOptionModal?: (
    data: ProjectLandingGridListingStates["modifyModal"]
  ) => void;
  handleScriptsModal?: (
    data: ProjectLandingGridListingStates["scriptsModal"]
  ) => void;
}

const ProjectGridCard = React.memo(
  ({
    ldpData,
    onDeleteItem,
    handleDomainModal,
    handleOptionModal,
    handleScriptsModal,
  }: ProjectGridCardProps) => {
    const {
      domain,
      id,
      user,
      project,
      optionMappings,
      headerScript,
      bodyScript,
    } = ldpData;
    const { profile } = useProfile();

    const isAdmin = profile?.role === Roles.ADMIN;
    const agentCanEdit = !ldpData?.useProjectSgTrackingId; // userProjectSgTrackingId = true mean Admin setting up scripts for  landing page
    const { shareToken } = useAuthHelper();

    const toasterRef = useRef<any>(null);

    const isSetupScript = useMemo(() => {
      return !!headerScript || !!bodyScript;
    }, [headerScript, bodyScript]);
    const currentActiveOption =
      optionMappings.find((op) => op?.active)?.landingPageOption?.name || "O1";
    const mappingOptionName = MAPPING_NAME[currentActiveOption];
    const originalDomain = user?.domains?.find((d) => d?.primary)?.name;
    const aliasEditDynamic = `https://${APP_SUPPORT_DOMAIN}/editor/landing-page?domain=${domain}&originalDomain=${originalDomain}`;

    const handleDelete = useCallback(() => {
      toast(
        (t) => {
          toasterRef.current = t;
          return (
            <div className="w-56 pb-2 pt-1 text-left rtl:text-right">
              <Title
                as="h6"
                className="mb-0.5 flex items-start text-xs text-gray-700 sm:items-center"
              >
                Delete Landing Page
              </Title>
              <Text className="mb-2 leading-relaxed text-gray-500">
                Are you sure to delete this Landing Page
              </Text>
              <div className="flex items-center justify-end">
                <Button
                  size="sm"
                  className="me-1.5 h-7"
                  type="submit"
                  onClick={async () => {
                    try {
                      await onDeleteItem?.(id);
                    } finally {
                      toast.dismiss(t.id);
                    }
                  }}
                >
                  Yes
                </Button>
                <Button
                  size="sm"
                  variant="outline"
                  className="h-7"
                  onClick={() => toast.dismiss(t.id)}
                >
                  No
                </Button>
              </div>
            </div>
          );
        },
        { position: "top-right" }
      );
    }, [id, onDeleteItem]);

    const handleEdit = useCallback(async () => {
      if (APP_SUPPORT_DOMAIN) {
        await shareToken?.();
        window.location.href = aliasEditDynamic;
      }
    }, [shareToken, aliasEditDynamic]);

    const thumbnail = useMemo(() => {
      return (
        project?.photo?.urls?.[0]?.url || project?.medias?.[0]?.urls?.[0]?.url
      );
    }, [project]);

    return (
      <div className="flex flex-col h-full bg-white border border-gray-200 rounded-lg overflow-hidden shadow-lg group cursor-pointer">
        {/* Image Section */}
        <div className="relative aspect-square w-full flex-shrink-0 overflow-hidden">
          <ImageApp
            inApp={!thumbnail}
            className="object-cover w-full h-full opacity-75 group-hover:opacity-100 transition-opacity"
            src={thumbnail || IMAGES.PROJ_IMAGE_HOLDER}
            fallback={IMAGES.PROJ_IMAGE_HOLDER}
          />
          <Badge rounded="md" className="absolute top-2 left-2 z-10">
            {mappingOptionName}
          </Badge>
          <Link
            className="opacity-0 group-hover:opacity-100 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity"
            target="_blank"
            to={`https://${domain}`}
          >
            <Button size="sm">
              <BsEye className="text-white me-2" />
              <span>View</span>
            </Button>
          </Link>

          {/* TODO: Clean up with ActionDropdownComponent */}
          <Dropdown
            className="opacity-0 group-hover:opacity-100 absolute right-2 top-2"
            placement="bottom-end"
            rounded="md"
          >
            <Dropdown.Trigger>
              <ActionIcon size="sm" rounded="md">
                <IoSettings />
              </ActionIcon>
            </Dropdown.Trigger>
            <Dropdown.Menu className="divide-y">
              <Dropdown.Item
                onClick={() => {
                  handleDomainModal?.({
                    id: ldpData.id,
                    domain: ldpData.domain,
                    domainName: ldpData.name,
                    userId: ldpData.userId,
                    projectId: ldpData.project?.id,
                    isSetupScript: isSetupScript,
                    useProjectSgTrackingId: !!ldpData?.useProjectSgTrackingId,
                  });
                }}
              >
                <BiLink className="mr-2 h-4 w-4" />
                Domain Config
              </Dropdown.Item>

              <Dropdown.Item onClick={handleEdit}>
                <BsLayoutWtf className="mr-2 h-4 w-4" />
                Editor Layout
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  handleScriptsModal?.({
                    ldpId: ldpData.id,
                    open: true,
                    initialValues: {
                      headerScript: ldpData.headerScript,
                      bodyScript: ldpData.bodyScript,
                      enquiryScript: ldpData.enquiryScript,
                      useEnquiryScript: !!ldpData?.useEnquiryScript,
                    },
                    useProjectSgTrackingId: !!ldpData?.useProjectSgTrackingId,
                  });
                }}
              >
                <BsCode className="mr-2 h-4 w-4" />
                Setup Scripts
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() =>
                  handleOptionModal?.({
                    ldpId: ldpData.id,
                    optionMappings: ldpData.optionMappings,
                    open: true,
                  })
                }
              >
                <IoOptions className="mr-2 h-4 w-4" />
                Modify Option
              </Dropdown.Item>

              {(isAdmin || agentCanEdit) && (
                <Dropdown.Item onClick={handleDelete}>
                  <TrashIcon className="mr-2 h-4 w-4" />
                  Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* Info Section */}
        <div className="flex-1 p-4 text-xs flex flex-col justify-between">
          <div className="flex flex-col justify-between flex-1 gap-1">
            <div className="flex text-sm items-baseline gap-1">
              <span className="text-gray-400 font-bold">Project:</span>
              <div className="flex flex-row items-center gap-x-2">
                <Link
                  to={
                    profile?.role === Roles.ADMIN
                      ? routes.project.edit(project?.id || "")
                      : `https://${domain}`
                  }
                  className="font-bold  underline"
                >
                  {(project?.name as string) || "N/A"}
                </Link>

                <span
                  className={cn(
                    "aspect-square size-[5px] rounded-full animate-pulse",
                    isSetupScript && "bg-emerald-500",
                    !isSetupScript && "bg-red-500"
                  )}
                />
              </div>
            </div>
            <div className="flex gap-1">
              <span className="font-medium text-gray-400">User:</span>
              <span className="font-medium">
                {`${user?.firstName || ""} ${user?.lastName || ""}`.trim()}
              </span>
            </div>
            <div className="flex flex-wrap gap-1">
              <span className="font-medium text-gray-400">Email:</span>
              <span className="text-gray-400">{user?.email || "N/A"}</span>
            </div>
            <div className="flex flex-wrap gap-1">
              <span className="text-gray-400">Domain:</span>
              <p
                style={{
                  overflowWrap: "anywhere",
                }}
                className=""
              >
                {domain || "N/A"}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

ProjectGridCard.displayName = "ProjectGridCard";

export default ProjectGridCard;

export const ProjectGridCardSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col h-full bg-white border border-gray-200 rounded-lg overflow-hidden shadow-lg group cursor-pointer min-h-0 animate-pulse">
      {/* Image Section Skeleton */}
      <div className="relative aspect-square w-full flex-shrink-0 overflow-hidden">
        <div className="w-full h-full bg-gray-200" />
        {/* Badge Skeleton */}
        <div className="absolute top-2 left-2 z-10 h-6 w-16 bg-gray-300 rounded-md" />
        {/* Button Skeleton */}
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-8 w-20 bg-gray-300 rounded-md" />
        {/* Dropdown Skeleton */}
        <div className="absolute right-2 top-2 h-8 w-8 bg-gray-300 rounded-md" />
      </div>

      {/* Info Section Skeleton */}
      <div className="flex-1 p-4 text-xs flex flex-col justify-between min-h-0">
        <div className="flex flex-col justify-between gap-2">
          {/* Name Skeleton */}
          <div className="flex gap-1">
            <span className="font-medium h-4 w-16 bg-gray-300 rounded" />
            <span className="font-medium h-4 w-3/4 bg-gray-300 rounded" />
          </div>
          {/* User Skeleton */}
          <div className="flex gap-1">
            <span className="font-medium h-4 w-16 bg-gray-300 rounded" />
            <span className="font-medium h-4 w-1/2 bg-gray-300 rounded" />
          </div>
          {/* Email Skeleton */}
          <div className="flex flex-wrap gap-1">
            <span className="font-medium h-4 w-16 bg-gray-300 rounded" />
            <span className="h-4 w-2/3 bg-gray-300 rounded" />
          </div>
          {/* Original Domain Skeleton */}
          <div className="flex flex-wrap gap-1">
            <span className="h-4 w-24 bg-gray-300 rounded" />
            <span className="h-4 w-1/2 bg-gray-300 rounded" />
          </div>
          {/* Project Skeleton */}
          <div className="flex gap-1">
            <span className="h-4 w-16 bg-gray-300 rounded" />
            <span className="h-4 w-1/3 bg-gray-300 rounded" />
          </div>
        </div>
      </div>
    </div>
  );
};
