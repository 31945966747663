import { Controller, useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Switch } from "rizzui";
import toast from "react-hot-toast";

import { useAdvanceFeatures } from "@/hooks/use-advance-features";
import useProfile from "@/hooks/use-profile";
import useApi from "@/hooks/useApi";
import AdvanceFeatureRepository from "@/utilities/repositories/AdvanceFeature";
import { EFeatureMode, EFeatureType } from "@/utilities/types/AdvanceFeature";
import {
  advanceFeatures,
  AdvanceFeaturesSchema,
} from "@/utils/validators/advance-features.schema";

export interface DynamicLayoutButtonProps {
  isSuperAdmin?: boolean;
}

export default function DynamicLayoutButton({
  isSuperAdmin = false,
}: DynamicLayoutButtonProps) {
  const { enabledLayout, dynamicLayoutMode, setDynamicLayoutMode } =
    useAdvanceFeatures();

  const { profile, setProfile } = useProfile();

  const { request: updateDynamicLayoutMode } = useApi({
    request: AdvanceFeatureRepository.updateDynamicLayoutMode,
    enableToast: true,
  });

  const form = useForm({
    resolver: zodResolver(advanceFeatures),
    mode: "onSubmit",
    defaultValues: {
      telegramBot: {
        chatId: "",
        type: EFeatureType.IpAccessTrackerTelegram,
      },
      dynamicLayout: {
        type: EFeatureType.DynamicLayout,
        enable: enabledLayout || false,
        dynamicLayoutMode: dynamicLayoutMode || EFeatureMode.Normal,
      },
    },
  });

  const onSubmit = async (values: AdvanceFeaturesSchema) => {
    const { dynamicLayout } = values;

    const featureDynamicLayout = profile?.userFeatures?.find(
      (feature) => feature?.feature?.type === EFeatureType.DynamicLayout
    );

    if (!featureDynamicLayout) {
      return toast.error("Feature not found");
    }

    try {
      await updateDynamicLayoutMode(featureDynamicLayout.id, {
        dynamicLayoutMode:
          dynamicLayout?.dynamicLayoutMode || EFeatureMode.Normal,
      });

      if (profile) {
        setProfile({
          ...profile,
          dynamicLayoutMode:
            dynamicLayout?.dynamicLayoutMode || EFeatureMode.Normal,
        });
      }
    } catch (error) {
      toast(error?.toString() || "Update failed");
    }
  };

  return (
    <div className="grid items-start gap-3 grid-cols-1">
      <FormProvider {...form}>
        <form
          className="flex flex-col w-full h-full"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          {!isSuperAdmin && (
            <Controller
              control={form.control}
              name="dynamicLayout.dynamicLayoutMode"
              render={({ field, fieldState }) => {
                const isAdvance = dynamicLayoutMode === EFeatureMode.Advance;

                const handleToggle = () => {
                  field.onChange(
                    dynamicLayoutMode === EFeatureMode.Normal
                      ? EFeatureMode.Advance
                      : EFeatureMode.Normal
                  );
                  form.handleSubmit(onSubmit)(); // Immediately submit the form after state change
                };

                return (
                  <Switch
                    label="Advance Layout"
                    size="lg"
                    className="w-auto"
                    onChange={handleToggle}
                    checked={isAdvance}
                    error={fieldState.error?.message}
                  />
                );
              }}
            />
          )}
        </form>
      </FormProvider>
    </div>
  );
}
