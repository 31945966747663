import FormFooter from "@/components/form-footer";
import cn from "@/utils/class-names";
import * as React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "@/config/routes";
import PageHeader from "@/app/shared/page-header";
import useApi from "@/hooks/useApi";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import {
  CreateSiteContentInput,
  createSiteContentSchema,
} from "@/utilities/validators/site-content";
import SiteContentRepository from "@/utilities/repositories/SiteContent";
import { Input } from "rizzui";
import QuillEditor from "@/components/ui/quille-editor/quill-editor";

export interface CreateEditSiteContentProps {
  className?: string;
  defaultValues?: Partial<CreateSiteContentInput>;
}

export default function CreateEditSiteContent({
  className,
  defaultValues,
}: CreateEditSiteContentProps) {
  const methods = useForm<CreateSiteContentInput>({
    resolver: zodResolver(createSiteContentSchema),
    reValidateMode: "onChange",
    mode: "all",
    defaultValues,
  });

  const handleBack = () => {
    methods.reset();
    navigate(routes.siteContent.listing);
  };

  const navigate = useNavigate();

  const { request: createSiteContent, loading: loadingCreate } = useApi({
    request: SiteContentRepository.createSiteContent,
  });

  const { request: updateSiteContent, loading: loadingEdit } = useApi({
    request: SiteContentRepository.updateSiteContent,
  });

  const { id } = useParams();

  const isUpdate = !!id;

  const onSubmit = async (data: CreateSiteContentInput) => {
    try {
      if (isUpdate) {
        await updateSiteContent(id, {
          ...data,
        });
      } else {
        await createSiteContent(data);
      }
      toast.success("Success");
      handleBack();
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };
  const isLoading = loadingCreate || loadingEdit;

  return (
    <FormProvider {...methods}>
      <form
        className={cn("mt-6", className)}
        noValidate
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="[&>div:last-child]:pb-28 mb-10 h-full grid gap-6 divide-y divide-dashed divide-gray-200">
          <div className="pt-6">
            <Input
              label="Name"
              placeholder="Title"
              {...methods.register("title")}
              error={methods?.formState?.errors.title?.message as string}
            />
          </div>

          <div className="pt-6">
            <Controller
              control={methods?.control}
              name="content"
              render={({ field: { onChange, value } }) => (
                <QuillEditor
                  value={value}
                  onChange={onChange}
                  label="Content"
                  className="col-span-full [&_.ql-editor]:min-h-[100px]"
                  labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5"
                  error={methods?.formState?.errors?.content?.message as string}
                />
              )}
            />
          </div>
        </div>
        <FormFooter
          isLoading={isLoading}
          altBtnText="Back"
          submitBtnText={"Save"}
          handleAltBtn={handleBack}
          submitButtonProps={{
            disabled: isLoading || !methods.formState.isDirty,
          }}
        />
      </form>
    </FormProvider>
  );
}
