import formatPhoneNumber from "@/utilities/functions/format-phone-number";
import { z } from "zod";

// form zod validation schema
export const loginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1),
  rememberMe: z.boolean().optional(),
});

export const agencyLoginSchema = z.object({
  phone: z
    .string({ message: "Invalid phone number" })
    .trim() // Removes leading & trailing spaces
    .transform((val) => {
      const format = formatPhoneNumber(val);
      return format.replace(/\s+/g, "");
    }) // Removes all spaces
    .refine((val) => val.length === 8, { message: "Invalid phone number" }),
});

// generate form types from zod validation schema
export type LoginSchema = z.infer<typeof loginSchema>;

export type AgencyLoginSchema = z.infer<typeof agencyLoginSchema>;
