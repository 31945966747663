import { LDP_DOMAIN, LDP_SUBDOMAINS } from "@/config/constants";
import { routes } from "@/config/routes";
import useProfile from "@/hooks/use-profile";
import useApi from "@/hooks/useApi.tsx";
import { useTableServer } from "@/hooks/useTableServer.tsx";
import LandingPagesRepository from "@/utilities/repositories/LandingPages";
import { ILandingPage } from "@/utilities/types/LandingPage";
import { IResponseData } from "@/utilities/types/requests.ts";
import { Roles } from "@/utilities/types/Users.ts";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Title } from "rizzui";
import DomainConfigModal from "../create-update/DomainConfigModal";
import OptionModifyModal from "../create-update/OptionModifyModal";
import ProjectGridCard, { ProjectGridCardSkeleton } from "./ProjectGridCard";
import ScriptsFormModal from "../create-update/ScriptsFormModal";
import { CreateNewCard } from "./components/CreateNewCard";
import { GridContainer } from "./components/GridContainer";
import { useLdpActionModals } from "./hooks/useLdpActionModals";
import { useLazyload } from "@/hooks/useLazyload";
import qs from "qs";

const DomainLabelQuestionTooltip = ({
  isSetupScript,
  useProjectSgTrackingId,
}: {
  isSetupScript: boolean;
  useProjectSgTrackingId: boolean;
}) => {
  if (useProjectSgTrackingId) {
    return (
      <div className="flex items-start flex-col gap-1 max-w-[320px]">
        <p className="text-sm text-left mb-1 font-bold">
          Why you cannot change the domain?
        </p>
        <p className="text-xs text-left">
          Admin has been using ProjectSG Tracking ID for this landing page.
        </p>
        <p className="text-xs text-left">Please contact Admin to support.</p>
      </div>
    );
  } else if (isSetupScript) {
    return (
      <div className="flex items-start flex-col gap-1 max-w-[320px]">
        <p className="text-sm text-left mb-1 font-bold">
          Why you cannot change the domain?
        </p>
        <p className="text-xs text-left">
          You have been setting up the scripts for this landing page.
        </p>
        <p className="text-xs text-left">
          Please remove all scripts and try again.
        </p>
      </div>
    );
  }

  return <></>;
};

const ProjectLandingGridListing = () => {
  const { profile } = useProfile();
  const navigate = useNavigate();
  const searchParams = useSearchParams();
  const searchParamsParsed = qs.parse(searchParams.toString());

  const userId =
    profile?.role === Roles.ADMIN ? searchParamsParsed?.userId : profile?.id;

  const {
    isLoading,
    tableData,
    refresh,
    totalItems,
    currentPage,
    totalPage,
    handlePaginate,
  } = useTableServer({
    server: {
      request: LandingPagesRepository.getLandingPages,
    },
    initialFilterState: {
      userId,
    },
    pagination: {
      limit: 20,
      page: 1,
    },
    pushSearchParams: false,
  });

  // Use the new useLazyload hook
  const {
    items: tableDataInfiniteLoad,
    loadingRef,
    hasMore,
    resetItems,
  } = useLazyload<ILandingPage>({
    data: tableData,
    totalItems,
    currentPage,
    isLoading,
    totalPage,
    handlePaginate,
  });

  const { request: removeLandingPage } = useApi<IResponseData<ILandingPage>>({
    request: LandingPagesRepository.removeLandingPage,
    enableToast: true,
  });

  const {
    modifyModal,
    setModifyModal,
    scriptsModal,
    setScriptsModal,
    customizeDomain,
    isModalOpen,
    handleDomainModal,
    handleModalClose,
    onUpdateItem,
  } = useLdpActionModals({ onRefresh: refresh });

  const onDeleteItem = async (id: string) => {
    try {
      await removeLandingPage(id);
      await refresh();
    } catch (error) {
      console.log("Failed to delete landing page");
    }
  };

  useEffect(() => {
    if (profile?.role === Roles.AGENCY && !profile?.isFlowSignUp) {
      navigate(routes.landingPages.create, {
        replace: true,
        state: { hideBack: true },
      });
    }
  }, [profile?.role, profile?.isFlowSignUp, navigate]);

  if (!profile) {
    return null;
  }

  return (
    <div className="col-span-full mt-4">
      <div className="mb-4 w-full flex flex-col lg:flex-row lg:items-center items-start gap-2.5 @container justify-between">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          Landing Pages
        </Title>
      </div>

      <GridContainer>
        <CreateNewCard loading={isLoading} />
        {tableDataInfiniteLoad.map((item) => (
          <ProjectGridCard
            key={item.id}
            ldpData={item}
            onDeleteItem={onDeleteItem}
            handleDomainModal={handleDomainModal}
            handleOptionModal={setModifyModal}
            handleScriptsModal={setScriptsModal}
          />
        ))}

        {isLoading &&
          Array.from({ length: 8 }).map((_, i) => (
            <ProjectGridCardSkeleton key={i} />
          ))}

        {hasMore && (
          <div ref={loadingRef} className="h-4 w-full col-span-full" />
        )}

        {isModalOpen && (
          <DomainConfigModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
            type={
              customizeDomain?.domain?.endsWith(LDP_DOMAIN)
                ? "subdomain"
                : "custom"
            }
            onSubmit={(data) =>
              customizeDomain && onUpdateItem(customizeDomain.id, data)
            }
            suffixDomains={LDP_SUBDOMAINS}
            defaultSuffixDomain={LDP_SUBDOMAINS[0]}
            initialValues={customizeDomain || undefined}
            updateDomain
            projectId={customizeDomain?.projectId}
            disabledChangeDomain={
              customizeDomain?.isSetupScript ||
              customizeDomain?.useProjectSgTrackingId
            }
            domainLabelQuestionTooltip={
              <DomainLabelQuestionTooltip
                isSetupScript={!!customizeDomain?.isSetupScript}
                useProjectSgTrackingId={
                  !!customizeDomain?.useProjectSgTrackingId
                }
              />
            }
          />
        )}

        {modifyModal?.open && (
          <OptionModifyModal
            isOpen={!!modifyModal?.open}
            onClose={() => setModifyModal(null)}
            ldpId={modifyModal?.ldpId || ""}
            optionsMapping={modifyModal?.optionMappings || []}
            onSuccessUpdate={async () => await refresh()}
          />
        )}

        {scriptsModal?.open && (
          <ScriptsFormModal
            open={scriptsModal.open}
            onClose={() => setScriptsModal(null)}
            initialValues={scriptsModal.initialValues}
            useProjectSgTrackingId={scriptsModal.useProjectSgTrackingId}
            onSave={async (data) => {
              try {
                await onUpdateItem(scriptsModal.ldpId, {
                  headerScript: scriptsModal.useProjectSgTrackingId
                    ? undefined
                    : data.headerScript,
                  bodyScript: data.bodyScript,
                  enquiryScript: "",
                  useEnquiryScript: data.useEnquiryScript,
                });
                setScriptsModal(null);
              } catch (error) {
                console.log("Failed to update landing page");
              }
            }}
          />
        )}
      </GridContainer>
    </div>
  );
};

export default ProjectLandingGridListing;
