import { AdvanceFeaturesContext } from "@/utilities/providers/AdvanceFeaturesProvider";
import { useContext } from "react";

export const useAdvanceFeatures = () => {
  const context = useContext(AdvanceFeaturesContext);
  if (context === undefined) {
    throw new Error(
      "useAdvanceFeatures must be used within a AdvanceFeaturesProvider"
    );
  }

  return context;
};
