import { Button } from "rizzui";
import { Template } from "./TemplateSections";
import cn from "@/utils/class-names";

interface TemplateCardProps {
  template: Template;
  onPreview: () => void;
  className?: string;
}

const TemplateCard = ({
  template,
  onPreview,
  className,
}: TemplateCardProps) => {
  return (
    <div
      className={cn(
        "relative bg-white h-full group rounded-lg shadow-sm overflow-hidden transition-all duration-200 hover:shadow-lg",
        className
      )}
    >
      <div className="relative ">
        <img
          src={template.previewImage.desktop}
          alt={template.name}
          className="w-full h-64 md:h-96 object-cover object-top"
          loading="lazy"
        />
        {/* Overlay khi hover */}
        <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
          <Button
            variant="solid"
            onClick={onPreview}
            className="bg-white text-gray-900 hover:bg-gray-100"
          >
            Preview
          </Button>
        </div>
      </div>

      <div className="p-4">
        <h3 className="text-lg font-semibold mb-2">{template.name}</h3>
        <p className="text-gray-600 text-sm">{template.description}</p>
      </div>
    </div>
  );
};

export default TemplateCard;
