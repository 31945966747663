import { Route, Routes } from "react-router-dom";
import DefaultLayout from "./components/layout/layout";
import { publicRoutes } from "./routes/publicRoutes";
import { Fragment } from "react/jsx-runtime";
import NotFound from "./page/404";
import { privateRoutes } from "./routes/privateRoutes";
import AuthOutlet from "@auth-kit/react-router/AuthOutlet";
import useProfile from "./hooks/use-profile";

function App() {
  const { profile } = useProfile();
  return (
    <Routes>
      {publicRoutes.length > 0 &&
        publicRoutes.map((route, index) => {
          const Layout = route.layout ? DefaultLayout : Fragment;
          const Page = route.component;
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Layout>
                  <Page />
                </Layout>
              }
            />
          );
        })}
      <Route element={<AuthOutlet fallbackPath="/sign-in" />}>
        {privateRoutes.length > 0 &&
          privateRoutes.map((route, index) => {
            if (
              route.permissions &&
              route.permissions.length > 0 &&
              !!profile
            ) {
              const currentRole = profile.role;

              if (!route.permissions.includes(currentRole)) {
                return; // Or navigate to 403 page
              }
            }
            const Layout = route.layout ? DefaultLayout : Fragment;
            const Page = route.component;

            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <Layout>
                    <Page />
                  </Layout>
                }
              />
            );
          })}
      </Route>
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
