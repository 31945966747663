import FormFooter from "@/components/form-footer";
import cn from "@/utils/class-names";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import MediasPart from "./medias-part";
import { routes } from "@/config/routes";
import PageHeader from "@/app/shared/page-header";
import InformationProjectPart from "./information-project";
import useApi from "@/hooks/useApi";
import {
  CreateProjectInput,
  createProjectSchema,
} from "@/utilities/validators/projects";
import { zodResolver } from "@hookform/resolvers/zod";
import ProjectRepository from "@/utilities/repositories/Project";
import { filterChangedFormFields } from "@/utils/form";
import toast from "react-hot-toast";
import { Roles } from "@/utilities/types/Users.ts";
import useProfile from "@/hooks/use-profile.ts";
import { useState } from "react";
import { isGoogleMapsIframe, parseSrcFromIframe } from "@/utils/string";
import { backPrev } from "@/utilities/functions";
import { EUploadType } from "@/utilities/types/Upload";
import _ from "lodash";

export interface CreateEditProjectProps {
  className?: string;
}

export default function CreateEditProject({
  className,
}: CreateEditProjectProps) {
  const methods = useForm<CreateProjectInput>({
    resolver: zodResolver(createProjectSchema),
    reValidateMode: "onChange",
    mode: "all",
  });
  const [slug, setSlug] = useState("");
  const navigate = useNavigate();

  const handleBack = () => {
    methods.reset();

    navigate(backPrev(routes.project.listing));
  };

  const { request: onCreateProject, loading: loadingCreate } = useApi({
    enableToast: true,
    request: ProjectRepository.createProject,
  });

  const { request: getDetailProjectById, loading } = useApi({
    request: ProjectRepository.getProjectById,
  });

  const { request: onEditProject, loading: loadingEdit } = useApi({
    request: ProjectRepository.updateProject,
    enableToast: true,
  });

  const handleCreateProject = (data: CreateProjectInput) => {
    const { pictures, ...restData } = data;
    const mediaIds = data.medias?.map((media) => media.id);
    const convertData = {
      ...restData,
      photoId: pictures?.[0]?.id || null,
      medias: mediaIds,
    };
    onCreateProject(convertData).then(() => {
      handleBack();
    });
  };

  const handleUpdateProject = (data: CreateProjectInput) => {
    const dirtyUpdateFields: Partial<CreateProjectInput> =
      filterChangedFormFields(data, methods?.formState.dirtyFields);
    const {
      pictures,
      // medias,
      mobileLogo,
      desktopLogo,
      siteplanImages,
      ...restData
    } = dirtyUpdateFields;

    let mediaIds = _.unionBy(data?.medias?.map((media) => media?.id));
    const sitePlanImagesId = siteplanImages?.map((media) => media?.id);
    const isDirtyPicture = methods?.formState.dirtyFields?.pictures;
    const photoId = isDirtyPicture ? pictures?.[0]?.id || null : undefined;

    if (photoId && mediaIds.includes(photoId)) {
      mediaIds = mediaIds.filter((id) => id !== photoId);
    }

    const convertData = {
      ...restData,
      manualAvailableUnitsCount: data.manualAvailableUnitsCount,
      photoId: photoId,
      medias: mediaIds,
      mobileLogoId: mobileLogo?.[0]?.id,
      desktopLogoId: desktopLogo?.[0]?.id,
      siteplanImageIds: sitePlanImagesId,
      googleMapUrl: isGoogleMapsIframe(data?.googleMapUrl || "")
        ? parseSrcFromIframe(data?.googleMapUrl || "") || ""
        : data?.googleMapUrl || "",
    };

    onEditProject(id, convertData).then(() => {
      handleBack();
    });
  };
  let RedirectProject: string = "";
  const onSubmit = (data: CreateProjectInput) => {
    if (isUpdate) {
      handleUpdateProject(data);
    } else {
      handleCreateProject(data);
    }
  };
  const isLoading = loadingCreate || loadingEdit;

  const { id } = useParams();

  const isUpdate = !!id;
  const { profile } = useProfile();
  const title =
    profile?.role === Roles.AGENCY
      ? "Project Information"
      : isUpdate
        ? "Edit Project"
        : "Create Project";
  if (profile?.domains && profile?.domains.length > 0) {
    RedirectProject = profile?.domains[0].name;
  }
  const pageHeader = {
    title,
    breadcrumb: [
      {
        href: routes.project.listing,
        name: "Projects",
      },
      {
        name: title,
      },
    ],
  };

  React.useEffect(() => {
    if (!isUpdate) return;
    getDetailProjectById(id)
      .then((response) => {
        if (!response) return;
        const { data } = response;
        if (!data) return;
        setSlug(data.slug);

        const photos = data?.photo
          ? {
              id: data?.photo?.id,
              url: data?.photo?.urls?.[0]?.url,
              type: data?.photo?.type,
              name: data?.photo?.id,
              disabled: false,
            }
          : undefined;

        const desktopLogo = data?.desktopLogo
          ? {
              id: data?.desktopLogo?.id,
              url: data?.desktopLogo?.urls?.[0]?.url,
              type: data?.desktopLogo?.type,
              name: data?.desktopLogo?.id,
              disabled: false,
            }
          : undefined;

        const mobileLogo = data?.mobileLogo
          ? {
              id: data?.mobileLogo?.id,
              url: data?.mobileLogo?.urls?.[0]?.url,
              type: data?.mobileLogo?.type,
              name: data?.mobileLogo?.id,
              disabled: false,
            }
          : undefined;

        const medias = data?.medias
          ?.filter((media) => media.id !== data?.photo?.id)
          ?.map((media) => ({
            // medias = photo + medias uploaded => need filter the photo
            id: media?.id,
            url: (media?.type === "video"
              ? media?.urls?.[0]
              : media?.urls?.[0]?.url) as string,
            type: media?.type,
            name: media?.id,
            disabled: false,
          }));

        if (data?.photo) {
          // Push thumbnail to the first of medias
          medias.unshift({
            id: data?.photo?.id,
            url: data?.photo?.urls?.[0]?.url,
            type: EUploadType.IMAGE,
            name: data?.photo?.id,
            disabled: true,
          });
        }

        const sitePlanImages = data?.siteplanImages
          ?.filter((media) => media.id !== data?.photo?.id)
          ?.map((media) => ({
            id: media?.id,
            url: (media?.type === "video"
              ? media?.urls?.[0]
              : media?.urls?.[0]?.url) as string,
            type: media?.type,
            name: media?.id,
          }));

        const defaultData: Partial<CreateProjectInput> = {
          area: data?.area,
          categoryId: data?.category?.id,
          description: data?.description || "",
          locationId: data?.location?.id,
          developerId: data?.developer?.id,
          marketSegment: data?.marketSegment || "",
          name: typeof data?.name === "object" ? data?.name?.en : data?.name,
          expectedTop: new Date(data?.expectedTop),
          tenure: data?.tenure || "",
          tenureEffectFrom: new Date(data?.tenureEffectFrom),
          pictures: photos && [photos],
          medias,
          amenityHtml: data?.amenityHtml || "",
          facilityHtml: data?.facilityHtml || "",
          directCommission: data?.directCommission,
          googleMapUrl: data?.googleMapUrl || "",
          address: data?.address || "",
          totalUnitsCount: data?.totalUnitsCount || "",
          availableUnitsCount: data?.availableUnitsCount || "",
          manualTotalUnitsCount: data?.manualTotalUnitsCount || "",
          manualAvailableUnitsCount: data?.manualAvailableUnitsCount || "",
          desktopLogo: desktopLogo && [desktopLogo],
          mobileLogo: mobileLogo && [mobileLogo],
          siteplanImages: sitePlanImages,
        };

        methods.reset(defaultData);
      })
      .catch(() => {
        toast.error("Project not found!");
        handleBack();
      });
  }, []);

  const picturesWatch = methods.watch("pictures");
  React.useEffect(() => {
    // 3 cases:
    // 1. Delete photo => remove thumbnail from medias
    // 2. Add new photo => add thumbnail to the first of medias
    // 3. Update photo => update thumbnail of medias

    const mediasUpdate = [...(methods.getValues("medias") || [])].filter(
      (item) => !item.disabled
    ); // always remove thumbnail if existing

    if (picturesWatch?.length && picturesWatch.length > 0) {
      // add thumbnail to the first of medias in add or update case
      mediasUpdate.unshift({
        id: picturesWatch?.[0]?.id,
        url: picturesWatch?.[0]?.url,
        type: picturesWatch?.[0]?.type,
        name: picturesWatch?.[0]?.name,
        disabled: true, // is
      });
    }

    methods.setValue(
      "medias",
      _.uniqBy(mediasUpdate, (item) => item.id)
    );
  }, [picturesWatch, methods]);
  return (
    <div className="@container h-full mt-4">
      <PageHeader
        title={pageHeader.title}
        breadcrumb={pageHeader.breadcrumb}
      ></PageHeader>
      <FormProvider {...methods}>
        <form
          className={cn("md:mt-6 mt-0", className)}
          noValidate
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="[&>div:last-child]:pb-28 mb-10 h-full grid gap-7 divide-y divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11">
            <div className="pt-0 md:pt-7 @2xl:pt-9 @3xl:pt-11">
              <InformationProjectPart />
            </div>
            <div
              className={`pt-0 md:pt-7 @2xl:pt-9 @3xl:pt-11 ${profile?.role === Roles.AGENCY ? "[&_.upload-zone]:opacity-80 [&_.upload-zone]:pointer-events-none " : ""}`}
            >
              <MediasPart />
            </div>
          </div>
          <FormFooter
            isLoading={isLoading}
            altBtnText="Back"
            submitBtnText={isUpdate ? "Save" : "Add"}
            handleAltBtn={handleBack}
            submitButtonProps={{
              disabled: isLoading || !methods.formState.isDirty,
            }}
            redirectPage={`https://${RedirectProject}/projects/${slug}`}
            canSubmit={profile?.role === Roles.ADMIN}
          />
        </form>
      </FormProvider>
    </div>
  );
}
