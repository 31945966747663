import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { E_ENQUIRY } from "../enums/EnquiryEntries";
import {
  ICeaDetails,
  IEnquiryEntry,
  IEnquirySubmissionLog,
} from "../types/EnquiryEntry";

function getList({ queryParams }: IRequestArgs) {
  let query = {
    sort: queryParams?.query?.sort,
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
  };

  queryParams?.query?.filter?.forEach((item) => {
    query = {
      ...query,
      ...item,
    };
  });

  return request<ListResponseData<IEnquiryEntry>>({
    method: ERequestMethods.GET,
    url: E_ENQUIRY.MAIN,
    params: query,
  });
}

function getListByAgency({ queryParams }: IRequestArgs) {
  let query = {
    sort: queryParams?.query?.sort,
    ...queryParams?.query?.search,
    ...queryParams?.pagination,
  };

  queryParams?.query?.filter?.forEach((item) => {
    query = {
      ...query,
      ...item,
    };
  });

  return request<ListResponseData<IEnquiryEntry>>({
    method: ERequestMethods.GET,
    url: E_ENQUIRY.MAIN + "/by-agency",
    params: query,
  });
}

function getById(id: string) {
  return request<IResponseData<IEnquiryEntry>>({
    method: ERequestMethods.GET,
    url: E_ENQUIRY.DETAIL.replace(":id", id),
  });
}

function deleteItem(id: string) {
  return request<IResponseData<IEnquiryEntry>>({
    method: ERequestMethods.DELETE,
    url: E_ENQUIRY.DETAIL.replace(":id", id),
  });
}

function getLogsById(id: string) {
  return request<ListResponseData<IEnquirySubmissionLog>>({
    method: ERequestMethods.GET,
    url: E_ENQUIRY.LOGS.replace(":id", id),
  });
}

async function getCeaDetails(getBy: {
  phoneNumber?: string;
  email?: string;
}): Promise<ICeaDetails | null> {
  const response = await request<IResponseData<ICeaDetails>>({
    method: ERequestMethods.POST,
    url: E_ENQUIRY.GET_CEA_DETAILS,
    data: getBy,
  });

  if (!response.data) {
    throw new Error("CEA Details not found");
  }

  return response.data;
}

const EnquiryEntriesRepository = Object.freeze({
  getList,
  getById,
  deleteItem,
  getListByAgency,
  getLogsById,
  getCeaDetails,
});

export default EnquiryEntriesRepository;
