import useApi from "@/hooks/useApi";
import LandingPageOptionRepository from "@/utilities/repositories/LandingPageOptions";
import { ILandingPage } from "@/utilities/types/LandingPage";
import { useState } from "react";
import toast from "react-hot-toast";
import { Button, Modal } from "rizzui";
import TemplateSelection from "./TemplateSections";

export interface OptionModifyModalProps {
  optionsMapping: ILandingPage["optionMappings"];
  ldpId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccessUpdate?: () => Promise<void>;
}

export default function OptionModifyModal({
  optionsMapping,
  ldpId,
  isOpen,
  onClose,
  onSuccessUpdate,
}: OptionModifyModalProps) {
  const currentActiveOption = optionsMapping.find((op) => op.active);

  const [activeOptionId, setActiveOptionId] = useState<string>(
    currentActiveOption?.landingPageOption?.id || ""
  );

  const { request: attachOption } = useApi({
    request: LandingPageOptionRepository.attachOption,
  });

  const { request: activeOption } = useApi({
    request: LandingPageOptionRepository.activeOption,
    enableToast: true,
  });

  const onModifyOption = async (optionId: string) => {
    const isExistingOption = optionsMapping.find(
      (option) => option.id === optionId
    );

    try {
      if (!isExistingOption) {
        // Need attach option before  active if option not exist before
        await attachOption({
          landingPageId: ldpId,
          layoutOptionId: optionId,
        });
      }
      await activeOption({
        landingPageId: ldpId,
        layoutOptionId: optionId,
        active: true,
      });
      await onSuccessUpdate?.();

      onClose();
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      containerClassName="max-w-[95%] h-[90svh] max-w-[1440px] mx-auto"
    >
      <div className="flex h-full flex-col gap-4">
        <div className="flex-1 overflow-auto">
          <TemplateSelection
            activeOptionId={activeOptionId || ""}
            onSelectTemplate={(template) => setActiveOptionId(template.id)}
          />
        </div>

        <div className="flex justify-end gap-2 p-4">
          <Button
            onClick={() => {
              onClose();
            }}
            variant="outline"
          >
            Close
          </Button>
          <Button
            onClick={() => {
              onModifyOption(activeOptionId);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}
