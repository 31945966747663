import { routes } from "@/config/routes";
import cn from "@/utils/class-names";
import { BsPlusSquare } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Loader } from "rizzui";

export const CreateNewCard = ({ loading = false }: { loading?: boolean }) => {
  return (
    <Link
      to={routes.landingPages.create}
      className={cn(
        "flex min-h-[200px] border-black justify-center items-center group rounded-lg border border-dashed cursor-pointer overflow-hidden flex-col bg-transparent w-full h-auto",
        loading && "animate-pulse select-none pointer-events-none"
      )}
    >
      {loading ? (
        <Loader className="w-8 h-8 rounded-full" />
      ) : (
        <div className="flex-col gap-2 flex justify-center items-center">
          <BsPlusSquare className="size-8 opacity-75" />
          <p>Create new Landing page</p>
        </div>
      )}
    </Link>
  );
};
