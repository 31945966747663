import ContactSupportPage from "@/app/app-shared/contact-support";
import RegistrationPage from "@/app/registration/page";
import PricingPlans from "@/components/pricing-plans/pricing-plans";
import { routes } from "@/config/routes";
import ForgetPassword from "@/page/ForgetPassword";
import SignUpPage from "@/page/sign-up/SignUpPage";
import SignIn from "@/page/SignIn";
import VerifyOtpPage from "@/page/verify-otp/VerifyOtpPage";
import { publicRoutesType } from "@/types/routes";

export const publicRoutes: publicRoutesType[] = [
  {
    path: routes.auth.signUp,
    component: RegistrationPage,
    layout: false,
  },
  {
    path: routes.auth.signIn,
    component: SignIn,
    layout: false,
  },
  {
    path: routes.auth.forgotPassword,
    component: ForgetPassword,
    layout: false,
  },

  {
    path: routes.auth.verifyOtp,
    component: VerifyOtpPage,
    layout: false,
  },

  {
    path: routes.contactSupport,
    component: ContactSupportPage,
    layout: false,
  },
];
