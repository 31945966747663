import { Plan } from "@/page/registration/RegistrationStepper";
import * as React from "react";
import Checkout from "./checkout";
import { Button, Stepper, Title } from "rizzui";
import { useAuthHelper } from "../auth/helper";
import useProfile from "@/hooks/use-profile";
import UserRepository from "@/utilities/repositories/Users";
import toast from "react-hot-toast";
import { RequestError } from "@/utilities/types/requests";
import PricingPlans from "./pricing-plans";
import { useNavigate } from "react-router-dom";
import { routes } from "@/config/routes";

export interface AdminPricingPlansProps {}

export default function AdminPricingPlans(props: AdminPricingPlansProps) {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [selectedPlan, setSelectedPlan] = React.useState<Plan | null>(null);
  const { profile, setProfile } = useProfile();
  const navigate = useNavigate();
  const { logOut } = useAuthHelper();
  const handlePlanSelect = (plan: Plan) => {
    setSelectedPlan(plan);
    setCurrentStep(1);
  };

  const handlePricingComplete = async (plan: Plan) => {
    try {
      await UserRepository.updateMe({
        pricingPlans: "NAVISVIP",
      });

      if (profile) {
        setProfile({
          ...profile,
          pricingPlans: "NAVISVIP",
        });
      }

      toast.success("Success");
      navigate(routes.landingPages.create, {
        replace: true,
        state: { hideBack: true },
      });
    } catch (error) {
      if (error instanceof RequestError) {
        toast.error(error.message || "Something went wrong");
      }
    }
  };

  const handleCheckoutComplete = () => {
    if (selectedPlan) {
      handlePricingComplete(selectedPlan);
    }
  };

  const handleBack = () => {
    setCurrentStep(0);
  };

  return (
    <div className="p-6 max-w-screen-xl mx-auto">
      <Title as="h2" className="mb-8 text-center text-2xl font-bold">
        Choose Your Plan
      </Title>

      <Stepper className="mb-10" currentIndex={currentStep}>
        <Stepper.Step
          title="Select Plan"
          description="Choose a plan that suits your needs"
          active={currentStep === 0}
          completed={currentStep > 0}
        />
        <Stepper.Step
          title="Payment"
          description="Complete your payment"
          active={currentStep === 1}
          completed={currentStep > 1}
        />
      </Stepper>

      {currentStep === 0 && <PricingPlans onPlanSelect={handlePlanSelect} />}
      {currentStep === 1 && selectedPlan && (
        <Checkout
          planName={selectedPlan.duration}
          price={selectedPlan.price}
          monthlyPrice={selectedPlan.monthlyPrice}
          onBack={handleBack}
          onComplete={handleCheckoutComplete}
        />
      )}

      <div className="flex justify-center mt-10">
        <Button
          type="button"
          className="mx-auto max-w-[300px] w-full"
          onClick={() => {
            logOut();
          }}
        >
          Sign out
        </Button>
      </div>
    </div>
  );
}
