"use client";

import ProjectCard from "@/components/project-card";
import { useLazyload } from "@/hooks/useLazyload";
import { useTableServer } from "@/hooks/useTableServer";
import ProjectRepository from "@/utilities/repositories/Project";
import { EPhotoType } from "@/utilities/types/Photo";
import { IProject } from "@/utilities/types/Project";
import cn from "@/utils/class-names";
import dynamic from "next/dynamic";
import { useFormContext } from "react-hook-form";

const FilterElement = dynamic(
  () => import("@/app/app-shared/project/project-table/filter-element"),
  { ssr: false }
);

export default function SelectProjectLDP() {
  const {
    isLoading,
    isFiltered,
    tableData,
    currentPage,
    totalItems,
    filters,
    updateFilter,
    searchTerm,
    handleSearch,
    handleReset,
    handlePaginate,
    totalPage,
  } = useTableServer<IProject>({
    server: {
      request: ProjectRepository.getProject,
    },
    pagination: {
      limit: 20,
      page: 1,
    },
    searchBy: (searchTerm) => {
      const s = searchTerm || undefined;
      return {
        search: s,
      };
    },
    pushSearchParams: false,
    callBackFilters: () => {
      resetItems();
    },
  });

  // Use the new useLazyload hook
  const {
    items: tableDataInfiniteLoad,
    loadingRef,
    hasMore,
    resetItems,
  } = useLazyload<IProject>({
    data: tableData,
    totalItems,
    currentPage,
    isLoading,
    handlePaginate,
    totalPage,
  });

  const form = useFormContext();

  return (
    <div className="mt-4 p-4 w-full">
      <FilterElement
        isFiltered={isFiltered}
        filters={filters}
        updateFilter={updateFilter}
        handleReset={handleReset}
        onSearch={handleSearch}
        searchTerm={searchTerm}
        title=""
        buttonAddAllProjects={<></>}
        addNew={false}
      />

      <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 auto-rows-fr  lg:grid-cols-4 gap-4">
        {tableDataInfiniteLoad.map((item) => {
          const thumbnail =
            item?.medias &&
            item?.medias?.find((media) => media.type === EPhotoType.IMAGE);
          return (
            <button
              key={item.id}
              onClick={() => {
                form.setValue("project", {
                  id: item.id,
                  name: item.name,
                  isProjectExist: !!item.userProject,
                });
              }}
            >
              <ProjectCard
                category={item?.category?.name}
                image={thumbnail?.urls?.[0]?.url}
                location={(item?.location?.name as string) || ""}
                name={(item?.name as string) || ""}
                tenure={item?.tenure}
                className={cn(
                  item.id === form.watch("project")?.id && "ring-2 ring-primary"
                )}
                isActive={item.id === form.watch("project")?.id}
              />
            </button>
          );
        })}

        {isLoading &&
          Array.from({ length: 4 }).map((_, i) => (
            <div
              key={i}
              className="flex border-black justify-center items-center group rounded-lg cursor-pointer overflow-hidden flex-col bg-gray-300 animate-pulse aspect-[4/3] w-full h-auto"
            />
          ))}

        {hasMore && (
          <div ref={loadingRef} className="h-4 w-full col-span-full" />
        )}
      </div>
    </div>
  );
}
