import { Enum } from "../enums/Enum";

export enum EPhotoType {
  IMAGE = "image",
  PACKAGE = "package",
}

export type PhotoType = Enum<typeof EPhotoType>;

export interface IPhotoUrl {
  id: string;
  width?: number;
  url: string;
}

export interface IPhoto {
  id: string;
  type: PhotoType;
  urls: IPhotoUrl[];
}
