import { useModal } from "@/app/shared/modal-views/use-modal";
import { PROJECT_CATEGORY_TYPES } from "@/config/constants";
import useApi from "@/hooks/useApi";
import { EProjectCategoryTypes } from "@/utilities/enums/ProjectCategory";
import ProjectCategoryRepository from "@/utilities/repositories/ProjectCategory";
import { IProjectCategory } from "@/utilities/types/ProjectCategory";
import {
  CreateProjectCategoryInput,
  createProjectCategorySchema,
} from "@/utils/validators/project-category.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { PiXBold } from "react-icons/pi";
import { ActionIcon, Button, Input, Select, SelectOption, Title } from "rizzui";

export interface CreateEditProjectCategoryProps {
  refetchData?: () => void;
  data?: IProjectCategory;
}

export default function CreateEditProjectCategory({
  refetchData,
  data,
}: CreateEditProjectCategoryProps) {
  const isUpdate = !!data;
  const title = isUpdate ? "Edit Project Category" : "Create Project Category";
  const form = useForm({
    defaultValues: {
      name: "",
      type: "",
      shortname: "",
    },
    resolver: zodResolver(createProjectCategorySchema),
  });

  const { closeModal, isOpen } = useModal();

  const { loading, request: createProjectCategory } = useApi({
    request: ProjectCategoryRepository.createProjectCategory,
  });
  const { loading: loadingUpdate, request: updateProjectCategory } = useApi({
    request: ProjectCategoryRepository.updateProjectCategory,
  });

  const onSubmit = async (formData: CreateProjectCategoryInput) => {
    try {
      if (isUpdate) {
        await updateProjectCategory(data.id, formData);
      } else {
        await createProjectCategory(formData);
      }
      toast.success("Success");
      refetchData?.();
      if (isOpen) {
        closeModal();
      }
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    form.reset({
      name: typeof data?.name === "string" ? data?.name : data?.name?.en,
      type: data?.type || "",
      shortname:
        typeof data?.shortname === "string"
          ? data?.shortname
          : data?.shortname?.en,
    });
  }, [JSON.stringify(data)]);

  const PROJECT_CATEGORY_TYPE_OPTIONS: SelectOption[] = Object.keys(
    PROJECT_CATEGORY_TYPES
  ).map((e) => ({
    value: e as string,
    label: (PROJECT_CATEGORY_TYPES?.[e as EProjectCategoryTypes]?.["label"] ||
      "") as string,
  }));

  return (
    <form
      noValidate
      className="grid grid-cols-1 gap-6 p-6 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className="col-span-full flex items-center justify-between">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          {title}
        </Title>
        {isOpen && (
          <ActionIcon size="sm" variant="text" onClick={closeModal}>
            <PiXBold className="h-auto w-5" />
          </ActionIcon>
        )}
      </div>

      <Input
        label="Name"
        size="lg"
        placeholder="Enter name"
        {...form.register("name")}
        className="[&>label>span]:font-medium  col-span-full"
        error={form?.formState?.errors?.name?.message}
      />
      <Input
        label="Short name"
        size="lg"
        placeholder="Enter name"
        {...form.register("shortname")}
        className="[&>label>span]:font-medium  col-span-full"
        error={form?.formState?.errors?.shortname?.message}
      />
      <Controller
        name="type"
        control={form.control}
        render={({ field: { name, onChange, value } }) => (
          <Select
            options={PROJECT_CATEGORY_TYPE_OPTIONS}
            value={value}
            onChange={onChange}
            name={name}
            label="Type"
            size="lg"
            className="[&>label>span]:font-medium  col-span-full"
            getOptionValue={(option: SelectOption) => option.value}
            displayValue={(selected: string) =>
              PROJECT_CATEGORY_TYPE_OPTIONS?.find(
                (option) => option.value === selected
              )?.label
            }
            dropdownClassName={"z-[9999] w-full"}
            error={form?.formState?.errors?.[name]?.message}
          />
        )}
      />

      <div className="col-span-full flex items-center justify-end gap-4">
        <Button
          variant="outline"
          onClick={closeModal}
          className="md:w-auto w-1/2"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          isLoading={loading || loadingUpdate}
          className="md:w-auto w-1/2"
          disabled={!form?.formState?.isDirty}
        >
          {title}
        </Button>
      </div>
    </form>
  );
}
