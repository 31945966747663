import React, { createContext, useState, useMemo } from "react";
import { EFeatureMode, IUserFeature } from "../types/AdvanceFeature";
import AdvanceFeatureRepository from "../repositories/AdvanceFeature";
import useApi from "@/hooks/useApi";
import { IResponseData } from "../types/requests";

export type AdvanceFeaturesContextState = {
  dynamicLayoutMode: EFeatureMode | undefined;
  landingPageFeature: IUserFeature | undefined;
  setLandingPageFeature: React.Dispatch<
    React.SetStateAction<IUserFeature | undefined>
  >;
  telegramFeature: IUserFeature | undefined;
  setTelegramFeature: React.Dispatch<
    React.SetStateAction<IUserFeature | undefined>
  >;
  multipleContact: IUserFeature | undefined;
  setMultipleContact: React.Dispatch<
    React.SetStateAction<IUserFeature | undefined>
  >;
  multipleAccount: IUserFeature | undefined;
  setMultipleAccount: React.Dispatch<
    React.SetStateAction<IUserFeature | undefined>
  >;
  enabledLayout: boolean;
  setDynamicLayoutMode: React.Dispatch<
    React.SetStateAction<EFeatureMode | undefined>
  >;
  setEnabledLayout: React.Dispatch<React.SetStateAction<boolean>>;
  getAdvanceFeatures: () => void;
};

export const AdvanceFeaturesContext = createContext<
  AdvanceFeaturesContextState | undefined
>(undefined);

export const AdvanceFeaturesProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [telegramFeature, setTelegramFeature] = useState<
    IUserFeature | undefined
  >(undefined);
  const [dynamicLayoutMode, setDynamicLayoutMode] = useState<
    EFeatureMode | undefined
  >(undefined);
  const [enabledLayout, setEnabledLayout] = useState<boolean>(false);
  const [landingPageFeature, setLandingPageFeature] = useState<
    IUserFeature | undefined
  >(undefined);
  const [multipleContact, setMultipleContact] = useState<
    IUserFeature | undefined
  >(undefined);
  const [multipleAccount, setMultipleAccount] = useState<
    IUserFeature | undefined
  >(undefined);

  const [directoryModule, setDirectoryModule] = useState<
    IUserFeature | undefined
  >(undefined);

  const {
    request: getAdvanceFeaturesByUser,
    response: allAvanceFeaturesByUser,
  } = useApi<IResponseData<IUserFeature[]>>({
    request: AdvanceFeatureRepository.getUserFeaturesById,
  });

  const getAdvanceFeatures = () => {
    // Implementation of the function
  };

  const value = useMemo(
    () => ({
      telegramFeature,
      setTelegramFeature,
      dynamicLayoutMode,
      enabledLayout,
      setDynamicLayoutMode,
      setEnabledLayout,
      landingPageFeature,
      setLandingPageFeature,
      multipleContact,
      setMultipleContact,
      multipleAccount,
      setMultipleAccount,
      getAdvanceFeatures,
      getAdvanceFeaturesByUser,
      allAvanceFeaturesByUser,
      setDirectoryModule,
      directoryModule,
    }),
    [
      allAvanceFeaturesByUser,
      dynamicLayoutMode,
      enabledLayout,
      getAdvanceFeaturesByUser,
      landingPageFeature,
      multipleAccount,
      multipleContact,
      telegramFeature,
      directoryModule,
      setDirectoryModule,
    ]
  );

  return (
    <AdvanceFeaturesContext.Provider value={value}>
      {children}
    </AdvanceFeaturesContext.Provider>
  );
};
