import { EFeatureMode, EFeatureType } from "@/utilities/types/AdvanceFeature";
import { z } from "zod";

export const telegramChatBotSchema = z.object({
  chatId: z.string().optional(),
  enable: z.boolean().optional(),
  type: z.nativeEnum(EFeatureType),
});

export const dynamicLayoutSchema = z.object({
  enable: z.boolean().optional(),
  dynamicLayoutMode: z.nativeEnum(EFeatureMode),
  type: z.nativeEnum(EFeatureType),
});

export const landingPageSchema = z.object({
  enable: z.boolean().optional(),
  useProjectSgTrackingId: z.boolean().optional(),
  type: z.nativeEnum(EFeatureType),
});

export const multipleContactSchema = z.object({
  enable: z.boolean().optional(),
  type: z.nativeEnum(EFeatureType),
});

export const teamAccountSchema = z.object({
  enable: z.boolean().optional(),
  type: z.nativeEnum(EFeatureType),
});

export const directorySchema = z.object({
  enable: z.boolean().optional(),
  type: z.nativeEnum(EFeatureType),
});

// form zod validation schema
export const advanceFeatures = z.object({
  telegramBot: telegramChatBotSchema.optional(),
  dynamicLayout: dynamicLayoutSchema.optional(),
  landingPage: landingPageSchema.optional(),
  multipleContact: multipleContactSchema.optional(),
  teamAccount: teamAccountSchema.optional(),
  directory: directorySchema.optional(),
});

// generate form types from zod validation schema
export type AdvanceFeaturesSchema = z.infer<typeof advanceFeatures>;
