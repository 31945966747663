import { useState, useRef, useCallback, useEffect, useMemo } from "react";

interface UseLazyloadProps<T> {
  /**
   * Initial data array
   */
  data: T[];

  /**
   * Total number of available items
   */
  totalItems: number;

  /**
   * Current page number
   */
  currentPage: number;

  /**
   * Loading state
   */
  isLoading: boolean;

  /**
   * Function to handle pagination (fetch the next page)
   */
  handlePaginate: (page: number) => void;

  /**
   * Optional callback to reset the data
   */
  onReset?: () => void;

  /**
   * Intersection observer threshold (default: 0.1)
   */
  threshold?: number;

  /**
   * Total number of pages
   */
  totalPage: number;
}

interface UseLazyloadReturn<T> {
  /**
   * Array of all accumulated data
   */
  items: T[];

  /**
   * Ref to attach to the loading element
   */
  loadingRef: React.RefObject<HTMLDivElement>;

  /**
   * Whether there is more data to load
   */
  hasMore: boolean;

  /**
   * Function to reset accumulated data
   */
  resetItems: () => void;
}

/**
 * A hook for implementing infinite scroll/lazy loading
 */
export function useLazyload<T>({
  data,
  totalItems,
  currentPage,
  isLoading,
  handlePaginate,
  onReset,
  totalPage,
  threshold = 0.5,
}: UseLazyloadProps<T>): UseLazyloadReturn<T> {
  const [items, setItems] = useState<T[]>([]);
  const loadingRef = useRef<HTMLDivElement>(null);

  // Update items when data changes
  useEffect(() => {
    if (currentPage === 1) {
      setItems(data);
    } else {
      setItems((prevItems) => [...prevItems, ...data]);
    }
  }, [data]);

  const hasMore = useMemo(() => {
    return currentPage < totalPage;
  }, [currentPage, totalPage]);

  // Reset items function
  const resetItems = useCallback(() => {
    setItems([]);
    if (onReset) {
      onReset();
    }
  }, [onReset]);

  // Handle intersection observer to detect when user scrolls to the end
  const handleObserver = useCallback(
    async (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target.isIntersecting && !isLoading && hasMore) {
        handlePaginate(currentPage + 1);
      }
    },
    [isLoading, hasMore, currentPage, handlePaginate]
  );

  // Setup intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      threshold,
    });

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [handleObserver, threshold]);

  return {
    items,
    loadingRef,
    hasMore,
    resetItems,
  };
}
