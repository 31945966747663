import { IconType } from "react-icons/lib";
import {
  PiBuildingApartmentDuotone,
  PiUsersDuotone,
  PiUserGearFill,
  PiWhatsappLogo,
  PiBrowserDuotone,
  PiSignIn,
  PiNotification,
  PiWebhooksLogo,
  PiCardsThree,
  PiUsersThreeDuotone,
  PiTelegramLogoDuotone,
} from "react-icons/pi";
import {
  HiOutlineLocationMarker,
  HiOutlineDatabase,
  HiOutlineServer,
  HiOutlinePuzzle,
  HiDocumentText,
} from "react-icons/hi";
import { atom } from "jotai";
import { routes } from "@/config/routes.ts";
import {
  MdOutlineContactPhone,
  MdOutlineEmail,
  MdOutlinePrivacyTip,
  MdSell,
} from "react-icons/md";
import { Roles } from "@/utilities/types/Users";
import { BiBuilding } from "react-icons/bi";
import { BsActivity, BsBank } from "react-icons/bs";
import { EFeatureType } from "@/utilities/types/AdvanceFeature";
import { IoAnalytics } from "react-icons/io5";

export interface SubMenuItemType {
  name: string;
  description?: string;
  href: string;
  badge?: string;
  permissions?: Roles[];
}

export interface ItemType {
  name: string;
  icon: IconType;
  href?: string;
  description?: string;
  badge?: string;
  subMenuItems?: SubMenuItemType[];
  permissions?: Roles[];
}

export interface MenuItemsType {
  id: string;
  name: string;
  title: string;
  icon: IconType;
  menuItems?: ItemType[];
  href?: string;
  permissions?: Roles[];
  advanceFeatureType?: EFeatureType;
}

export const carbonMenuItems: MenuItemsType[] = [
  {
    id: "dashboard",
    name: "Dashboard",
    title: "Overview",
    icon: PiBrowserDuotone,
    href: "/",
    permissions: [Roles.ADMIN, Roles.AGENCY],
  },
  {
    id: "ad-report",
    name: "Ad Report",
    title: "Ad Report",
    icon: IoAnalytics,
    href: routes.adReport,
    permissions: [Roles.AGENCY],
  },

  {
    id: "users",
    name: "Users",
    title: "Users Management",
    icon: PiUsersDuotone,
    href: routes.user.listing,
    permissions: [Roles.ADMIN],
  },
  {
    id: "allProjects",
    name: "All Projects",
    title: "Projects Management",
    icon: PiBuildingApartmentDuotone,
    href: routes.project.listing,
    permissions: [Roles.ADMIN],
  },
  {
    id: "projectsInventory",
    name: "Projects Inventory",
    title: "Projects Management",
    advanceFeatureType: EFeatureType.Directory,
    icon: PiBuildingApartmentDuotone,
    href: routes.project.listing,
    permissions: [Roles.AGENCY],
  },
  {
    id: "locations",
    name: "All Districts",
    title: "All Districts",
    icon: HiOutlineLocationMarker,
    href: routes.location.listing,
    permissions: [Roles.ADMIN],
  },
  {
    id: "agent-project",
    name: "My Website’s Projects",
    title: "My Website’s Projects",
    advanceFeatureType: EFeatureType.Directory,
    icon: BiBuilding,
    href: routes.project.agentListing,
    permissions: [Roles.AGENCY],
  },
  {
    id: "project-landing-page",
    name: "Landing Pages",
    advanceFeatureType: EFeatureType.LandingPage,
    title: "Landing Pages",
    icon: PiCardsThree,
    href: routes.landingPages.listing,
    permissions: [Roles.ADMIN, Roles.AGENCY],
  },
  {
    id: "agentLocation",
    name: "All Districts",
    title: "All Districts",
    icon: HiOutlineLocationMarker,
    advanceFeatureType: EFeatureType.Directory,
    href: routes.agentLocation.listing,
    permissions: [Roles.AGENCY],
  },
  {
    id: "settingWebhook",
    name: "Webhook",
    title: "Webhook",
    icon: PiWebhooksLogo,
    href: routes.webhook,
    permissions: [Roles.ADMIN],
  },
  {
    id: "allLeads",
    name: "All Leads",
    title: "All Leads",
    icon: MdOutlineContactPhone,
    href: routes.contacts.listing,
    permissions: [Roles.ADMIN, Roles.AGENCY, Roles.USER],
  },
  {
    id: "team-account",
    name: "My Team",
    advanceFeatureType: EFeatureType.TeamAccount,
    title: "My Team",
    icon: PiUsersThreeDuotone,
    href: routes.team.listing,
    permissions: [Roles.AGENCY],
  },
  {
    id: "round-robin",
    advanceFeatureType: EFeatureType.TeamAccount,
    name: "Round Robin Setting",
    title: "Round Robin Setting",
    icon: MdOutlineContactPhone,
    href: routes.robin.listing,
    permissions: [Roles.AGENCY],
  },
  {
    id: "websiteSetting",
    name: "Website Setting",
    title: "Website Setting",
    icon: PiUserGearFill,
    href: routes.setting.agency,
    permissions: [Roles.AGENCY],
  },

  {
    id: "unitTypes",
    name: "Unit Types",
    title: "Unit Types",
    icon: HiOutlineDatabase,
    href: routes.unitType.listing,
    permissions: [Roles.ADMIN],
  },
  {
    id: "projectCategory",
    name: "Project Category",
    title: "Project Category",
    icon: HiOutlineServer,
    href: routes.projectCategory.listing,
    permissions: [Roles.ADMIN],
  },
  {
    id: "developer",
    name: "Developer",
    title: "Developer",
    icon: HiOutlinePuzzle,
    href: routes.developer.listing,
    permissions: [Roles.ADMIN],
  },
  // {
  //   id: "promotionBanner",
  //   name: "Promotion Banner",
  //   title: "Promotion Banner",
  //   icon: HiOutlineTicket,
  //   href: routes.promotionBanner.listing,
  //   permissions: [Roles.ADMIN, Roles.AGENCY],
  // },
  // {
  //   id: "userPreference",
  //   name: "User Preference Enquiries",
  //   title: "User Preference Enquiries",
  //   icon: HiOutlineDocumentReport,
  //   href: routes.userPreference.listing,
  //   permissions: [Roles.AGENCY],
  // },

  {
    id: "siteContent",
    name: "System Pages",
    title: "System Pages",
    icon: HiDocumentText,
    href: routes.siteContent.listing,
    advanceFeatureType: EFeatureType.Directory,
    permissions: [Roles.AGENCY],
  },
  {
    id: "whatsapp",
    name: "WhatsApp",
    title: "WhatsApp",
    icon: PiWhatsappLogo,
    href: routes.whatsapp.listing,
    permissions: [Roles.ADMIN],
  },

  {
    id: "emailTemplate",
    name: "Email Template",
    title: "Email Template",
    icon: MdOutlineEmail,
    href: routes.emailTemplate,
    permissions: [Roles.ADMIN],
  },

  {
    id: "privacy-policy",
    name: "Privacy Policy Template",
    title: "Privacy Policy Template",
    icon: MdOutlinePrivacyTip,
    href: routes.privacyPolicy,
    permissions: [Roles.ADMIN],
  },

  {
    id: "import-data",
    name: "Import Data",
    title: "Import Data",
    icon: PiSignIn,
    href: routes.importData.listing,
    permissions: [Roles.ADMIN],
  },
  {
    id: "disclaimer",
    name: "Disclaimer",
    title: "Disclaimer",
    icon: PiNotification,
    href: routes.disclaimer.listing,
    permissions: [Roles.ADMIN],
  },
  {
    id: "mortgage",
    name: "Mortgage",
    title: "Mortgage",
    icon: BsBank,
    href: routes.mortgage.listing,
    permissions: [Roles.ADMIN],
  },

  {
    id: "loginActivity",
    name: "Login Activity",
    title: "Login Activity",
    icon: BsActivity,
    href: routes.loginActivity,
    permissions: [Roles.ADMIN],
  },

  // {
  //   id: "layout",
  //   name: "Layout",
  //   title: "Layout",
  //   icon: HiOutlineDeviceTablet,
  //   href: routes.layout.listing,
  //   permissions: [Roles.AGENCY],
  // },
  {
    id: "follow-up",
    name: "WhatsApp Template",
    title: "WhatsApp Template",
    icon: PiWhatsappLogo,
    href: routes.followUp.listing,
    permissions: [Roles.AGENCY],
  },
  {
    id: "advanced-features",
    advanceFeatureType: EFeatureType.IpAccessTrackerTelegram,
    name: "Telegram Setting",
    title: "Telegram Setting",
    icon: PiTelegramLogoDuotone,
    href: routes.advanceFeatures,
    permissions: [Roles.AGENCY],
  },

  {
    id: "buy-domain",
    name: "Buy Domain",
    title: "Buy Domain",
    icon: MdSell,
    href: "https://websuite.project.sg/",
    permissions: [Roles.AGENCY],
  },
];

export const carbonMenuItemAtom = atom(carbonMenuItems[0]);
