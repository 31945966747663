import { IconType } from "react-icons";
import { Button } from "rizzui";

interface DomainOptionProps {
  icon: IconType;
  title: string;
  description: string;
  onChoose: () => void;
}

const DomainOption = ({
  icon: Icon,
  title,
  description,
  onChoose,
}: DomainOptionProps) => {
  return (
    <div className="flex flex-col items-center p-6 rounded-lg border border-gray-200 hover:border-blue-200 transition-colors">
      <div className="mb-4">
        <Icon className="w-12 h-12 text-blue-400" />
      </div>
      <h3 className="text-xl font-semibold mb-3">{title}</h3>
      <p
        className="text-gray-500 text-center mb-6"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className="mt-auto">
        <p className="mb-3 font-medium text-center">How to use</p>
        <Button
          variant="solid"
          className="rounded-full px-8"
          onClick={onChoose}
        >
          CHOOSE
        </Button>
      </div>
    </div>
  );
};

export default DomainOption;
