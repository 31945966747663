import { IUserData, Roles } from "@/utilities/types/Users";
import { HeaderCell } from "@/components/ui/table";
import DeletePopover from "@/app/shared/delete-popover";
import { Controller, UseFormReturn } from "react-hook-form";
import CellEdit from "@/components/table-cell-custom/cell-edit";
import { useEffect, useState } from "react";
import { ILandingPage } from "@/utilities/types/LandingPage";
import { TLandingPageSchemaForm } from "@/utilities/validators/landing-page";
import { APP_SUPPORT_DOMAIN, SUFFIX_DOMAIN } from "@/config/constants";
import toast from "react-hot-toast";
import React from "react";
import { routes } from "@/config/routes";
import { Link } from "react-router-dom";
import SelectOptionDomain from "./select-option-domain";
import { ILandingPageOption } from "@/utilities/types/LandingPageOption";
import { useCheckUniqueDomain } from "@/hooks/useCheckUniqueDomain";
import { useAuthHelper } from "@/components/auth/helper";
import CustomPopover from "@/app/shared/custom-popover";
import { ActionIcon, Button, Tooltip } from "rizzui";
import PencilIcon from "@/components/icons/pencil";
import { BiCheck, BiDetail } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";

type LandingPagesColumnsProps = {
  data: ILandingPage[];
  onDeleteItem?: (id: string) => void;
  onHeaderCellClick: (value: string) => void;
  reset?: () => void;
  role: Roles;
  projectId: string;
  profile: IUserData | null;
  onUpdateItem: (
    id: string,
    data: Partial<TLandingPageSchemaForm>
  ) => Promise<void>;
  onAddLandingPage: (data: TLandingPageSchemaForm) => Promise<void>;
  form: UseFormReturn<TLandingPageSchemaForm>;
  ldpOtions: ILandingPageOption[];
};

export const GetColumns = ({
  data,
  onHeaderCellClick,
  onDeleteItem,
  role,
  reset,
  projectId,
  onUpdateItem,
  onAddLandingPage,
  profile,
  ldpOtions,
  form,
}: LandingPagesColumnsProps) => {
  const ldpOptionDataDefault = ldpOtions.find((item) => item.name === "O1");

  const [addData, setAddData] = useState<TLandingPageSchemaForm>({
    name: "",
    domain: "",
    layoutOptionId: ldpOptionDataDefault?.id || "",
  });

  const { watch, setError, clearErrors } = form;

  const formValues = watch();

  const { domainIsAvailable, error } = useCheckUniqueDomain({
    domain: formValues.domain || "",
  });

  const handleAddSubmit = async () => {
    const dataToCreate = {
      projectId: formValues.project?.value,
      userDomainId: formValues.user,
      domain: `${formValues.domain}`,
      name: `${formValues.name}`,
      layoutOptionId: addData?.layoutOptionId || ldpOptionDataDefault?.id || "",
    };

    try {
      await onAddLandingPage(dataToCreate);
      form.reset({
        name: "",
        domain: "",
        project: {
          value: projectId ?? "",
          label: "",
        },
        user: profile?.role === Roles.ADMIN ? "" : profile?.id || "",
      });
      setAddData({
        name: "",
        domain: "",
      });
      reset?.();
    } catch (error) {
      toast.error("Failed to add landing page");
    }
  };

  useEffect(() => {
    if (error?.message) {
      setError("domain", { message: error?.message || "", type: "custom" });
    } else {
      clearErrors("domain");
    }
  }, [error]);

  const { shareToken } = useAuthHelper();

  const columns = [
    {
      title: <HeaderCell title="Name" align="left" />,
      onHeaderCell: () => onHeaderCellClick("name"),
      dataIndex: "name",
      key: "name",
      render: (name: string, row: ILandingPage & { isAdd?: boolean }) => (
        <Controller
          name="name"
          control={form.control}
          render={({ field, fieldState }) => (
            <CellEdit
              defaultValue={name}
              size="md"
              onSubmit={async (value) => {
                if (row.isAdd) {
                  setAddData((prev) => ({ ...prev, name: value }));
                } else {
                  await onUpdateItem(row.id, {
                    ...addData,
                    name: value,
                    domain: row.domain,
                  });
                }
              }}
              onChange={field.onChange}
              defaultToggle={row.isAdd}
              error={fieldState.error?.message || ""}
            />
          )}
        />
      ),
    },
    {
      title: <HeaderCell title="Domain" align="left" />,
      onHeaderCell: () => onHeaderCellClick("domain"),
      dataIndex: "domain",
      key: "domain",
      render: (domain: string, row: ILandingPage & { isAdd?: boolean }) => (
        <Controller
          name="domain"
          control={form.control}
          defaultValue={row.isAdd ? `${domain}${SUFFIX_DOMAIN}` : domain}
          render={({ field, fieldState }) => (
            <>
              <CellEdit
                defaultValue={row.isAdd ? `${domain}${SUFFIX_DOMAIN}` : domain}
                size="md"
                onSubmit={async (value) => {
                  if (fieldState.invalid) {
                    throw new Error(
                      fieldState.error?.message || "Please valid input"
                    );
                  }

                  if (row.isAdd) {
                    setAddData((prev) => ({ ...prev, domain: value }));
                    return;
                  }
                  await onUpdateItem(row.id, {
                    ...addData,
                    domain: value,
                    name: row.name,
                  });
                }}
                onChange={(e) => {
                  field.onChange(e);
                }}
                defaultToggle={row.isAdd}
                error={fieldState.error?.message || ""}
              />
            </>
          )}
        />
      ),
    },
    {
      title: <HeaderCell title="Preview" align="left" />,
      onHeaderCell: () => onHeaderCellClick("preview"),
      dataIndex: "preview",
      key: "preview",
      render: (name: string, row: ILandingPage) => (
        <div className="flex flex-col">
          <a
            href={`https://${row.domain}`}
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            {`https://${row.domain}`}
          </a>
          {profile?.role === Roles.ADMIN && !projectId && (
            <>
              <div className="flex gap-1">
                <span className="font-medium text-gray-400"> User: </span>
                <span className="font-medium">
                  {`${row.user?.firstName} ${row.user?.lastName}`}
                </span>
                <span className="text-gray-400">{row.user?.email}</span>
              </div>
              <div className="flex flex-wrap gap-x-1">
                <span className="text-gray-400 whitespace-nowrap">
                  Original Domain:
                </span>
                <span className="">
                  {row?.user?.domains?.find((domain) => domain?.primary)?.name}
                </span>
              </div>
              <div className="flex flex-wrap gap-x-1">
                <span className="text-gray-400 whitespace-nowrap">
                  Project:
                </span>
                <Link
                  to={
                    profile?.role === Roles.ADMIN
                      ? routes.project.edit(row?.project?.id)
                      : routes.project.details(row?.project?.id || "")
                  }
                  className="underline"
                >
                  {String(row?.project?.name)}
                </Link>
              </div>
            </>
          )}
        </div>
      ),
    },

    {
      title: (
        <HeaderCell
          className="w-[200px]"
          title="Layout Option"
          align="left"
          width={200}
        />
      ),
      dataIndex: "layoutOption",
      key: "layoutOption",
      render: (name: string, row: ILandingPage & { isAdd?: boolean }) => {
        const currentActiveOption = row?.optionMappings?.find(
          (option) => option.active
        )?.landingPageOption;
        return row.id ? (
          <SelectOptionDomain
            ldpOptsListing={ldpOtions || []}
            optionsMapping={row?.optionMappings || []}
            ldpId={row.id}
            isAdding={row.isAdd}
            defaultValue={currentActiveOption?.id || ldpOptionDataDefault?.id}
            onSelectOption={
              row.isAdd
                ? (optionId) =>
                    setAddData((prev) => ({
                      ...prev,
                      layoutOptionId: optionId,
                    }))
                : undefined
            }
          />
        ) : (
          <></>
        );
      },
    },
    {
      title: (
        <HeaderCell
          className="ps-3"
          title={<span className="whitespace-nowrap">Actions</span>}
        />
      ),
      dataIndex: "action",
      key: "action",
      width: 120,
      render: (_: string, row: ILandingPage & { isAdd?: boolean }) => {
        const domain = row?.domain;
        const originalDomain = row?.user?.domains?.find(
          (domain) => domain?.primary
        )?.name;

        const aliasEditDynamic = `https://${APP_SUPPORT_DOMAIN}/editor/landing-page?domain=${domain}&originalDomain=${originalDomain}`;

        return (
          <div className="flex flex-row items-center justify-center">
            {role === Roles.ADMIN && (
              <>
                <MemoizedActionAdmin
                  onDeleteItem={() => onDeleteItem?.(row.id)}
                />

                {row.isAdd && (
                  <CustomPopover
                    title="Add Landing Page"
                    description="Are you sure to add this Landing Page?"
                    onAccept={handleAddSubmit}
                  >
                    <Tooltip
                      size="sm"
                      content="Submit"
                      placement="top"
                      color="invert"
                    >
                      <ActionIcon size="sm" variant="text">
                        <BiCheck className="h-4 w-4" />
                      </ActionIcon>
                    </Tooltip>
                  </CustomPopover>
                )}
              </>
            )}

            {!row.isAdd && (
              <CustomPopover
                title="Edit Landing Page"
                onAccept={async () => {
                  if (APP_SUPPORT_DOMAIN) {
                    await shareToken?.();
                    window.location.href = aliasEditDynamic;
                  }
                }}
                description=""
              >
                <Tooltip
                  size="sm"
                  content="Edit"
                  placement="top"
                  color="invert"
                >
                  <ActionIcon size="sm" variant="text">
                    <PencilIcon className="h-4 w-4" />
                  </ActionIcon>
                </Tooltip>
              </CustomPopover>
            )}
          </div>
        );
      },
    },
  ];

  return columns;
};

export const GetLightColumns = ({
  onHeaderCellClick,
  profile,
  projectId,
  data,
}: Pick<
  LandingPagesColumnsProps,
  "onHeaderCellClick" | "profile" | "projectId" | "data"
>) => {
  return [
    {
      title: <HeaderCell title="Domain" align="left" />,
      onHeaderCell: () => onHeaderCellClick("domain"),
      dataIndex: "domain",
      key: "domain",
      render: (domain: string, row: ILandingPage & { isAdd?: boolean }) => (
        <Link
          to={`https://${domain}`}
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          {domain}
        </Link>
      ),
    },

    {
      title: <HeaderCell title="Project" align="left" />,
      onHeaderCell: () => onHeaderCellClick("preview"),
      dataIndex: "project",
      key: "project",
      render: (name: string, row: ILandingPage) => (
        <div className="flex flex-col">
          {profile?.role === Roles.ADMIN && (
            <>
              <div className="flex flex-wrap gap-x-1">
                <p className="">{String(row?.project?.name)}</p>
              </div>
            </>
          )}
        </div>
      ),
    },
  ];
};

const ActionAdmin = ({ onDeleteItem }: { onDeleteItem: () => void }) => (
  <div className="flex items-center justify-center gap-3 pe-4">
    <DeletePopover
      title="Delete Landing Page"
      description="Are you sure to delete this Landing Page"
      onDelete={onDeleteItem}
    />
  </div>
);

const MemoizedActionAdmin = React.memo(ActionAdmin);
