import { IUserData } from "./Users";

interface IAgency {
  id: string;
  createdAt: string; // ISO string format
  updatedAt: string; // ISO string format
  deletedAt: string | null;
  email: string;
  status: string; // e.g., "active"
  role: string; // e.g., "admin"
  firstName: string;
  lastName: string;
  phone: string | null;
  whatsapp: string | null;
  resetPasswordToken: string | null;
  // measuringId: string | null;
  headerScript: string | null;
  bodyScript: string | null;
  enquiryScript: string | null;
  photoId: string | null;
  company: string;
  additionalEmail: string | null;
  ownerId: string | null;
  changePasswordLogMessage: string | null;
}

export interface ISchedule {
  id: string;
  createdAt: string; // ISO string format
  updatedAt: string; // ISO string format
  deletedAt: string | null;
  agencyId: string;
  startTime: string; // Time in "HH:mm" format
  endTime: string; // Time in "HH:mm" format
  users: IUserData[]; // Adjust the type if you have more details about `users`
  agency: IAgency;
}

export interface IScheduleDTO {
  agentIds: string[]; // id of user's agency
  startTime?: string;
  endTime?: string;
}

export enum ETimeSlotConfigMode {
  ROUND_ROBIN = "round_robin",
  DEFAULT = "default",
  OFF = "off",
}

export interface ITimeSlotConfig {
  id: string;
  createdAt: string; // ISO date string
  updatedAt: string; // ISO date string
  deletedAt: string | null;
  enabled: boolean;
  agencyId: string;
  mode: ETimeSlotConfigMode;
}

export interface ITimeSlotConfigDTO {
  enabled: boolean;
  mode: ETimeSlotConfigMode;
}
