import ControlledTable from "@/components/controlled-table";
import { useColumn } from "@/hooks/use-column";
import useProfile from "@/hooks/use-profile";
import { useTableServer } from "@/hooks/useTableServer.tsx";
import LandingPagesRepository from "@/utilities/repositories/LandingPages";
import { ILandingPage } from "@/utilities/types/LandingPage";
import { ColumnsType } from "rc-table";
import { GetLightColumns } from "./columns";

const LightLandingPageTable = ({ userId }: { userId: string }) => {
  const { profile } = useProfile();

  const {
    isLoading,
    tableData,
    totalItems,
    currentPage,
    pageSize,
    handlePaginate,
    handleChangePageSize,
  } = useTableServer({
    server: {
      request: LandingPagesRepository.getLandingPages,
    },
    initialFilterState: {
      userId: userId,
    },
    pushSearchParams: false,
    pagination: {
      limit: 5,
      page: 1,
    },
  });

  const columns = GetLightColumns({
    data: tableData,
    onHeaderCellClick: () => {},
    profile: profile,
    projectId: "",
  });
  const { visibleColumns } = useColumn(columns);

  if (!userId) {
    return <></>;
  }

  // Use the new useLazyload hook

  return (
    <div className="col-span-full mt-4">
      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        columns={visibleColumns as unknown as ColumnsType<ILandingPage>}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
          selectClassName: "!z-[10000]",
        }}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
};

export default LightLandingPageTable;
