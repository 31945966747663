import { Modal, Button } from "rizzui";

import { Template } from "./TemplateSections";
import { GoDeviceDesktop, GoDeviceMobile } from "react-icons/go";
import cn from "@/utils/class-names";
import { CgClose } from "react-icons/cg";

interface PreviewModalProps {
  template: Template | null;
  isOpen: boolean;
  onClose: () => void;
  viewMode: "desktop" | "mobile";
  onViewModeChange: (mode: "desktop" | "mobile") => void;
}

const PreviewModal = ({
  template,
  isOpen,
  onClose,
  viewMode,
  onViewModeChange,
}: PreviewModalProps) => {
  if (!template) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={cn("mx-auto w-full h-full bg-black")}
      size="full"
      containerClassName="p-0 relative overflow-y-auto"
    >
      <div className="relative bg-black/50 h-screen overflow-y-auto">
        {/* Sticky Header */}
        <div className="w-full bg-white sticky top-0 left-0 z-10">
          <div className="relative max-w-[1440px] w-full mx-auto flex justify-center space-x-4 mb-6 p-5">
            <Button
              variant={viewMode === "desktop" ? "solid" : "outline"}
              onClick={() => onViewModeChange("desktop")}
              className="flex items-center gap-2"
            >
              <GoDeviceDesktop className="w-5 h-5" />
              Desktop
            </Button>
            <Button
              variant={viewMode === "mobile" ? "solid" : "outline"}
              onClick={() => onViewModeChange("mobile")}
              className="flex items-center gap-2"
            >
              <GoDeviceMobile className="w-5 h-5" />
              Mobile
            </Button>
          </div>
        </div>

        <Button
          color="danger"
          onClick={onClose}
          className="fixed border-2 size-16 animate-pulse right-10 bottom-10 p-2 aspect-square flex items-center gap-2 rounded-full"
        >
          <CgClose className="size-8" />
        </Button>

        {/* Preview Content */}
        <div
          className={cn(
            "preview-container h-full mx-auto",
            viewMode === "mobile" ? "max-w-[375px]" : "max-w-[1440px]"
          )}
        >
          <img
            src={
              viewMode === "desktop"
                ? template.previewImage.desktop
                : template.previewImage.mobile
            }
            alt={`${template.name} - ${viewMode} view`}
            className="w-full h-auto"
          />
        </div>
      </div>
    </Modal>
  );
};

export default PreviewModal;
