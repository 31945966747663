import { useTableServer } from "@/hooks/useTableServer.tsx";
import ControlledTable from "@/components/controlled-table";
import { useColumn } from "@/hooks/use-column.ts";
import { Button, Title } from "rizzui";
import { GetColumns } from "./columns";
import RoundRobinRepository from "@/utilities/repositories/RoundRobin";
import { useModal } from "@/app/shared/modal-views/use-modal";
import TimeSlotDTO from "./create-edit/time-slot-dto";
import { useMemo } from "react";
import useApi from "@/hooks/useApi";
import { ISchedule, IScheduleDTO } from "@/utilities/types/RoundRobin";
import { IResponseData } from "@/utilities/types/requests";
import TimeSlotConfigMode from "./create-edit/time-slot-config-mode";

const RoundRobinTable = () => {
  const {
    // handleReset,
    isLoading,
    tableData,
    currentPage,
    totalItems,
    // handleSort,
    handlePaginate,
    pageSize,
    handleChangePageSize,
    refresh,
  } = useTableServer({
    server: {
      request: RoundRobinRepository.getTimeSlot,
    },
  });

  const { request: deleteTimeSlotItem } = useApi<IResponseData<IScheduleDTO>>({
    request: RoundRobinRepository.removeTimeSlot,
    enableToast: true,
  });

  // TODO: Waiting BE change API getList to get pagination

  // const { response, refetch, loading, request } = useApi<any>({
  //   request: RoundRobinRepository.getTimeSlot,
  // });

  // console.log("response", response);

  // const tableData = response?.data?.items || [];

  const { openModal } = useModal();

  const onOpenTimeSlotDTO = (
    isUpdate: boolean = false,
    defaultValue?: ISchedule
  ) => {
    openModal({
      view: (
        <TimeSlotDTO
          refetchData={() => handlePaginate(currentPage)}
          isUpdate={isUpdate}
          defaultValues={defaultValue}
        />
      ),
      size: "lg",
    });
  };

  const handleDeleteItem = async (id: string) => {
    await deleteTimeSlotItem(id);
    refresh();
  };

  const columns = useMemo(() => {
    return GetColumns({
      data: tableData,
      onUpdateAgents: (data) => onOpenTimeSlotDTO(true, data),
      onDeleteItem: (id) => handleDeleteItem(id),
    });
  }, [tableData]);

  const { visibleColumns } = useColumn<any>(columns);

  return (
    <div className={"col-span-full mt-4"}>
      <div className="mb-4 w-full flex flex-col lg:flex-row lg:items-center items-start gap-2.5 @container justify-between">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          Round Robin Setting
        </Title>
        <div className="flex flex-col w-full lg:w-fit lg:flex-row gap-3">
          <Button
            onClick={() => {
              onOpenTimeSlotDTO();
            }}
            className="order-2 lg:order-3"
          >
            Add new
          </Button>
        </div>
      </div>

      <div className="my-4 lg:max-w-[250px]">
        <TimeSlotConfigMode />
      </div>
      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoading}
        showLoadingText={true}
        columns={visibleColumns}
        paginatorOptions={{
          pageSize,
          setPageSize: handleChangePageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_.rc-table-row:last-child_td.rc-table-cell]:border-b-0 [&_thead.rc-table-thead]:border-t-0"
      />
    </div>
  );
};

export default RoundRobinTable;
