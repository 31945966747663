import { IUserData } from "@/utilities/types/Users";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import Cookies from "js-cookie";
import { useCallback, useEffect } from "react";
import { useAdvanceFeatures } from "./use-advance-features";
import { EFeatureType } from "@/utilities/types/AdvanceFeature";
import { COOKIES_KEYS } from "@/config/auth";

const Cookie = Cookies.get();
const initialProfile = Cookie[COOKIES_KEYS.AUTH_STATE];
const userFeat = Cookie[COOKIES_KEYS.USER_FEATS];

export const profileAtom = atomWithStorage<IUserData | null>(
  "atomProfile",
  initialProfile
    ? {
        ...JSON.parse(initialProfile),
        userFeatures: userFeat ? JSON.parse(userFeat) : [],
      }
    : null
);

export default function useProfile() {
  // TODO: refactor that
  const setAuth = useSignIn<IUserData>();
  const authHeader = useAuthHeader();
  const {
    dynamicLayoutMode,
    setEnabledLayout,
    setTelegramFeature,
    setDynamicLayoutMode,
    setLandingPageFeature,
    setMultipleContact,
    setMultipleAccount,
  } = useAdvanceFeatures();

  const [atomProfile, setAtomProfile] = useAtom(profileAtom);

  const Cookie = Cookies.get();
  const authState = Cookie[COOKIES_KEYS.AUTH_STATE];
  const userFeat = Cookie[COOKIES_KEYS.USER_FEATS];

  const defaultProfile = {
    ...(authState && JSON?.parse?.(authState)),
    userFeatures: userFeat ? JSON?.parse?.(userFeat) : [],
  };

  useEffect(() => {
    setAtomProfile(defaultProfile);
  }, [authState, userFeat]);

  const updateLayoutState = useCallback(() => {
    if (atomProfile && atomProfile?.userFeatures) {
      const featureLayout = atomProfile?.userFeatures?.find(
        (feature) => feature?.feature?.type === EFeatureType.DynamicLayout
      )?.enabled;
      const advanceLayout = atomProfile?.dynamicLayoutMode;
      const telegramFeature = atomProfile?.userFeatures?.find(
        (feature) =>
          feature?.feature?.type === EFeatureType.IpAccessTrackerTelegram
      );
      const landingPageFeature = atomProfile?.userFeatures?.find(
        (feature) => feature?.feature?.type === EFeatureType.LandingPage
      );

      const multipleContactFeature = atomProfile?.userFeatures?.find(
        (feature) => feature?.feature?.type === EFeatureType.MultipleContact
      );
      const multipleAccountFeature = atomProfile?.userFeatures?.find(
        (feature) => feature?.feature?.type === EFeatureType.TeamAccount
      );

      if (telegramFeature) {
        setTelegramFeature(telegramFeature);
      }

      if (landingPageFeature) {
        setLandingPageFeature(landingPageFeature);
      }

      if (multipleContactFeature) {
        setMultipleContact(multipleContactFeature);
      }

      if (multipleAccountFeature) {
        setMultipleAccount(multipleAccountFeature);
      }

      if (featureLayout) {
        setEnabledLayout(featureLayout ? featureLayout : false);
      }

      if (advanceLayout) {
        setDynamicLayoutMode(advanceLayout);
      }
    }
  }, [atomProfile]);

  // console.log(dynamicLayoutMode);

  useEffect(() => {
    updateLayoutState();
  }, [updateLayoutState]);

  if (!authHeader) {
    return {
      profile: defaultProfile ? (defaultProfile as IUserData) : null,
      setProfile: () => {},
    };
  }

  const [token] = authHeader.split(" ").reverse();

  const setProfile = ({ userFeatures, ...profile }: IUserData) => {
    setAuth({
      auth: {
        token: token,
        type: "Bearer",
      },
      userState: profile,
    });

    setAtomProfile({ ...profile, userFeatures });
    Cookies.set(COOKIES_KEYS.USER_FEATS, JSON.stringify(userFeatures || []));
  };

  return { profile: atomProfile, setProfile };
}
