import { z } from "zod";
import { messages } from "@/config/messages";
import { validateEmail } from "@/utils/validators/common-rules";
import formatPhoneNumber from "@/utilities/functions/format-phone-number";

// form zod validation schema
export const createUserSchema = z.object({
  firstName: z.string().min(1, { message: messages.firstNameRequired }),
  lastName: z.string().min(1, { message: messages.lastNameRequired }),
  email: validateEmail,
  password: z.string().min(1),
  phone: z
    .string({ message: "Phone number is require" })
    .trim() // Removes leading & trailing spaces
    .transform((val) => {
      const format = formatPhoneNumber(val);
      return format.replace(/\s+/g, "");
    }) // Removes all spaces
    .refine((val) => val && val.length === 8, {
      message: "Invalid phone number",
    }),
});

export const registerAgencySchema = z.object({
  firstName: z.string().min(1, { message: messages.firstNameRequired }),
  lastName: z.string().min(1, { message: messages.lastNameRequired }),
  email: validateEmail,
  password: z.string().min(1),
  phone: z
    .string({ message: "Phone number is require" })
    .trim() // Removes leading & trailing spaces
    .transform((val) => {
      const format = formatPhoneNumber(val);
      return format.replace(/\s+/g, "");
    }) // Removes all spaces
    .refine((val) => val && val.length === 8, {
      message: "Invalid phone number",
    }),
  salesTeamInfo: z.object({
    name: z
      .string({ message: "Agent Name is required" })
      .min(1, { message: "Agent Name is required" }),
    registrationNumber: z
      .string({ message: "CEA Registration Number is required" })
      .min(1, { message: "CEA Registration Number is required" }),
    currentEa: z
      .string({ message: "Current Agency is required" })
      .min(1, { message: "Current Agency is required" }),
    licenseNumber: z
      .string({ message: "Agency License Number is required" })
      .min(1, { message: "Agency License Number is required" }),
  }),
});

// generate form types from zod validation schema
export type CreateUserInput = z.infer<typeof createUserSchema>;
export type RegisterAgencyInput = z.infer<typeof registerAgencySchema>;
