import { Controller, useFormContext } from "react-hook-form";
import { Switch } from "rizzui";
import LightLandingPageTable from "../../project-landing-pages/table/table-light";
import { useParams } from "react-router-dom";

export interface LandingPageProps {
  isSuperAdmin?: boolean;
  name: string;
}

export default function LandingPage({ isSuperAdmin, name }: LandingPageProps) {
  const { control } = useFormContext();
  const { id } = useParams();
  return (
    <div className="grid grid-cols-1 gap-2 w-full">
      <div className="grid w-full items-start gap-32grid-cols-1">
        <h6 className="text-foreground md:text-base">Landing Page</h6>
      </div>
      <div className="grid w-full items-start gap-3 grid-cols-1">
        {isSuperAdmin && (
          <div className="flex flex-col gap-2">
            <Controller
              control={control}
              name={`${name}.enable`}
              render={({ field, fieldState, formState }) => (
                <Switch
                  label="Enable"
                  size="lg"
                  className=" w-auto"
                  {...field}
                  {...formState}
                  checked={field.value || ""}
                  error={fieldState.error?.message}
                />
              )}
            />

            <div className="flex flex-col gap-2">
              <h6 className="text-foreground md:text-base">
                Landing Page Configuration
              </h6>
              <Controller
                control={control}
                name={`${name}.useProjectSgTrackingId`}
                render={({ field, fieldState, formState }) => (
                  <Switch
                    label="ProjectSG Tracking ID"
                    size="lg"
                    className=" w-auto"
                    {...field}
                    {...formState}
                    checked={field.value || ""}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </div>
          </div>
        )}
      </div>

      <div className="grid w-full items-start gap-3 grid-cols-1">
        <h6 className="text-foreground md:text-base">Agency's Landing Pages</h6>

        <LightLandingPageTable userId={id || ""} />
      </div>
    </div>
  );
}
