import ReactQuill, { type ReactQuillProps } from "react-quill";
import { FieldError } from "rizzui";
import cn from "@/utils/class-names";
import "react-quill/dist/quill.snow.css";
import { useCallback, useEffect, useRef } from "react";
import "./custom.css";
import UploadRepository from "@/utilities/repositories/Upload";
import toast from "react-hot-toast";
import { convertUrl } from "@/utilities/functions";
interface QuillEditorProps extends ReactQuillProps {
  error?: string;
  label?: React.ReactNode;
  className?: string;
  labelClassName?: string;
  errorClassName?: string;
  toolbarPosition?: "top" | "bottom";
  // toolbar?: any[];
  topMessageBackground?: string;
}

export default function QuillEditor({
  label,
  error,
  className,
  labelClassName,
  errorClassName,
  toolbarPosition = "top",
  // toolbar,
  topMessageBackground = "transparent",
  ...props
}: QuillEditorProps) {
  const quillRef = useRef<ReactQuill | null>(null);

  const handleQuillUpload = async (file: File) => {
    try {
      const response = await UploadRepository.uploadImages(file);
      return response;
    } catch (error: unknown) {
      console.log(error);
    }
  };
  const imageHandler = useCallback(async (file: File) => {
    console.log(file);
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        const file = input.files[0];
        const response = await toast.promise(handleQuillUpload(file), {
          loading: "Uploading...",
          success: "Upload file success",
          error: "Upload failed, please try again",
        });

        const urls = response?.data?.urls || [];

        const srcsetConverted = urls.map(
          (item) => `${convertUrl(item.url)} ${item.width}px`
        );

        const url =
          srcsetConverted?.[srcsetConverted.length - 1]?.split(" ")[0];

        const quill = quillRef.current;
        if (quill) {
          const range = quill.getEditorSelection();
          const editor = quill.getEditor();
          range && editor.insertEmbed(range.index, "image", url);

          const imgNode = editor.root.querySelector('img[src="' + url + '"]');
          if (imgNode) {
            imgNode.setAttribute("srcset", srcsetConverted.join(", "));
          }
        }
      }
    };
  }, []);

  const quillModules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        ["bold", "italic", "underline", "strike"], // toggled buttons
        // ["blockquote", "code-block"],
        ["link", "image"],

        [{ list: "ordered" }, { list: "bullet" }],
        // [{ script: "sub" }, { script: "super" }], // superscript/subscript
        // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ font: [] }],
        [{ align: [] }],

        ["clean"],
      ],

      handlers: {
        image: imageHandler,
      },
    },
  };

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.root.style.backgroundColor = topMessageBackground;
    }
  }, [topMessageBackground]);

  return (
    <div className={cn(className)}>
      {label && (
        <label className={cn("mb-1.5 block", labelClassName)}>{label}</label>
      )}
      <ReactQuill
        modules={quillModules}
        ref={quillRef}
        // formats={quillFormats}

        className={cn(
          "react-quill",
          toolbarPosition === "bottom" && "react-quill-toolbar-bottom relative",
          className
        )}
        {...props}
      />
      {error && (
        <FieldError size="md" error={error} className={errorClassName} />
      )}
    </div>
  );
}
