import { motion } from "framer-motion";
import ImageApp from "../ui/image-app";
import { Badge, Title } from "rizzui";
import cn from "@/utils/class-names";
import { IMAGES } from "@/assets/images";

interface ProjectCardProps {
  image?: string;
  name: string;
  tenure: string;
  location: string;
  category: string;
  className?: string;
  isActive?: boolean;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  image,
  name,
  tenure,
  location,
  category,
  className,
  isActive,
}) => {
  const fallbackRandom = [
    IMAGES.PJ_HOLDER_1,
    IMAGES.PJ_HOLDER_2,
    IMAGES.PROJ_IMAGE_HOLDER,
  ];
  return (
    <motion.div
      className={cn(
        "relative group aspect-[4/3] w-full h-auto rounded-xl overflow-hidden shadow-lg cursor-pointer",
        className
      )}
      whileHover={{ scale: 1.05 }}
    >
      <ImageApp
        inApp={!image}
        src={image || IMAGES.PJ_HOLDER_1}
        fallback={
          fallbackRandom[Math.floor(Math.random() * fallbackRandom.length)]
        }
        alt={name}
        className="w-full h-full object-cover"
      />

      <motion.div
        className="absolute transition-all duration-300 group-hover:opacity-0 inset-0 bg-opacity-70 flex flex-col justify-end  text-white p-4 opacity-100"
        whileHover={{ opacity: 0 }}
      >
        <Badge className="justify-start bg-black/60" rounded="md">
          <Title as="h6" className="text-white text-left">
            {name}
          </Title>
        </Badge>
      </motion.div>
      <motion.div
        className="absolute inset-0 bg-black bg-opacity-70 flex flex-col justify-end  text-white p-4 opacity-0"
        whileHover={{ opacity: 1 }}
      >
        {tenure && (
          <Badge
            className="capitalize absolute top-2 left-2"
            color="secondary"
            size="sm"
            rounded="md"
            variant="flat"
          >
            {tenure}
          </Badge>
        )}

        {isActive && (
          <Badge
            className="capitalize absolute top-2 right-2"
            color="success"
            size="sm"
            rounded="md"
            variant="flat"
          >
            Picking
          </Badge>
        )}

        <div className="text-left">
          <Title as="h6" className="text-white">
            {name}
          </Title>
          <p className="text-sm mt-2">{location}</p>
          <p className="text-sm mt-2">{category}</p>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ProjectCard;
