import cn from "@/utils/class-names";
import SimpleBar from "@/components/ui/simplebar";
import Logo from "@/components/logo";

import { Link } from "react-router-dom";
import { CarbonSidebarMenu } from "./carbon-sidebar-menu";
import ProfileCardMenu from "./profile-card-menu";

import { PiDotsThreeVerticalBold } from "react-icons/pi";
import useProfile from "@/hooks/use-profile";
import { useEffect, useState } from "react";
import { Roles } from "@/utilities/types/Users.ts";
import { ActionIcon } from "rizzui";
import { MdClose } from "react-icons/md";
import { useDrawer } from "@/app/shared/drawer-views/use-drawer";
import { useAdvanceFeatures } from "@/hooks/use-advance-features";
import DynamicLayoutButton from "@/app/app-shared/advanced-features/features/DynamicLayoutButton";

const ASSET_BASE_URL = import.meta.env.VITE_ASSET_BASE_URL as string;

export function CarbonDrawerSidebar({ className }: { className?: string }) {
  const { profile } = useProfile();
  const { enabledLayout } = useAdvanceFeatures();
  const [urlImage, setUrlImage] = useState<string>(
    "https://isomorphic-furyroad.s3.amazonaws.com/public/avatars/avatar-12.webp"
  );
  useEffect(() => {
    if (profile?.photo?.urls[0])
      setUrlImage(`${ASSET_BASE_URL}${profile.photo.urls[0].url}`);
  }, [profile]);

  const { closeDrawer } = useDrawer();

  return (
    <aside
      className={cn(
        "fixed flex justify-between flex-col bottom-0 start-0 z-50 h-full w-[270px] border-e-2 border-gray-100 bg-transparent dark:bg-transparent 2xl:w-72",
        className
      )}
    >
      <div className="sticky top-0 z-40 bg-transparent px-6 pb-5 pt-5 dark:bg-transparent 2xl:px-8 2xl:pt-6">
        <div className="flex justify-between items-center">
          <Link
            to={"/"}
            aria-label="Site Logo"
            className="text-gray-800 hover:text-gray-900"
          >
            <Logo className="max-w-[155px]" />
          </Link>
          <ActionIcon onClick={closeDrawer} variant="text">
            <MdClose size={25} />
          </ActionIcon>
        </div>
      </div>

      <SimpleBar
        className={cn(
          "flex-1 overflow-auto",
          profile?.role === Roles.ADMIN && "h-[calc(100%-175px)]",
          profile?.role === Roles.AGENCY && enabledLayout
            ? "h-[calc(100%-175px)]"
            : "h-[calc(100%-75px)]"
        )}
      >
        <CarbonSidebarMenu />
      </SimpleBar>
      {enabledLayout && (
        <div className="w-[270px] mx-[14px] my-1 ">
          <DynamicLayoutButton isSuperAdmin={profile?.role === Roles.ADMIN} />
        </div>
      )}

      <div className="relative mt-auto bg-gray-0 px-6 py-1 dark:bg-gray-100">
        {/* <WorkSpaceSwitcher
          dropdownClassName="max-w-[270px]"
          selectClassName="border-0 border-t-2 border-gray-200 rounded-none"
          suffixClassName="rotate-180"
        /> */}
        <ProfileCardMenu
          title={profile?.firstName + " " + profile?.lastName}
          designation={
            profile?.role === Roles.AGENCY
              ? profile?.company || ""
              : profile?.role
          }
          placement="top"
          image={urlImage}
          avatarClassName="!w-10 !h-10"
          icon={
            <PiDotsThreeVerticalBold
              className={cn(
                "h-7 w-7 text-gray-400 transition-all group-hover:text-primary"
              )}
            />
          }
          className={cn("px-0 py-0")}
          buttonClassName="border-0 !border-t !border-gray-200 pt-5 px-0 rounded-none"
        />
      </div>
    </aside>
  );
}
