import { Select, SelectOption } from "rizzui";

export interface StatusAccountProps {
  status: "active" | "inactive";
  onChange: (value: SelectOption) => Promise<void>;
}

export default function StatusAccount({
  status = "inactive",
  onChange,
}: StatusAccountProps) {
  return (
    <Select
      value={status}
      onChange={onChange}
      options={[
        { value: "active", label: "Active", color: "green" },
        { value: "inActive", label: "Inactive", color: "red" },
      ]}
      displayValue={(value) => {
        return value === "active" ? "Active" : "Inactive";
      }}
    />
  );
}
