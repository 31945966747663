import UserSelect from "@/components/user-select";
import REGEX from "@/config/regex";
import useProfile from "@/hooks/use-profile";
import SettingsRepository from "@/utilities/repositories/Settings";
import { Roles } from "@/utilities/types/Users";
import cn from "@/utils/class-names";
import { zodResolver } from "@hookform/resolvers/zod";
import { debounce } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { BiPlanet, BiRocket } from "react-icons/bi";
import { BsQuestionCircle } from "react-icons/bs";
import { MdAutoMode } from "react-icons/md";
import {
  Badge,
  Button,
  Input,
  Modal,
  RadioGroup,
  Select,
  SelectOption,
  Tooltip,
} from "rizzui";
import { z } from "zod";

const createDomainSchema = (type: "custom" | "subdomain") =>
  z.object({
    domain: z
      .string()
      .min(1, "Domain is required")
      .refine(
        (val) => {
          if (type === "custom") {
            return REGEX.CHECK_DOMAIN.test(val);
          } else {
            // For subdomain, only allow alphanumeric and hyphens, not allow uppercase
            return REGEX.CHECK_SUBDOMAIN.test(val);
          }
        },
        type === "custom"
          ? "Please enter a valid domain"
          : "Please enter a valid subdomain (only lowercase letters, numbers, and hyphens allowed)"
      ),
    userId: z.string().min(1, "User is required"),
    domainName: z.string().min(1, "Name is required"),
    selectedSuffix: z.string().min(1, "Suffix domain is required"),
  });

export type CustomizeDomainForm = {
  domain: string;
  userId: string;
  domainName: string;
  selectedSuffix: string;
};

interface DomainConfigModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: "custom" | "subdomain";
  onSubmit: (values: CustomizeDomainForm) => void;
  suffixDomains: string[];
  defaultSuffixDomain: string;
  initialValues?: Partial<CustomizeDomainForm>;
  updateDomain?: boolean;
  projectId?: string;
  disabledChangeDomain?: boolean;
  domainLabelQuestionTooltip?: React.ReactNode;
}

export default function DomainConfigModal({
  isOpen,
  onClose,
  type: initialType,
  onSubmit,
  suffixDomains,
  defaultSuffixDomain,
  initialValues,
  updateDomain,
  projectId,
  disabledChangeDomain,
  domainLabelQuestionTooltip,
}: DomainConfigModalProps) {
  const [type, setType] = useState<"custom" | "subdomain">(() => {
    if (updateDomain && initialValues?.domain) {
      return suffixDomains.some((suffix) =>
        initialValues.domain?.endsWith(suffix)
      )
        ? "subdomain"
        : "custom";
    }
    return initialType;
  });

  const [isGenerating, setIsGenerating] = useState(false);
  const [isCheckingDomain, setIsCheckingDomain] = useState(false);
  const { profile } = useProfile();
  const isAdmin = profile?.role === Roles.ADMIN;

  const userId = isAdmin ? "" : profile?.id || ""; // Mean admin need to select user to assign domain otherwise use current user

  const defaultValues: CustomizeDomainForm = useMemo(() => {
    const values = {
      domain: "",
      userId,
      domainName: "",
      selectedSuffix: defaultSuffixDomain,
      ...initialValues,
    };

    // If it's a subdomain, remove the suffix from the initial domain
    if (type === "subdomain" && values.domain) {
      for (const suffix of suffixDomains) {
        if (values.domain.endsWith(suffix)) {
          values.domain = values.domain.slice(0, -suffix.length);
          values.selectedSuffix = suffix;
          break;
        }
      }
    }

    return values;
  }, [initialValues, userId, type, suffixDomains, defaultSuffixDomain]);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm<CustomizeDomainForm>({
    resolver: zodResolver(createDomainSchema(type)),
    mode: "onChange",
    defaultValues,
  });

  const selectedSuffix = watch("selectedSuffix");

  const checkDomainUniqueness = async (domain: string) => {
    try {
      setIsCheckingDomain(true);

      const fullDomain =
        type === "subdomain" ? `${domain}${selectedSuffix}` : domain;
      const response =
        await SettingsRepository.checkAvailableDomain(fullDomain);
      if (response.data) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error("Error checking domain availability:", error);
      return false;
    } finally {
      setIsCheckingDomain(false);
    }
  };

  const handleGenerateDomain = async () => {
    setIsGenerating(true);
    try {
      const response = await SettingsRepository.generateUniqeDomain(
        projectId || ""
      );

      const randomText = Math.random().toString(36).substring(7);
      const subDomain = response?.data
        ? `${randomText}-${response.data}`
        : randomText;

      if (subDomain) {
        setValue("domain", subDomain, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
        setValue("domainName", subDomain, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    } finally {
      setIsGenerating(false);
    }
  };

  const debouncedCheckDomain = useCallback(
    debounce(async (input: string) => {
      if (!input) return;

      const isUnique = await checkDomainUniqueness(input);
      if (!isUnique) {
        setError("domain", {
          type: "manual",
          message: "This domain is already taken",
        });
      }
    }, 500),
    [type, selectedSuffix]
  );

  const handleFormSubmit = async (values: CustomizeDomainForm) => {
    // double check domain uniqueness before submitting
    const isUnique = await checkDomainUniqueness(values.domain);
    if (!isUnique) {
      setError("domain", {
        type: "manual",
        message: "This domain is already taken",
      });
      return;
    }

    // For subdomain, append the suffix before submitting
    if (type === "subdomain") {
      onSubmit({
        ...values,
        domain: `${values.domain}${values.selectedSuffix}`,
      });
    } else {
      onSubmit(values);
    }
  };

  const showUserSelecter = isAdmin && !updateDomain;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="m-auto px-7 pt-6 pb-8">
        <div className="mb-7 flex items-center justify-between">
          <h2 className="text-xl font-semibold">
            {type === "custom" ? "Custom Domain" : "Subdomain"} Configuration
          </h2>
        </div>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="flex flex-col gap-4"
        >
          {updateDomain && (
            <div className="">
              <RadioGroup
                value={type}
                setValue={(e) => setType(e as "custom" | "subdomain")}
                className="grid grid-cols-1 sm:grid-cols-2 max-w-2xl mx-auto gap-4"
              >
                <Button
                  size="sm"
                  onClick={() => {
                    toast("Coming soon");
                    return;
                    setType("custom");
                  }}
                  variant={type === "custom" ? "solid" : "outline"}
                >
                  <BiPlanet className="size-4 mr-2" />
                  Custom Domain
                </Button>
                <Button
                  size="sm"
                  onClick={() => setType("subdomain")}
                  variant={type === "subdomain" ? "solid" : "outline"}
                >
                  <BiRocket className="size-4 mr-2" />
                  Subdomain
                </Button>
              </RadioGroup>
            </div>
          )}

          {showUserSelecter && (
            // TODO: optimize later
            <div className="mb-[30px]">
              <Controller
                control={control}
                name="userId"
                render={({ field, formState }) => (
                  <UserSelect
                    label="User"
                    size="md"
                    placeholder="Select User"
                    className="col-span-full h-10"
                    dropdownClassName="z-[999]"
                    {...field}
                    {...formState}
                    error={errors.userId?.message}
                    projectId={projectId}
                  />
                )}
              />
            </div>
          )}

          <div className="flex gap-2">
            <div className="flex-1">
              <Controller
                name="domain"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className="flex-1 relative">
                    <Input
                      label={
                        <div className="flex items-center gap-2">
                          Domain
                          {disabledChangeDomain &&
                            domainLabelQuestionTooltip && (
                              <Tooltip content={domainLabelQuestionTooltip}>
                                <button
                                  type="button"
                                  className="flex size-5 items-center justify-center "
                                >
                                  <BsQuestionCircle className="size-4" />
                                </button>
                              </Tooltip>
                            )}
                        </div>
                      }
                      placeholder={
                        type === "subdomain"
                          ? "Enter subdomain"
                          : "Enter your domain"
                      }
                      disabled={disabledChangeDomain}
                      onChange={async (e) => {
                        const input = e.target.value;
                        // Remove the suffix if user pastes full domain
                        if (
                          type === "subdomain" &&
                          suffixDomains.some((suffix) => input.endsWith(suffix))
                        ) {
                          const suffix = suffixDomains.find((s) =>
                            input.endsWith(s)
                          );
                          if (suffix) {
                            onChange(input.slice(0, -suffix.length));
                            setValue("selectedSuffix", suffix);
                            setValue(
                              "domainName",
                              input.slice(0, -suffix.length)
                            );
                          }
                        } else {
                          onChange(input);
                          setValue("domainName", input);
                        }

                        // Debounced domain uniqueness check
                        debouncedCheckDomain(input);
                      }}
                      value={value}
                      suffix={
                        type === "subdomain" ? (
                          <Controller
                            name="selectedSuffix"
                            control={control}
                            render={({ field }) => (
                              <Select
                                options={suffixDomains.map((suffix) => ({
                                  label: suffix,
                                  value: suffix,
                                }))}
                                {...field}
                                value={field.value}
                                onChange={(option: SelectOption) => {
                                  field.onChange(option.value);
                                  // Re-check domain uniqueness with new suffix
                                  debouncedCheckDomain(watch("domain"));
                                }}
                                className={cn(
                                  "min-w-max",

                                  "pointer-events-none" // for now
                                )}
                                selectClassName="hover:!border-0 hover:!outline-0 p-0 h-fit"
                                dropdownClassName="min-w-max"
                                variant="text"
                                size="sm"
                                suffix=""
                                // disabled={disabledChangeDomain}
                                displayValue={(value: string) => {
                                  return (
                                    <Badge rounded="md" size="sm">
                                      {value}
                                    </Badge>
                                  );
                                }}
                              />
                            )}
                          />
                        ) : undefined
                      }
                      error={errors.domain?.message}
                    />
                  </div>
                )}
              />
            </div>
            {type === "subdomain" && (
              <Tooltip content="Generate unique subdomain">
                <Button
                  type="button"
                  variant="outline"
                  onClick={handleGenerateDomain}
                  isLoading={isGenerating}
                  className="mt-6"
                  disabled={
                    isGenerating || isCheckingDomain || disabledChangeDomain
                  }
                >
                  <MdAutoMode />
                </Button>
              </Tooltip>
            )}
          </div>

          <div className="flex justify-end gap-3">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={
                isGenerating ||
                isCheckingDomain ||
                (updateDomain && disabledChangeDomain)
              }
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
