import ScriptsForm from "@/components/forms/ScriptsForm";
import { DEFAULT_HEAD_SCRIPTS } from "@/config/scripts";

import { ScriptsFormSchemaInput } from "@/utilities/validators/scripts-form";
import cn from "@/utils/class-names";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { BsQuestionCircle } from "react-icons/bs";
import { Button, Modal, Switch, Title, Tooltip } from "rizzui";

export interface ScriptsFormModalProps {
  initialValues?: ScriptsFormSchemaInput;
  onSave?: (data: ScriptsFormSchemaInput) => void;
  onClose?: () => void;
  open?: boolean;
  useProjectSgTrackingId?: boolean;
}

export default function ScriptsFormModal({
  initialValues,
  onSave,
  onClose,
  open = false,
  useProjectSgTrackingId,
}: ScriptsFormModalProps) {
  // NOTE: If the project has a sg tracking id, then the default value for the useEnquiryScript is true
  const form = useForm<ScriptsFormSchemaInput>({
    defaultValues: {
      headerScript: useProjectSgTrackingId
        ? DEFAULT_HEAD_SCRIPTS
        : initialValues?.headerScript || "",
      bodyScript: initialValues?.bodyScript || "",
      useEnquiryScript: useProjectSgTrackingId
        ? true
        : !!initialValues?.useEnquiryScript,
    },
  });

  const onSubmit = (data: ScriptsFormSchemaInput) => {
    onSave?.(data);
  };

  return (
    <Modal size="lg" isOpen={open} onClose={onClose || (() => {})}>
      <div className="flex h-full flex-col gap-4 p-6">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2 items-center">
            <Title className="mb-2" as="h3">
              Page Scripts
            </Title>
            {useProjectSgTrackingId && (
              <Tooltip
                content={
                  <div className="flex items-start flex-col gap-1 max-w-[320px]">
                    <p className="text-sm text-left mb-1 font-bold">
                      Why you cannot setup scripts?
                    </p>
                    <p className="text-xs text-left">
                      Admin has been using ProjectSG Tracking ID for this
                      landing page.
                    </p>
                    <p className="text-xs text-left">
                      Please contact Admin to support.
                    </p>
                  </div>
                }
              >
                <button
                  type="button"
                  className="flex size-5 items-center justify-center "
                >
                  <BsQuestionCircle className="size-4" />
                </button>
              </Tooltip>
            )}
          </div>
          <p className="text-sm text-gray-500">
            Setup the scripts for the landing page
          </p>
        </div>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Controller
              control={form.control}
              name="useEnquiryScript"
              render={({ field }) => (
                <Switch
                  label="Default Thank You page"
                  checked={!!field.value}
                  onChange={(value) => field.onChange(value)}
                  labelPlacement="left"
                  className={cn(
                    "flex justify-end",
                    useProjectSgTrackingId &&
                      "cursor-not-allowed opacity-50 pointer-events-none"
                  )}
                  readOnly={useProjectSgTrackingId}
                  // disabled={useProjectSgTrackingId}
                />
              )}
            />
            <ScriptsForm
              visible={{
                headerScript: true,
                bodyScript: true,
              }}
              props={{
                headerScript: {
                  disabled: useProjectSgTrackingId,
                },
                bodyScript: {
                  disabled: useProjectSgTrackingId,
                },
              }}
            />
            <div className="flex flex-row gap-2 justify-end mt-4">
              {onClose && (
                <Button type="button" variant="outline" onClick={onClose}>
                  Cancel
                </Button>
              )}
              <Button disabled={useProjectSgTrackingId} type="submit">
                Save
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
}
