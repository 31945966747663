export enum LDP_OPTIONS {
  O1 = "O1",
  O2 = "O2",
  O3 = "O3",
}

export type ILandingPageOption = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  name: LDP_OPTIONS;
  option: string;
};

export type TLandingPge = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  projectId: string;
  userId: string;
  domain: string;
  name: string;
  layout: null;
  type: null;
  layoutOption: null;
};

export interface SelectLandingPageOptionDTO {
  landingPageId: string;
  layoutOptionId: string;
  active?: boolean;
}
