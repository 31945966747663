import { Enum } from "./Enum";

export const ROUNDROBIN = Object.freeze({
  ADĐ_TIME_SLOT: `/round-robin/time-slot`,
  GET_TIME_SLOT: `/round-robin/time-slot`,
  AGENT_STATUS: (agencyId: string) => `/round-robin/status/${agencyId}`,
  UPDATE_AGENTS: (timeSlotId: string) =>
    `/round-robin/time-slot/${timeSlotId}/update-agents`,
  DISTRIBUTED_HISTORY: (agencyId: string) => `/round-robin/history/${agencyId}`,
  REMOVE: (id: string) => `/round-robin/time-slot/${id}`,
  GET_TIME_SLOT_CONFIG: (id: string) => `/round-robin/config/${id}`,
  UPDATE_TIME_SLOT_CONFIG: (id: string) => `/round-robin/config/${id}`,
});

export type E_ROUNDROBIN = Enum<typeof ROUNDROBIN>;
