import { useEffect, useState } from "react";
import {
  Modal,
  Button,
  ActionIcon,
  Input,
  Tooltip,
  Select,
  SelectOption,
} from "rizzui";
import PencilIcon from "@/components/icons/pencil.tsx";
import useApi from "@/hooks/useApi.tsx";
import { IResponseData } from "@/utilities/types/requests.ts";
import { useForm } from "react-hook-form";
import { IUnitTypeInAll } from "@/utilities/types/UnitType.ts";
import UnitTypeRepository from "@/utilities/repositories/UnitType.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import SingleImageUpload from "@/components/ui/file-upload/single-image-upload.tsx";
import UploadRepository from "@/utilities/repositories/Upload.ts";
import { TImageAsset } from "@/utilities/types/Asset.ts";
import { routes } from "@/config/routes.ts";
import { Link } from "react-router-dom";
import {
  VirtualProjectDTO,
  VirtualProjectItem,
} from "@/utilities/types/VirtualProject.ts";
import { editVirtualProjectSchema } from "@/utilities/validators/virtual-projects.ts";
import VirtualProjectRepository from "@/utilities/repositories/VirtualProjects.ts";
import { Photo } from "@/utilities/types/Floor.ts";
import { backPrev } from "@/utilities/functions";
import { PiPlusBold } from "react-icons/pi";

export interface ModalEditProps {
  reset?: () => void;
  row?: VirtualProjectItem;
  projectId: string;
}

export default function ModalCreateEditFloor({
  row,
  projectId,
  reset,
}: ModalEditProps) {
  const form = useForm({
    defaultValues: {
      projectId,
      name: row?.name?.en || "",
      unitTypeId: row?.unitTypeId || "",
      thumbnailId: row?.thumbnailId || "",
      url: row?.url || "",
    },
    resolver: zodResolver(editVirtualProjectSchema),
  });
  const [modalState, setModalState] = useState(false);
  const [photo, setPhoto] = useState<Photo>({
    id: row?.thumbnailId || "",
    url: "",
  });

  const [displayOption, setDisplayOption] = useState<string | number>(
    form.getValues("unitTypeId")
  );
  const isUpdate: boolean = !!row?.id;
  const title = row?.id ? "Update" : "Create";

  const { loading: loadingUpdate, request: updateVirtualProject } = useApi({
    request: VirtualProjectRepository.updateVirtualProject,
  });

  const { request: getUploadById, response: photoUpload } = useApi<
    IResponseData<TImageAsset>
  >({
    request: UploadRepository.getUploadById,
  });

  const { request: createVirtualProject } = useApi({
    request: VirtualProjectRepository.createVirtualProject,
  });

  const { request: getUnitTypeList, response: unitTypesData } = useApi<
    IResponseData<IUnitTypeInAll[]>
  >({
    request: UnitTypeRepository.getAllUnitMain,
  });
  useEffect(() => {
    if (modalState) {
      Promise.all([getUnitTypeList(), getUploadById(photo.id)]).then(() => {
        if (photoUpload?.data.urls[0]?.url) {
          setPhoto({
            ...photo,
            url: photoUpload?.data?.urls[0]?.url,
          });
        }
      });
    }
    form.reset({
      name: typeof row?.name === "string" ? row?.name : row?.name?.en,
      unitTypeId: row?.unitTypeId || "",
      thumbnailId: row?.thumbnailId || "",
      url: row?.url || "",
    });
  }, [modalState]);

  const unitTypeOptions: SelectOption[] =
    unitTypesData?.data.map((unitTypeItem: IUnitTypeInAll) => ({
      value: unitTypeItem.id,
      label: unitTypeItem.title,
    })) || ([] as SelectOption[]);

  const onSubmit = async (formData: VirtualProjectDTO) => {
    const dataToCreateOrUpdate = {
      ...formData,
      projectId: isUpdate ? undefined : projectId,
      thumbnailId: photo?.id || null,
    };
    try {
      if (isUpdate) {
        await updateVirtualProject(row?.id, dataToCreateOrUpdate);
      } else {
        await createVirtualProject(dataToCreateOrUpdate);
      }
      reset?.();
      toast.success("Success");
      setModalState(false);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };
  return (
    <>
      {isUpdate ? (
        <Tooltip
          size="sm"
          content={"Edit Floorplan"}
          placement="top"
          color="invert"
        >
          <ActionIcon
            size="sm"
            variant="outline"
            onClick={() => setModalState(true)}
          >
            <PencilIcon className="h-4 w-4" />
          </ActionIcon>
        </Tooltip>
      ) : (
        <Button
          onClick={() => {
            setModalState(true);
          }}
          className="w-fit mr-0 flex justify-center items-center whitespace-nowrap"
        >
          <PiPlusBold className="me-1.5 h-[17px] w-[17px]" />
          Add New
        </Button>
      )}

      <Modal
        containerClassName="p-6"
        size={"lg"}
        isOpen={modalState}
        onClose={() => setModalState(false)}
      >
        <form
          className={"gap-4 grid grid-cols-2"}
          noValidate
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <Input
            label="Name"
            placeholder="Name of the Floorplan"
            // value={row?.name?.en || ''}
            {...form.register("name")}
            error={form?.formState?.errors.name?.message}
          />
          <Input
            label="Url"
            placeholder="your URL"
            {...form.register("url")}
            error={form?.formState?.errors.url?.message}
          />
          <Select
            className={"col-span-2"}
            dropdownClassName="z-[99999]"
            options={unitTypeOptions}
            value={displayOption}
            onChange={(value: string) => {
              if (isUpdate) {
                form.setValue("unitTypeId", value, { shouldDirty: true });
              } else {
                form.setValue("unitTypeId", value);
              }
              setDisplayOption(value);
            }}
            label="Unit Type"
            inPortal={false}
            name={"unitTypeId"}
            error={form?.formState?.errors.unitTypeId?.message}
            getOptionValue={(option) => option.value}
            displayValue={(selected: string) => {
              const selectedOption = unitTypeOptions?.find(
                (option) => option.value === selected
              );
              return selectedOption?.label;
            }}
          />
          <SingleImageUpload
            imageUrl={photo.url || ""}
            title="Photo"
            setValue={setPhoto}
            uploaderText="Upload photo"
            uploadContainerClass="w-full h-full md:w-1/3"
          />

          <div className="col-span-full flex items-center justify-end gap-4">
            <Button
              variant="outline"
              onClick={() => {
                setModalState(false);
              }}
              className="md:w-auto w-1/2"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={loadingUpdate}
              className="md:w-auto w-1/2"
              disabled={!form?.formState?.isDirty}
            >
              {title}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
