export enum EFeatureType {
  IpAccessTrackerTelegram = "ip-access-tracker-with-telegram-alerts",
  DynamicLayout = "dynamic-layout",
  LandingPage = "project-landing-page",
  MultipleContact = "multiple-contact",
  TeamAccount = "team-account",
  Directory = "directory",
}

export enum EFeatureMode {
  Advance = "advance",
  Normal = "normal",
}

export interface IFeature {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  name: string;
  enabled: boolean;
  dynamicLayoutMode?: EFeatureMode;
  type: EFeatureType;
}

export interface IUserFeature {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  userId: string;
  featureId: string;
  enabled: boolean;
  dynamicLayoutMode?: EFeatureMode;
  expiredAt: string | null;
  activationKey?: string | null;
  feature: IFeature;
  chatId?: string;
  useProjectSgTrackingId?: boolean;
}

export interface UserFeatureDTO {
  featureId: string;
  userId: string;
  enabled: boolean;
  dynamicLayoutMode?: EFeatureMode;
  chatId?: string;
}

export interface DynamicLayoutModeDTO {
  dynamicLayoutMode?: EFeatureMode;
}
