import { Enum } from "./Enum";

export const LANDINGPAGE_OPTIONS = Object.freeze({
  GET: `/landing-page-layout-option`,
  CREATE: "/landing-page-layout-option",
  UPDATE: (id: string) => `/landing-page-layout-option/${id}`,
  REMOVE: (id: string) => `/landing-page-layout-option/${id}`,
  ATTACH: `/landing-page-layout-option/attach-landing-page-option`, // use to attach option for each landing page
  CHANGE_ACTIVE: "/landing-page-layout-option/change-landing-page-option",
});

export type E_LANDINGPAGE_OPTIONS = Enum<typeof LANDINGPAGE_OPTIONS>;
