import { Title, Text, ActionIcon, Button, Popover } from "rizzui";
import TrashIcon from "@/components/icons/trash";
import { PiTrashFill } from "react-icons/pi";
import cn from "@/utils/class-names";

type DeletePopoverProps = {
  title: string;
  description: string;
  disabled?: boolean;
  iconButtonClass?: string;
  isDeleting?: boolean;
  icon?: React.ReactNode;
  iconClass?: string;
  onDelete: () => void;
  children?: React.ReactElement;
};

export default function DeletePopover({
  title,
  description,
  onDelete,
  disabled,
  iconButtonClass = "",
  isDeleting,
  icon,
  iconClass,
  children,
}: DeletePopoverProps) {
  return (
    <Popover placement="left">
      <Popover.Trigger>
        {children ? (
          children
        ) : (
          <ActionIcon
            size="sm"
            variant="outline"
            aria-label={"Delete Item"}
            disabled={disabled}
            className={cn(
              "cursor-pointer hover:!border-gray-900 hover:text-gray-700",
              disabled && "cursor-not-allowed pointer-events-none",
              iconButtonClass
            )}
          >
            {icon ? icon : <TrashIcon className={cn("h-4 w-4", iconClass)} />}
          </ActionIcon>
        )}
      </Popover.Trigger>
      <Popover.Content className="z-50">
        {({ setOpen }) => (
          <div className="w-56 pb-2 pt-1 text-left rtl:text-right">
            <Title
              as="h6"
              className="mb-0.5 flex items-start text-sm text-gray-700 sm:items-center"
            >
              <PiTrashFill className="me-1 h-[17px] w-[17px]" /> {title}
            </Title>
            <Text className="mb-2 leading-relaxed text-gray-500">
              {description}
            </Text>
            <div className="flex items-center justify-end">
              <Button
                size="sm"
                className="me-1.5 h-7"
                isLoading={isDeleting}
                onClick={() => {
                  onDelete();
                  setOpen(false);
                }}
              >
                Yes
              </Button>
              <Button
                size="sm"
                variant="outline"
                className="h-7"
                onClick={() => setOpen(false)}
                isLoading={isDeleting}
              >
                No
              </Button>
            </div>
          </div>
        )}
      </Popover.Content>
    </Popover>
  );
}
