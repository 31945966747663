import { LDP_PREVIEW } from "@/assets/images";
import useApi from "@/hooks/useApi";
import LandingPageOptionRepository from "@/utilities/repositories/LandingPageOptions";
import { LDP_OPTIONS } from "@/utilities/types/LandingPageOption";
import cn from "@/utils/class-names";
import { useEffect, useState } from "react";
import PreviewModal from "./PreviewModal";
import TemplateCard from "./TemplateCard";

export interface Template {
  id: string;
  name: string;
  description: string;
  previewImage: {
    desktop: string;
    mobile: string;
  };
}

const defaultTemplates = [
  {
    id: "1",
    name: "Option 1",
    key: LDP_OPTIONS.O1,
    description: "",
    previewImage: {
      desktop: LDP_PREVIEW.LDP_OPTION_1,
      mobile: LDP_PREVIEW.MB_LDP_OPTION_1,
    },
  },
  {
    id: "2",
    name: "Option 2",
    key: LDP_OPTIONS.O2,
    description: "",
    previewImage: {
      desktop: LDP_PREVIEW.LDP_OPTION_2,
      mobile: LDP_PREVIEW.MB_LDP_OPTION_2,
    },
  },
  {
    id: "3",
    name: "Option 3",
    key: LDP_OPTIONS.O3,
    description: "",
    previewImage: {
      desktop: LDP_PREVIEW.LDP_OPTION_3,
      mobile: LDP_PREVIEW.MB_LDP_OPTION_3,
    },
  },
];

const TemplateSelection = ({
  onSelectTemplate,
  activeOptionId,
}: {
  onSelectTemplate: (template: Template) => void;
  activeOptionId: string;
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null
  );

  const [templates, setTemplates] = useState(defaultTemplates);

  const { request: getOption, loading } = useApi({
    request: LandingPageOptionRepository.getLandingPageOption,
  });

  useEffect(() => {
    (async () => {
      await getOption({
        page: 1,
        limit: 50,
      }).then((res) => {
        const newTemplates = defaultTemplates.map((template) => {
          const templateMatch = res?.data?.items?.find(
            (op) => op.name === template.key
          );
          if (templateMatch) {
            return { ...template, id: templateMatch.id };
          }
          return template;
        });
        setTemplates(newTemplates);
      });
    })();
  }, [getOption]);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [viewMode, setViewMode] = useState<"desktop" | "mobile">("desktop");

  return (
    <div className="p-6 h-full overflow-auto">
      <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ">
        {!loading &&
          templates.map((template) => (
            <button
              key={template?.id}
              onClick={() => {
                onSelectTemplate(template);
              }}
            >
              <TemplateCard
                template={template}
                onPreview={() => {
                  setSelectedTemplate(template);
                  setIsPreviewOpen(true);
                }}
                className={cn(
                  activeOptionId === template?.id && "ring-2 ring-primary"
                )}
              />
            </button>
          ))}
      </div>

      <PreviewModal
        template={selectedTemplate}
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        viewMode={viewMode}
        onViewModeChange={setViewMode}
      />
    </div>
  );
};

export default TemplateSelection;
