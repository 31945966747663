import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import { ROUNDROBIN } from "../enums/RoundRobin";
import {
  ISchedule,
  IScheduleDTO,
  ITimeSlotConfig,
  ITimeSlotConfigDTO,
} from "../types/RoundRobin";

function getTimeSlot({ queryParams }: IRequestArgs) {
  const query = {
    filter: queryParams?.query?.filter,
    ...queryParams?.pagination,
  };

  return request<ListResponseData<ISchedule>>({
    method: ERequestMethods.GET,
    url: ROUNDROBIN.GET_TIME_SLOT,
    params: query,
  });
}

function distributedHistory(
  queryParams: IRequestArgs
): Promise<ListResponseData<ISchedule>> {
  console.log("query", queryParams.queryParams?.query?.filter[0].id);
  if (!queryParams.queryParams?.query?.filter[0].id) {
    throw new Error("An 'id' parameter is required.");
  }

  // Construct the query object, ensuring nullish values are handled gracefully
  const query = {
    ...queryParams?.queryParams?.pagination,
  };

  return request<ListResponseData<ISchedule>>({
    method: ERequestMethods.GET,
    url: ROUNDROBIN.DISTRIBUTED_HISTORY(
      queryParams.queryParams?.query?.filter[0].id
    ),
    params: query,
  });
}

function addTimeSlot(data: IScheduleDTO) {
  return request<IResponseData<IScheduleDTO>>({
    method: ERequestMethods.POST,
    url: ROUNDROBIN.ADĐ_TIME_SLOT,
    data,
  });
}

function updateRoundRobinStatus(id: string, data: Partial<any>) {
  return request<IResponseData<IScheduleDTO>>({
    method: ERequestMethods.PATCH,
    url: ROUNDROBIN.AGENT_STATUS(id),
    data,
  });
}

function updateAgentsInTimeSlot(id: string, data: Partial<string[]>) {
  return request<IResponseData<IScheduleDTO>>({
    method: ERequestMethods.PATCH,
    url: ROUNDROBIN.UPDATE_AGENTS(id),
    data,
  });
}

function removeTimeSlot(id: string) {
  return request<IResponseData<IScheduleDTO>>({
    method: ERequestMethods.DELETE,
    url: ROUNDROBIN.REMOVE(id),
  });
}

function getTimeSlotConfig(id: string) {
  return request<IResponseData<ITimeSlotConfig>>({
    method: ERequestMethods.GET,
    url: ROUNDROBIN.GET_TIME_SLOT_CONFIG(id),
  });
}

function updateTimeSlotConfig({
  id,
  data,
}: {
  id: string;
  data: Partial<ITimeSlotConfigDTO>;
}) {
  return request<IResponseData<ITimeSlotConfig>>({
    method: ERequestMethods.PUT,
    url: ROUNDROBIN.UPDATE_TIME_SLOT_CONFIG(id),
    data,
  });
}

const RoundRobinRepository = Object.freeze({
  getTimeSlot,
  addTimeSlot,
  distributedHistory,
  updateAgentsInTimeSlot,
  updateRoundRobinStatus,
  removeTimeSlot,
  getTimeSlotConfig,
  updateTimeSlotConfig,
});

export default RoundRobinRepository;
