import { useDrawer } from "@/app/shared/drawer-views/use-drawer";
import { COOKIES_KEYS, STORAGE_KEYS } from "@/config/auth";
import { SUFFIX_DOMAIN } from "@/config/constants";
import { routes } from "@/config/routes";
import { EStatusCode } from "@/utilities/enums/StatusCodes";
import { findAccessiblePath } from "@/utilities/functions";

import AuthRepository from "@/utilities/repositories/Auths";
import { SignInResponse, VerifyOtpDTO } from "@/utilities/types/Auths";
import { IUserData } from "@/utilities/types/Users";
import {
  AgencyLoginSchema,
  LoginSchema,
} from "@/utils/validators/login.schema";
import Cookies from "js-cookie";
import { useState } from "react";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { SubmitHandler } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";

const TIME_TOKEN_EXPIRED = 301; // seconds

export const useAuthHelper = () => {
  const navigate = useNavigate();
  const signIn = useSignIn<IUserData>();
  const signOut = useSignOut();
  const [isLoading, setIsLoading] = useState(false);
  const { closeDrawer } = useDrawer();
  const cookies = Cookies.get();

  const accessToken = cookies && cookies[COOKIES_KEYS.ACCESS_TOKEN];
  const [_, setOtpExpired] = useSessionStorage<string | null>(
    STORAGE_KEYS.OTP_EXPIRED,
    null
  );

  const saveAuth = (data: SignInResponse) => {
    signIn({
      auth: {
        token: data.tokens.accessToken,
        type: "Bearer",
      },
      userState: data.user,
    });
  };

  const refreshAuth = (data: SignInResponse) => {
    const defaultPath = findAccessiblePath(data.user.role);

    if (!defaultPath) {
      signOut();
      navigate(routes.auth.signIn, {
        replace: true,
      });
      return;
    }

    signIn({
      auth: {
        token: data.tokens.accessToken,
        type: "Bearer",
      },
      userState: data.user,
    });

    navigate(defaultPath, {
      replace: true,
    });
  };

  const signInWithToken = async (data: SignInResponse) => {
    try {
      if (data?.tokens?.accessToken) {
        refreshAuth(data);
      }
    } catch (err: any) {
      signOut();
      throw err;
    }
  };

  const sendOtp: SubmitHandler<LoginSchema> = async (data) => {
    delete data?.rememberMe;
    const toastID = toast.loading("Sending OTP..");
    try {
      const response = await AuthRepository.signIn(data);
      if (
        // response?.data?.user?.role === Roles.ADMIN &&
        response?.data?.tokens?.accessToken
      ) {
        toast.success("Sign in success", { id: toastID });
        refreshAuth(response.data);
      } else {
        const t = new Date();
        t.setSeconds(t.getSeconds() + TIME_TOKEN_EXPIRED);
        setOtpExpired(t.toString());

        setTimeout(() => {
          toast.success("OTP sent successfully", { id: toastID });

          navigate(routes.auth.verifyOtp, {
            state: {
              data,
            },
          });
        }, 1000);
      }
    } catch (err: any) {
      toast.error(err?.message, { id: toastID });
      signOut();
      throw err;
    }
  };

  const sendOtpByPhone: SubmitHandler<AgencyLoginSchema> = async (data) => {
    const toastID = toast.loading("Sending OTP..");
    try {
      const response = await AuthRepository.sendOTPByPhone(data);
      if (response?.data?.tokens?.accessToken) {
        toast.success("Sign in success", { id: toastID });
        refreshAuth(response.data);
      } else {
        const t = new Date();
        t.setSeconds(t.getSeconds() + TIME_TOKEN_EXPIRED);
        setOtpExpired(t.toString());

        setTimeout(() => {
          toast.success("OTP sent successfully", { id: toastID });

          navigate(routes.auth.verifyOtp, {
            state: {
              data,
            },
          });
        }, 1000);
      }
    } catch (err: any) {
      toast.error(err?.message || "Something went wrong, please try again", {
        id: toastID,
      });

      signOut();

      throw err;
    }
  };

  const verifyOtpLogin = async (data: VerifyOtpDTO) => {
    const toastID = toast.loading("Signing in...");
    setIsLoading(true);
    try {
      const response = await AuthRepository.verifyOTP(data);
      if (response.statusCode === EStatusCode.OK) {
        refreshAuth(response.data);
        toast.success("Sign in success", { id: toastID });
      }
    } catch (error: any) {
      toast.error(error?.message, { id: toastID });
      signOut();
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const clearAuth = async () => {
    try {
      signOut(); // clear react auth-kit
      Cookies.remove(COOKIES_KEYS.USER_FEATS); // clear user feats
      await AuthRepository.signOut();
    } catch (error) {
      console.error("Error during clearAuth:", error);
      // Still try to clean up local state even if API call fails
      signOut();
      Cookies.remove(COOKIES_KEYS.USER_FEATS);
    }
  };

  const logOut = async (navigateTo = routes.auth.signIn) => {
    // We need signout function to save  account activity
    const toastID = toast.loading("Loading...");

    try {
      await AuthRepository.signOut();
      closeDrawer();
      setTimeout(() => {
        signOut(); // clear react auth-kit
        Cookies.remove(COOKIES_KEYS.USER_FEATS); // clear user feats
        toast.success("Logged out successfully", { id: toastID });
        if (navigateTo) {
          navigate({ pathname: navigateTo }, { replace: true });
          window.location.reload();
        }
      }, 500); // Waiting for drawer close
    } catch (err: any) {
      toast.error(err?.message || "Log out failed, pleas try again", {
        id: toastID,
      });
    }
  };

  const shareToken = async () => {
    if (!accessToken) {
      // TODO: Need to check token expired
      toast.error("Unauthorization, please login again!");
      return;
    }

    Cookies.set(COOKIES_KEYS.SHARED_TOKEN, accessToken, {
      domain: SUFFIX_DOMAIN,
    });
  };

  return {
    refreshAuth,
    sendOtp,
    isLoading,
    verifyOtpLogin,
    logOut,
    shareToken,
    sendOtpByPhone,
    signInWithToken,
    saveAuth,
    clearAuth,
  };
};
