import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { IoArrowBack } from "react-icons/io5";
import { Button, Input, Text, Title } from "rizzui";
import { E_PRICING_PLANS } from "./pricing-plans";

interface CheckoutFormData {
  cardNumber: string;
  cardHolder: string;
  expiryDate: string;
  cvv: string;
  promoCode: string;
}

interface CheckoutProps {
  planName: E_PRICING_PLANS;
  price: number;
  monthlyPrice: number;
  onBack: () => void;
  onComplete?: () => void;
}

const DEMO_PROMO_CODE: any[] = [
  // {
  //   code: "NAVISVIP",
  //   discount: 100,
  //   applySuccess: "NAVIS subsidy applied successfully!",
  //   codeType: E_PRICING_PLANS["1_MONTH"],
  // },
];

const formatCardNumber = (value: string) => {
  const v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
  const matches = v.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || "";
  const parts = [];

  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(" ");
  } else {
    return value;
  }
};

const formatExpiryDate = (value: string) => {
  const v = value.replace(/\D/g, "");
  if (v.length >= 2) {
    return v.slice(0, 2) + (v.length > 2 ? "/" + v.slice(2, 4) : "");
  }
  return v;
};

export default function Checkout({
  planName,
  price,
  monthlyPrice,
  onBack,
  onComplete,
}: CheckoutProps) {
  const [isPromoValid, setIsPromoValid] = React.useState(false);
  const [isCheckingPromo, setIsCheckingPromo] = React.useState(false);
  const [appliedPromoCode, setAppliedPromoCode] = React.useState<any>(null);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CheckoutFormData>();

  const promoCode = watch("promoCode");

  const handlePaymentComplete = () => {
    onComplete?.();
  };

  const onSubmit = async (data: CheckoutFormData) => {
    toast.success("Payment processed successfully!");
    console.log("Form data:", data);
    handlePaymentComplete();
  };

  const validatePromoCode = async () => {
    if (!promoCode) {
      toast.error("Please enter a promo code");
      return;
    }

    setIsCheckingPromo(true);
    // Simulate API call
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const isMatch = DEMO_PROMO_CODE.find(
      (item) =>
        item.code.toLowerCase() === promoCode.toLowerCase() &&
        item.codeType === planName
    );

    if (isMatch) {
      toast.success(isMatch.applySuccess);
      setIsPromoValid(true);
      setAppliedPromoCode(isMatch);
    } else {
      toast.error("Invalid promo code");
      setIsPromoValid(false);
      setAppliedPromoCode(null);
    }
    setIsCheckingPromo(false);
  };

  const removePromoCode = () => {
    setIsPromoValid(false);
    setValue("promoCode", "");
    toast.success("Promo code removed");
    setAppliedPromoCode(null);
  };

  const handleNext = () => {
    handlePaymentComplete();
  };

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatCardNumber(e.target.value);
    e.target.value = formatted;
  };

  const handleExpiryDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatExpiryDate(e.target.value);
    if (formatted.length <= 5) {
      e.target.value = formatted;
    }
  };

  return (
    <div className="mx-auto max-w-3xl px-4 py-8">
      <Button
        variant="outline"
        className="mb-6 inline-flex items-center gap-2"
        onClick={onBack}
      >
        <IoArrowBack className="text-lg" />
        Back to Plans
      </Button>

      <div className="mb-8 rounded-lg bg-white p-6 shadow-[0_4px_20px_rgba(0,0,0,0.1)]">
        <Title className="mb-6 text-2xl font-bold">Order Summary</Title>
        <div className="flex justify-between border-b pb-4">
          <div>
            <Text className="text-lg font-semibold">{planName}</Text>
            <Text className="text-gray-600">${monthlyPrice}/month</Text>
          </div>
          <div className="text-right">
            <Text
              className={`text-2xl font-bold ${isPromoValid ? "line-through text-gray-400" : ""}`}
            >
              ${price}
            </Text>
            {isPromoValid && (
              <Text className="text-2xl font-bold text-green-600">$0</Text>
            )}
          </div>
        </div>

        <div className="mt-6">
          <div className="mb-4 flex items-center gap-2">
            <Input
              type="text"
              placeholder="Enter promo code"
              {...register("promoCode")}
              className="max-w-xs"
              disabled={isPromoValid}
            />
            {!isPromoValid ? (
              <Button
                variant="solid"
                onClick={validatePromoCode}
                isLoading={isCheckingPromo}
                disabled={isCheckingPromo}
              >
                Apply
              </Button>
            ) : (
              <Button
                variant="outline"
                onClick={removePromoCode}
                className="text-red-500 hover:bg-red-50"
              >
                Remove
              </Button>
            )}
          </div>
          {appliedPromoCode && (
            <Text className="text-sm text-green-600">
              {appliedPromoCode?.applySuccess}
            </Text>
          )}
        </div>

        <Button
          variant="solid"
          size="lg"
          className="mt-6 w-full"
          onClick={handleNext}
          disabled={!isPromoValid}
        >
          Next
        </Button>
      </div>

      {/* {!isPromoValid && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="rounded-lg bg-white p-6 shadow-[0_4px_20px_rgba(0,0,0,0.1)]"
        >
          <Title className="mb-6 text-2xl font-bold">Payment Details</Title>

          <div className="space-y-4">
            <div>
              <Input
                label="Card Number"
                placeholder="1234 5678 9012 3456"
                maxLength={19}
                {...register("cardNumber", {
                  required: "Card number is required",
                  pattern: {
                    value: /^(\d{4}\s?){4}$/,
                    message: "Please enter a valid 16-digit card number",
                  },
                  onChange: handleCardNumberChange,
                })}
              />
              {errors.cardNumber && (
                <Text className="mt-1 text-sm text-red-500">
                  {errors.cardNumber.message}
                </Text>
              )}
            </div>

            <div>
              <Input
                label="Card Holder Name"
                placeholder="John Doe"
                maxLength={50}
                {...register("cardHolder", {
                  required: "Card holder name is required",
                })}
              />
              {errors.cardHolder && (
                <Text className="mt-1 text-sm text-red-500">
                  {errors.cardHolder.message}
                </Text>
              )}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Expiry Date"
                  placeholder="MM/YY"
                  maxLength={5}
                  {...register("expiryDate", {
                    required: "Expiry date is required",
                    pattern: {
                      value: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
                      message: "Please enter a valid date (MM/YY)",
                    },
                    onChange: handleExpiryDateChange,
                  })}
                />
                {errors.expiryDate && (
                  <Text className="mt-1 text-sm text-red-500">
                    {errors.expiryDate.message}
                  </Text>
                )}
              </div>

              <div>
                <Input
                  label="CVV"
                  type="text"
                  placeholder="123"
                  maxLength={3}
                  {...register("cvv", {
                    required: "CVV is required",
                    pattern: {
                      value: /^[0-9]{3}$/,
                      message: "Please enter a valid 3-digit CVV",
                    },
                  })}
                />
                {errors.cvv && (
                  <Text className="mt-1 text-sm text-red-500">
                    {errors.cvv.message}
                  </Text>
                )}
              </div>
            </div>

            <Button
              type="submit"
              className="mt-6 w-full"
              variant="solid"
              size="lg"
            >
              Pay ${price}
            </Button>
          </div>
        </form>
      )} */}
    </div>
  );
}
