import { IMortgage } from "@/utilities/types/Mortgage.ts";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import useApi from "@/hooks/useApi.tsx";
import { ActionIcon, Button, Input, Select, Title } from "rizzui";
import toast from "react-hot-toast";
import SingleImageUpload from "@/components/ui/file-upload/single-image-upload.tsx";
import {
  MortgageSchemaInput,
  createMortgageSchema,
} from "@/utilities/validators/mortgage.ts";
import MortgageRepository from "@/utilities/repositories/Mortgage.ts";
import { DatePicker } from "@/components/ui/datepicker.tsx";
import { useModal } from "@/app/shared/modal-views/use-modal";
import dayjs from "dayjs";
import { PiXBold } from "react-icons/pi";

export interface CreateEditMortgageProps {
  refetchData?: () => void;
  row?: IMortgage;
}

const optionMortgageTypes = [
  { label: "HDB", value: "HDB" },
  { label: "Condo", value: "Condo" },
  { label: "BUC", value: "BUC" },
];

export default function CreateEditMortgage({
  row,
  refetchData,
}: CreateEditMortgageProps) {
  const form = useForm({
    defaultValues: {
      bankName: row?.bankName || "",
      mortgageType: row?.type || "",
      firstYearRate: row?.firstYear?.value || 0,
      firstYearValue: row?.firstYear?.text || "",
      secondYearRate: row?.secondYear?.value || 0,
      secondYearValue: row?.secondYear?.text || "",
      threeYearRate: row?.threeYear?.value || 0,
      threeYearValue: row?.threeYear?.text || "",
      thereAfterRate: row?.thereAfter?.value || 0,
      thereAfterValue: row?.thereAfter?.text || "",
      bankLogoId: row?.bankLogoId || "",
      applyDate:
        row?.applyDate && dayjs(row?.applyDate).isValid()
          ? row?.applyDate
          : dayjs().toISOString(),
      bankLogo: {
        id: "",
        url: "",
      },
      package: row?.package || "",
      remarks: row?.remarks || "",
    },
    resolver: zodResolver(createMortgageSchema),
  });

  const isUpdate: boolean = !!row?.id;
  const title = row?.id ? "Update" : "Create";

  const { loading: updating, request: updateMortgage } = useApi({
    request: MortgageRepository.updateMortgage,
  });

  const { request: createMortgage, loading: creating } = useApi({
    request: MortgageRepository.createMortgage,
  });

  const { closeModal, isOpen } = useModal();

  useEffect(() => {
    form.reset({
      bankName: row?.bankName || "",
      mortgageType: row?.type || "",
      firstYearRate: row?.firstYear?.value || 0,
      firstYearValue: row?.firstYear?.text || "",
      secondYearRate: row?.secondYear?.value || 0,
      secondYearValue: row?.secondYear?.text || "",
      threeYearRate: row?.threeYear?.value || 0,
      threeYearValue: row?.threeYear?.text || "",
      thereAfterRate: row?.thereAfter?.value || 0,
      thereAfterValue: row?.thereAfter?.text || "",
      bankLogoId: row?.bankLogoId || "",
      applyDate:
        row?.applyDate && dayjs(row?.applyDate).isValid()
          ? row?.applyDate
          : dayjs().toISOString(),
      package: row?.package || "",
      remarks: row?.remarks || "",
      bankLogo: {
        id: row?.bankLogo?.id || "",
        url: row?.bankLogo?.urls?.[0]?.url || "",
      },
    });
  }, [JSON.stringify(row)]);

  const onSubmit = async (formData: MortgageSchemaInput) => {
    const dataToCreateOrUpdate = {
      mortgageType: formData?.mortgageType || "",
      bankName: formData?.bankName || "",
      firstYear: {
        value: formData?.firstYearRate,
        text: formData?.firstYearValue,
      },
      secondYear: {
        value: formData?.firstYearRate,
        text: formData?.firstYearValue,
      },
      threeYear: {
        value: formData?.firstYearRate,
        text: formData?.firstYearValue,
      },
      thereAfter: {
        value: formData?.firstYearRate,
        text: formData?.firstYearValue,
      },
      package: formData?.package || "",
      applyDate: formData?.applyDate || "",
      remarks: formData?.remarks,
      bankLogoId: formData?.bankLogo?.id || null,
      bankLogo: undefined,
    };
    try {
      if (isUpdate) {
        await updateMortgage(row?.id, dataToCreateOrUpdate);
      } else {
        await createMortgage(dataToCreateOrUpdate);
      }
      toast.success("Success");
      refetchData?.();
      if (isOpen) {
        closeModal();
      }
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };
  return (
    <form
      noValidate
      className="grid grid-cols-2 gap-6 p-6 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className="col-span-full flex items-center justify-between">
        <Title as="h4" className="font-semibold">
          {title}
        </Title>
        {isOpen && (
          <ActionIcon size="sm" variant="text" onClick={closeModal}>
            <PiXBold className="h-auto w-5" />
          </ActionIcon>
        )}
      </div>
      <Input
        label="Bank Name"
        placeholder="Name of the bank"
        className="[&>label>span]:font-medium col-span-full lg:col-span-1"
        {...form.register("bankName")}
        error={form?.formState?.errors.bankName?.message}
      />
      <Input
        label="First Year Rate"
        placeholder="0.00"
        min={0.0}
        type={"number"}
        {...form.register("firstYearRate")}
        className="[&>label>span]:font-medium col-span-full lg:col-span-1"
        error={form?.formState?.errors.firstYearRate?.message}
      />
      <Input
        label="First Year Value"
        placeholder="First Year Value"
        min={0.0}
        {...form.register("firstYearValue")}
        className="[&>label>span]:font-medium col-span-full lg:col-span-1"
        error={form?.formState?.errors.firstYearValue?.message}
      />
      <Input
        label="Second Year Rate"
        placeholder="0.00"
        min={0.0}
        type={"number"}
        {...form.register("secondYearRate")}
        className="[&>label>span]:font-medium  col-span-full lg:col-span-1"
        error={form?.formState?.errors.secondYearRate?.message}
      />
      <Input
        label="Second Year Value"
        placeholder="Second Year Value"
        min={0.0}
        {...form.register("secondYearValue")}
        className="[&>label>span]:font-medium col-span-full lg:col-span-1"
        error={form?.formState?.errors.secondYearValue?.message}
      />
      <Input
        label="Three Year Rate"
        placeholder="0.00"
        min={0.0}
        type={"number"}
        {...form.register("threeYearRate")}
        className="[&>label>span]:font-medium  col-span-full lg:col-span-1"
        error={form?.formState?.errors.threeYearRate?.message}
      />
      <Input
        label="Three year value"
        placeholder="Three value"
        {...form.register("threeYearValue")}
        className="[&>label>span]:font-medium  col-span-full lg:col-span-1"
        error={form?.formState?.errors.threeYearValue?.message}
      />
      <Input
        label="There after Rate"
        placeholder="There After Rate"
        min={0.0}
        {...form.register("thereAfterRate")}
        className="[&>label>span]:font-medium col-span-full lg:col-span-1"
        error={form?.formState?.errors.thereAfterRate?.message}
      />
      <Input
        label="Three after value"
        placeholder="Three after value"
        {...form.register("thereAfterValue")}
        className="[&>label>span]:font-medium  col-span-full lg:col-span-1"
        error={form?.formState?.errors.thereAfterValue?.message}
      />
      <Input
        label="Package"
        placeholder="Package"
        {...form.register("package")}
        className="[&>label>span]:font-medium col-span-full lg:col-span-1"
        error={form?.formState?.errors.package?.message}
      />
      <Input
        label="Remarks"
        placeholder="Remarks"
        {...form.register("remarks")}
        className="[&>label>span]:font-medium  col-span-full lg:col-span-1"
        error={form?.formState?.errors.remarks?.message}
      />

      <Controller
        name="applyDate"
        control={form?.control}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            className="[&>label>span]:font-medium  col-span-full lg:col-span-1"
            name={"applyDate"}
            inputProps={{
              label: "Apply Date",
              error: form?.formState?.errors?.applyDate?.message as string,
            }}
            placeholderText="Select Date"
            selected={
              value && dayjs(value).isValid()
                ? dayjs(value).toDate()
                : dayjs().toDate()
            }
            onChange={(date: Date) => {
              onChange(dayjs(date).toISOString());
            }}
          />
        )}
      />
      <Controller
        name="mortgageType"
        control={form?.control}
        render={({ field: { onChange, value } }) => (
          <Select
            className="[&>label>span]:font-medium  col-span-full"
            dropdownClassName="z-[9999] w-full"
            options={optionMortgageTypes}
            value={value}
            onChange={onChange}
            label="Mortgage Type"
            inPortal={false}
            name={"mortgageType"}
            error={form?.formState?.errors.mortgageType?.message}
            getOptionValue={(option) => option.value}
            displayValue={(selected: string) => {
              const selectedOption = optionMortgageTypes?.find(
                (option) => option.value === selected
              );
              return selectedOption?.label;
            }}
          />
        )}
      />
      <Controller
        control={form.control}
        name="bankLogo"
        render={({ field: { onChange } }) => (
          <SingleImageUpload
            imageUrl={form?.watch("bankLogo")?.url || ""}
            title="Bank Logo"
            setValue={onChange}
            error={form?.formState?.errors?.bankLogo?.id?.message as string}
            uploaderText="Upload bank logo"
            uploadContainerClass="w-full col-span-full md:w-1/3"
          />
        )}
      />

      <div className="col-span-full flex items-center justify-end gap-4">
        <Button
          variant="outline"
          onClick={closeModal}
          className="md:w-auto w-1/2"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          isLoading={updating || creating}
          className="md:w-auto w-1/2"
          disabled={!form?.formState?.isDirty}
        >
          {title}
        </Button>
      </div>
    </form>
  );
}
