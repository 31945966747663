"use client";

import { useEffect, useState } from "react";
import { PiXBold } from "react-icons/pi";
import { Controller, SubmitHandler, UseFormReturn } from "react-hook-form";
import { Form } from "@/components/ui/form";
import { Input, Button, ActionIcon, Title } from "rizzui";
import { useModal } from "@/app/shared/modal-views/use-modal";
import { useNavigate } from "react-router-dom";
import UserRepository from "@/utilities/repositories/Users";
import { IUserData, Roles } from "@/utilities/types/Users";
import toast from "react-hot-toast";
import useApi from "@/hooks/useApi";
import { IResponseData } from "@/utilities/types/requests";
import { filterChangedFormFields } from "@/utils/form";
import {
  EditUserSchema,
  editUserSchema,
} from "@/utils/validators/edit-user.schema";
import useProfile from "@/hooks/use-profile";
import SingleImageUpload from "@/components/ui/file-upload/single-image-upload.tsx";
import { backPrev } from "@/utilities/functions";
import SettingsRepository from "@/utilities/repositories/Settings";
import SelectDomain, {
  SelectOptionType,
} from "@/app/app-shared/user/users-table/select-domain.tsx";
import { IDomainDTO, TAgencySetting } from "@/utilities/types/Setting.ts";
import formatPhoneNumber from "@/utilities/functions/format-phone-number";
import { COUNTRY_SG_CODE } from "@/config/constants";
import formatPhone from "@/utilities/functions/format-phone";
import { findFeatureByType } from "@/app/app-shared/advanced-features/ultil";
import { EFeatureType } from "@/utilities/types/AdvanceFeature";
export default function EditUser({
  id,
  title,
  backTo,
}: {
  id: string;
  title: string;
  backTo?: string;
}) {
  const { closeModal, isOpen } = useModal();
  const [reset, setReset] = useState<{
    firstName: string;
    lastName: string;
    password: string;
    email: string;
    phone: string;
    photo: { url: string } | null;
    photoId: string;
    // measuringId?: string | null;
    headerScript?: string;
    bodyScript?: string;
    enquiryScript?: string;
    domain?: string | null;
    adRerportUrl?: string | null;
  }>({
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    phone: "",
    photo: null,
    photoId: "",
    adRerportUrl: "",
    // measuringId: "",
    // headerScript: "",
    // bodyScript: "",
    // enquiryScript: "",
  });

  const [mapOptions, setMapOptions] = useState<SelectOptionType[]>([]);
  const [defaultOptions, setDefaultOptions] = useState({});
  const [configID, setConfigID] = useState<IDomainDTO[]>();
  const [userHasDirectoryPermission, setUserHasDirectoryPermission] =
    useState(false);
  const navigate = useNavigate();

  const refreshPage = () => {
    window.location.reload();
  };

  const { profile, setProfile } = useProfile();

  const isMyProfile = profile?.id === id;

  const [isLoading, setIsLoading] = useState(false);

  const [form, setForm] =
    useState<UseFormReturn<EditUserSchema, any, undefined>>();
  const handleBack = () => {
    if (!backTo) return;
    navigate(backPrev(backTo));
    closeModal?.();
  };

  const { request: findUserDetail, response } = useApi<
    IResponseData<IUserData>
  >({
    request: UserRepository.getUserById,
  });

  const { request: findMeDetails } = useApi<IResponseData<IUserData>>({
    request: UserRepository.getMe,
  });

  const updateUser = async (dataForm: EditUserSchema) => {
    if (!id || !form?.formState.dirtyFields) return;

    const fieldChange = filterChangedFormFields(
      dataForm,
      form?.formState.dirtyFields
    );

    const { domain, ...userData } = fieldChange;

    try {
      setIsLoading(true);
      await UserRepository.updateUser(id, {
        ...userData,
        phone: dataForm.phone
          ? formatPhone(dataForm.phone).trim().split(" ").join("")
          : "",
      });

      if (domain && response?.data?.config?.id) {
        await SettingsRepository.createDomain({
          configId: response?.data?.config?.id,
          name: domain?.toLowerCase() || "",
          primary: true,
        });
      }
      toast.success(`Success`);
      setTimeout(() => {
        setIsLoading(false);

        if (backTo) {
          setReset({
            firstName: "",
            lastName: "",
            password: "",
            email: "",
            phone: "",
            photo: null,
            photoId: "",
            adRerportUrl: "",
          });
        }

        if (isMyProfile && profile) {
          setProfile({
            ...profile,
            ...form.getValues(),
          } as IUserData);
        }

        handleBack();
      }, 600);
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
      setIsLoading(false);
    }
  };

  const updateMe = async (dataForm: EditUserSchema) => {
    try {
      setIsLoading(true);
      const dataSubmit: any = {
        email: dataForm.email,
        firstName: dataForm.firstName,
        lastName: dataForm.lastName,
        phone: dataForm.phone
          ? formatPhone(dataForm.phone).trim().split(" ").join("")
          : "",
      };

      // Only set photoId if dataForm.photo.id exists
      if (dataForm?.photo?.id) {
        dataSubmit.photoId = dataForm.photo.id;
      }

      const response = await UserRepository.updateMe({
        ...dataSubmit,
      });
      toast.success(`Success`);
      setIsLoading(false);

      if (backTo) {
        setReset({
          firstName: "",
          lastName: "",
          password: "",
          email: "",
          phone: "",
          photo: null,
          photoId: "",
          adRerportUrl: "",
        });
      }

      if (isMyProfile && profile) {
        const profileData = {
          ...profile,
          ...response?.data,
        };
        setProfile({
          ...profileData,
        });
      }

      handleBack();
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
      setIsLoading(false);
    }
  };
  const onSubmit: SubmitHandler<EditUserSchema> = (data) => {
    console.log(data);
    if (!isMyProfile) {
      updateUser(data);
    } else if (isMyProfile) {
      updateMe(data);
    }
  };

  const setOptionsSelect = (config: TAgencySetting) => {
    if (config) {
      const primaryDomain = config?.domains?.find((item) => !!item.primary);
      primaryDomain &&
        setDefaultOptions({
          primary: primaryDomain.primary,
          value: primaryDomain.id,
          label: `${primaryDomain.name?.toLowerCase()}`,
          configID: primaryDomain.configId,
        });
      if (config?.domains) {
        setConfigID(config.domains);
        const mapOptionsArr = config.domains.map((item) => {
          return {
            primary: item.primary,
            value: item.id,
            label: item.name?.toLowerCase(),
            configID: item.configId,
          };
        });
        setMapOptions(mapOptionsArr);
      }
    }
  };

  useEffect(() => {
    if (!id) return;
    if (isMyProfile) {
      findMeDetails().then((res) => {
        if (!res?.data) return;
        const { firstName, lastName, phone, email, photo, photoId, config } =
          res.data;
        let photoArgs = {
          width: 0,
          url: "",
        };
        if (photo?.urls?.[0]) {
          photoArgs = photo?.urls?.[0];
        }
        if (config) setOptionsSelect(config);

        // @ts-ignore
        setReset({
          firstName,
          lastName,
          password: "",
          email,
          phone: phone
            ? phone.startsWith(COUNTRY_SG_CODE)
              ? phone.split(COUNTRY_SG_CODE)[1].trim()
              : phone
            : "",
          photo: { url: photoArgs?.url || "" },
          photoId: photoId || "",
          adRerportUrl: profile?.adRerportUrl || "",

          // headerScript: profile?.headerScript ?? "",
          // bodyScript: profile?.bodyScript ?? "",
          // enquiryScript: profile?.enquiryScript ?? "",
        });
      });
    } else {
      findUserDetail(id).then((res) => {
        if (!res?.data) return;
        const {
          firstName,
          lastName,
          phone,
          email,
          adRerportUrl,
          config,
          userFeatures,
        } = res.data;

        const features = userFeatures?.map((item) => item?.feature);
        const userHasDirectoryPermission =
          features &&
          !!findFeatureByType(features, EFeatureType.Directory)?.enabled;

        setUserHasDirectoryPermission(!!userHasDirectoryPermission);
        if (config) setOptionsSelect(config);

        setReset({
          firstName,
          lastName,
          password: "",
          email,
          phone: phone
            ? phone.startsWith(COUNTRY_SG_CODE)
              ? phone.split(COUNTRY_SG_CODE)[1].trim()
              : phone
            : "",
          photo: null,
          photoId: "",
          adRerportUrl: adRerportUrl || "",
        });
      });
    }
  }, []);

  return (
    <Form<EditUserSchema>
      resetValues={reset}
      onSubmit={onSubmit}
      validationSchema={editUserSchema}
      onInitForm={(methods) => {
        setForm(methods);
      }}
      className="grid grid-cols-1 h-full gap-6 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900"
    >
      {({
        register,
        control,
        watch,
        setError,
        formState: { errors, dirtyFields },
      }) => {
        return (
          <div className="col-span-full flex flex-col gap-4">
            <div className="mt-4 flex items-center justify-between">
              <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
                {title}
              </Title>
              {isOpen && (
                <ActionIcon size="sm" variant="text" onClick={closeModal}>
                  <PiXBold className="h-auto w-5" />
                </ActionIcon>
              )}
            </div>
            <div className="flex-1 flex-col gap-3 flex">
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState, formState }) => (
                  <Input
                    label="Email"
                    size="lg"
                    placeholder="Enter user's Email Address"
                    className="col-span-full"
                    {...field}
                    {...formState}
                    value={field.value}
                    error={fieldState.error?.message}
                    disabled
                  />
                )}
              />

              {/* <Controller
                control={control}
                name="phone"
                render={({ field, fieldState, formState }) => (
                  <Input
                    label="Phone Number"
                    size="lg"
                    placeholder="Enter phone number"
                    className="col-span-full"
                    {...field}
                    {...formState}
                    value={field.value}
                    error={fieldState.error?.message}
                  />
                )}
              /> */}

              {/* <Controller
                name={"phone"}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    className="col-span-full"
                    size="lg"
                    label="Phone Number"
                    type="tel"
                    value={formatPhoneNumber(value)}
                    onChange={onChange}
                    // @ts-ignore
                    error={errors?.phone?.message as string}
                  />
                )}
              /> */}

              {/* <Input
                label="Phone number"
                size="lg"
                placeholder=""
                {...register("phone")}
                className="col-span-full"
                error={errors.phone?.message}
              /> */}

              <Controller
                control={control}
                name="phone"
                render={({ field, fieldState, formState }) => (
                  <Input
                    label="Phone number"
                    type="text"
                    prefix="+65"
                    size="lg"
                    placeholder="Enter phone number"
                    className="col-span-full"
                    {...field}
                    {...formState}
                    value={formatPhoneNumber(field.value)}
                    error={fieldState.error?.message}
                  />
                )}
              />

              {/* <Controller
                control={control}
                name="phone"
                render={({ field, fieldState, formState }) => (
                  <Input
                    type="text"
                    label="Phone Number"
                    {...field}
                    {...formState}
                    value={field.value}
                    error={fieldState.error?.message}
                  />
                )}
              /> */}

              {/* <Controller
                name={"phone"}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <PhoneNumber
                    className="col-span-full"
                    size="lg"
                    label="Phone Number"
                    country="sg"
                    value={formatPhone(value, "+65")}
                    onChange={onChange}
                    // @ts-ignore
                    error={errors?.phone?.message as string}
                  />
                )}
              /> */}

              <div className="flex gap-3 w-full">
                <Input
                  label="First Name"
                  size="lg"
                  placeholder="Enter user's first name"
                  {...register("firstName")}
                  className="[&>label>span]:font-medium flex-1 basis-1/2"
                  error={errors.firstName?.message}
                />
                <Input
                  label="Last Name"
                  size="lg"
                  placeholder="Enter user's last name"
                  {...register("lastName")}
                  className="[&>label>span]:font-medium flex-1 basis-1/2"
                  error={errors.lastName?.message}
                />
              </div>

              {/* {!isMyProfile && mapOptions.length > 0 && (
                <Textarea
                  label="Header Script"
                  size="lg"
                  {...register("headerScript")}
                  rows={3}
                  className="[&>label>span]:font-medium"
                  error={form?.formState?.errors?.headerScript?.message}
                />
              )} */}

              {/* {!isMyProfile && mapOptions.length > 0 && (
                <Textarea
                  label="Body Script"
                  size="lg"
                  placeholder=""
                  {...register("bodyScript")}
                  className="[&>label>span]:font-medium "
                  error={errors.bodyScript?.message}
                />
              )} */}

              {/* {!isMyProfile && mapOptions.length > 0 && (
                <Textarea
                  label="Enquire Script"
                  size="lg"
                  {...register("enquiryScript")}
                  rows={3}
                  className="[&>label>span]:font-medium"
                  error={form?.formState?.errors?.enquiryScript?.message}
                />
              )} */}

              {!isMyProfile && profile?.role === Roles.ADMIN && (
                <Controller
                  control={control}
                  name="adRerportUrl"
                  render={({ field, fieldState, formState }) => (
                    <Input
                      label="Ad Report URL"
                      size="lg"
                      placeholder="Enter Ad Report URL"
                      className="col-span-full"
                      {...field}
                      {...formState}
                      value={field.value}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              )}

              {!isMyProfile &&
                profile?.role === Roles.ADMIN &&
                userHasDirectoryPermission && (
                  <>
                    <p>Custom Domain</p>
                    <SelectDomain
                      options={mapOptions}
                      defaultOptions={defaultOptions}
                      configID={configID || []}
                      reset={() => refreshPage()}
                    />
                  </>
                )}

              {isMyProfile && (
                <Controller
                  control={control}
                  name="photo"
                  render={({ field: { onChange } }) => (
                    <SingleImageUpload
                      imageUrl={watch("photo")?.url || ""}
                      title="Avatar"
                      setValue={onChange}
                      uploaderText="Upload Avatar photo"
                      uploadContainerClass="w-full h-fit md:w-1/3"
                      imageContainerClassName="rounded-full w-[120px] h-[120px] p-0"
                      imageClassName="rounded-full w-[120px] h-[120px] p-0 object-cover"
                      toolbarContainerClassName="top-[20px] end-[10px] [&>div]:-translate-x-[10px]"
                      dropZoneClassName="w-[120px] h-[120px] rounded-full"
                      uploadDropzoneContainerClass="w-fit"
                    />
                  )}
                />
              )}
            </div>

            <div className="sticky bottom-0  py-4 px-2 -mx-4 border-t border-gray-200 bg-white z-10 @lg:gap-4 @xl:grid @xl:auto-cols-max @xl:grid-flow-col col-span-full flex items-center justify-end gap-4">
              {backTo && (
                <Button
                  variant="outline"
                  onClick={handleBack}
                  className="w-full @xl:w-auto"
                >
                  Cancel
                </Button>
              )}
              <Button
                type="submit"
                isLoading={isLoading}
                disabled={!form?.formState.isDirty}
                className="w-full @xl:w-auto"
              >
                {title}
              </Button>
            </div>
          </div>
        );
      }}
    </Form>
  );
}
