import {
  ForgotPasswordDto,
  ForgotPasswordResponse,
  ResetPasswordDTO,
  SendOTPByPhone,
  SignInDto,
  SignInResponse,
  VerifyOtpDTO,
  VerifyTokenResponse,
} from "@/utilities/types/Auths.ts";
import { request } from "@/utilities/libs/request.ts";
import { IRequestArgs, IResponseData } from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import E_AUTH_PATHS from "@/utilities/enums/Auths.ts";
import { CreateUserDto } from "../types/Users";

function signUp(signUpDto: CreateUserDto) {
  return request<IResponseData<SignInResponse>>({
    method: ERequestMethods.POST,
    url: E_AUTH_PATHS.SIGN_UP,
    data: signUpDto,
  });
}

function signIn(signInDto: SignInDto) {
  return request<IResponseData<SignInResponse>>({
    method: ERequestMethods.POST,
    url: E_AUTH_PATHS.SIGN_IN,
    data: signInDto,
  });
}

function sendOTPByPhone(sendOTPData: SendOTPByPhone) {
  return request<IResponseData<SignInResponse>>({
    method: ERequestMethods.POST,
    url: E_AUTH_PATHS.SEND_OTP_PHONE, // /auth/send-otp-by-phone
    data: sendOTPData,
  });
}

function forgotPassword(forgotPasswordDto: ForgotPasswordDto) {
  return request<IResponseData<ForgotPasswordResponse>>({
    method: ERequestMethods.POST,
    url: E_AUTH_PATHS.FORGOT_PASSWORD,
    data: forgotPasswordDto,
  });
}

function verifyToken({ queryParams }: IRequestArgs) {
  return request<IResponseData<VerifyTokenResponse>>({
    method: ERequestMethods.GET,
    url: E_AUTH_PATHS.VERIFICATION_TOKEN,
    params: queryParams,
  });
}

function resetPassword(resetPasswordDto: ResetPasswordDTO) {
  return request<IResponseData<ForgotPasswordResponse>>({
    method: ERequestMethods.POST,
    url: E_AUTH_PATHS.RESET_PASSWORD,
    data: resetPasswordDto,
  });
}

function verifyOTP(verifyOtpDto: VerifyOtpDTO) {
  return request<IResponseData<SignInResponse>>({
    method: ERequestMethods.POST,
    url: E_AUTH_PATHS.VERIFY_OTP,
    data: verifyOtpDto,
  });
}

function adminSignInAgent(data: { email: string }) {
  return request<IResponseData<SignInResponse>>({
    method: ERequestMethods.POST,
    url: E_AUTH_PATHS.ADMIN_SIGNIN_AGENT,
    data,
  });
}

function signOut() {
  return request<void>({
    method: ERequestMethods.POST,
    url: E_AUTH_PATHS.SIGN_OUT,
  });
}

const AuthRepository = Object.freeze({
  signIn,
  signUp,
  forgotPassword,
  verifyToken,
  resetPassword,
  verifyOTP,
  adminSignInAgent,
  signOut,
  sendOTPByPhone,
});

export default AuthRepository;
