import { useState } from "react";
import { PiArrowRightBold } from "react-icons/pi";
import { Button, Checkbox, Input, Password } from "rizzui";
import { Form } from "@/components/ui/form";
import { routes } from "@/config/routes";
import { loginSchema, LoginSchema } from "@/utils/validators/login.schema";
import { Link } from "react-router-dom";
import { useAuthHelper } from "./helper";

export default function SignInForm() {
  const { sendOtp } = useAuthHelper();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (data: LoginSchema) => {
    if (isSubmitted) return; // Prevent further submissions after success
    setIsSubmitting(true);
    try {
      await sendOtp(data);
      setIsSubmitted(true); // Mark form as successfully submitted
    } catch (error) {
      console.error("Error during form submission", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Form<LoginSchema> validationSchema={loginSchema} onSubmit={handleSubmit}>
        {({ register, formState: { errors } }) => (
          <div className="space-y-5">
            <Input
              type="email"
              size="lg"
              label="Email"
              placeholder="Enter your email"
              className="[&>label>span]:font-medium"
              inputClassName="text-sm"
              {...register("email")}
              error={errors.email?.message}
            />
            <Password
              label="Password"
              placeholder="Enter your password"
              size="lg"
              className="[&>label>span]:font-medium"
              inputClassName="text-sm"
              {...register("password")}
              error={errors.password?.message}
            />
            <div className="flex items-center justify-between pb-2">
              <Checkbox
                {...register("rememberMe")}
                label="Remember Me"
                className="[&>label>span]:font-medium"
              />
              <Link
                to={routes.auth.forgotPassword}
                className="h-auto p-0 text-sm font-semibold text-blue underline transition-colors hover:text-gray-900 hover:no-underline"
              >
                Forget Password?
              </Link>
            </div>
            <Button
              className="w-full"
              type="submit"
              size="lg"
              disabled={isSubmitting || isSubmitted} // Disable on submitting or after successful submit
            >
              <span>{isSubmitting ? "Signing in..." : "Sign in"}</span>
              {!isSubmitting && !isSubmitted && (
                <PiArrowRightBold className="ms-2 mt-0.5 h-5 w-5" />
              )}
            </Button>
          </div>
        )}
      </Form>
    </>
  );
}
