import { Roles } from "@/utilities/types/Users";
import SignInForm from "./sign-in-form";
import AgentSignInForm from "./agent-sign-in-form";

export interface SignInFormRendererProps {
  signInAs: Roles;
}
function SignInFormRenderer({ signInAs }: SignInFormRendererProps) {
  return (
    <>
      {signInAs === Roles.ADMIN && <SignInForm />}
      {signInAs === Roles.AGENCY && <AgentSignInForm />}
    </>
  );
}

export default SignInFormRenderer;
