import { useModal } from "@/app/shared/modal-views/use-modal";
import QuillEditor from "@/components/ui/quille-editor/quill-editor";
import { DISCLAIMERS } from "@/config/constants";
import useApi from "@/hooks/useApi";
import { EDisclaimerKeys } from "@/utilities/enums/Disclaimer";
import DisclaimerRepository from "@/utilities/repositories/Disclaimer";
import {
  CreateDisclaimerInput,
  createDisclaimerSchema,
} from "@/utils/validators/disclaimer.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { PiXBold } from "react-icons/pi";
import { ActionIcon, Button, Textarea, Title } from "rizzui";

interface ICreateEditDisclaimerProps {
  disclaimerKey?: EDisclaimerKeys;
}

export default function CreateEditDisclaimer({
  disclaimerKey = EDisclaimerKeys.Disclaimer,
}: ICreateEditDisclaimerProps) {
  const title = `Edit ${DISCLAIMERS?.[disclaimerKey]?.label}`;
  const form = useForm({
    defaultValues: {
      value: "",
    },
    resolver: zodResolver(createDisclaimerSchema),
  });

  const { closeModal, isOpen } = useModal();

  const { loading: updating, request: updateDisclaimer } = useApi({
    request: DisclaimerRepository.updateDisclaimer,
  });

  const {
    loading: loading,
    request: getDisClaimer,
    response: disclaimerResponse,
  } = useApi({
    request: DisclaimerRepository.getDisClaimer,
  });

  const onSubmit = async (formData: CreateDisclaimerInput) => {
    try {
      await updateDisclaimer({
        ...formData,
        key: disclaimerKey,
      });
      toast.success("Success");
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    getDisClaimer(disclaimerKey);
  }, []);

  useEffect(() => {
    form.reset({
      value:
        typeof disclaimerResponse?.data?.value === "string"
          ? disclaimerResponse?.data?.value
          : "",
    });
  }, [disclaimerResponse?.data]);

  return (
    <form
      noValidate
      className="grid grid-cols-1 gap-6 p-0 lg:p-6 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className="col-span-full flex items-center justify-between">
        <Title as="h5" className="@xl:basis-auto text-xl md:text-2xl">
          {title}
        </Title>
        {isOpen && (
          <ActionIcon size="sm" variant="text" onClick={closeModal}>
            <PiXBold className="h-auto w-5" />
          </ActionIcon>
        )}
      </div>

      <Controller
        name="value"
        control={form.control}
        render={({ field, fieldState, formState }) => (
          // disclaimerKey === EDisclaimerKeys.FormNotice ? (
          //   <Textarea
          //     label="Form Notice"
          //     size="lg"
          //     placeholder="Enter form notice"
          //     className="col-span-full"
          //     labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5"
          //     {...field}
          //     {...formState}
          //     value={field.value}
          //     error={fieldState.error?.message}
          //   />
          // ) : (
          //   <QuillEditor
          //     value={field?.value}
          //     onChange={field?.onChange}
          //     label="Message"
          //     className="col-span-full [&_.ql-editor]:min-h-[200px]"
          //     labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5"
          //     error={form?.formState?.errors?.value?.message}
          //   />
          // )
          <QuillEditor
            value={field?.value}
            onChange={field?.onChange}
            label="Message"
            className="col-span-full [&_.ql-editor]:min-h-[200px]"
            labelClassName="font-medium text-gray-700 dark:text-gray-600 mb-1.5"
            error={form?.formState?.errors?.value?.message}
          />
        )}
      />

      <div className="col-span-full flex md:flex-row flex-col items-center justify-end gap-2">
        <Button
          variant="outline"
          onClick={closeModal}
          className="md:w-auto w-full"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          isLoading={loading || updating}
          className="md:w-auto w-full"
          disabled={!form?.formState?.isDirty}
        >
          {title}
        </Button>
      </div>
    </form>
  );
}
