import { request } from "@/utilities/libs/request.ts";
import {
  IRequestArgs,
  IResponseData,
  ListResponseData,
} from "@/utilities/types/requests.ts";
import { ERequestMethods } from "@/utilities/enums/RequestMethod.ts";
import {
  ILandingPageOption,
  SelectLandingPageOptionDTO,
} from "../types/LandingPageOption";
import { LANDINGPAGE_OPTIONS } from "../enums/LandingPageOption";

function getLandingPageOption({ queryParams }: IRequestArgs) {
  const query = {
    filter: queryParams?.query?.filter,
    ...queryParams?.pagination,
  };

  return request<ListResponseData<ILandingPageOption>>({
    method: ERequestMethods.GET,
    url: LANDINGPAGE_OPTIONS.GET,
    params: query,
  });
}

function attachOption(data: SelectLandingPageOptionDTO) {
  return request<IResponseData<ILandingPageOption>>({
    method: ERequestMethods.POST,
    url: LANDINGPAGE_OPTIONS.ATTACH,
    data,
  });
}

function activeOption(data: SelectLandingPageOptionDTO) {
  return request<IResponseData<ILandingPageOption>>({
    method: ERequestMethods.POST,
    url: LANDINGPAGE_OPTIONS.CHANGE_ACTIVE,
    data,
  });
}

const LandingPageOptionRepository = Object.freeze({
  getLandingPageOption,
  attachOption,
  activeOption,
});

export default LandingPageOptionRepository;
