import AuthWrapperFour from "@/app/shared/auth-layout/auth-wrapper-four";
import SignInFormRenderer from "@/components/auth/signin-form-renderer";

function SignIn() {
  return (
    <AuthWrapperFour title={<></>} isSignIn isSocialLoginActive={false}>
      {(signInAs) => {
        return <SignInFormRenderer signInAs={signInAs} />;
      }}
    </AuthWrapperFour>
  );
}

export default SignIn;
