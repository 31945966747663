import { useState } from "react";

import {
  CustomizeDomainState,
  ScriptModalState,
  UpdateItemData,
} from "../types";
import { ProjectLandingGridListingStates } from "../types";
import useApi from "@/hooks/useApi";
import { ILandingPage } from "@/utilities/types/LandingPage";
import { IResponseData } from "@/utilities/types/requests";
import LandingPagesRepository from "@/utilities/repositories/LandingPages";

interface useLdpActionModalsProps {
  onRefresh: () => void; // To refresh the data
}

export const useLdpActionModals = ({ onRefresh }: useLdpActionModalsProps) => {
  const [modifyModal, setModifyModal] =
    useState<ProjectLandingGridListingStates["modifyModal"]>(null);
  const [scriptsModal, setScriptsModal] = useState<ScriptModalState | null>(
    null
  );
  const [customizeDomain, setCustomizeDomain] =
    useState<CustomizeDomainState | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { request: updateLandingPage } = useApi<IResponseData<ILandingPage>>({
    request: LandingPagesRepository.updateLandingPage,
    enableToast: true,
  });

  const handleDomainModal = (data: CustomizeDomainState) => {
    setCustomizeDomain(data);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCustomizeDomain(null);
  };

  const onUpdateItem = async (id: string, data: UpdateItemData) => {
    try {
      await updateLandingPage(id, {
        name: data?.domainName,
        domain: data?.domain,
        headerScript: data?.headerScript,
        bodyScript: data?.bodyScript,
        enquiryScript: data?.enquiryScript,
        useEnquiryScript: data?.useEnquiryScript,
      });
      handleModalClose();
      await onRefresh();
    } catch (error) {
      console.log("Failed to update landing page");
    }
  };

  return {
    modifyModal,
    setModifyModal,
    scriptsModal,
    setScriptsModal,
    customizeDomain,
    isModalOpen,
    handleDomainModal,
    handleModalClose,
    onUpdateItem,
  };
};
