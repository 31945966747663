import useProfile from "@/hooks/use-profile";
import useApi from "@/hooks/useApi";
import LandingPageOptionRepository from "@/utilities/repositories/LandingPageOptions";
import LandingPagesRepository from "@/utilities/repositories/LandingPages";
import UserRepository from "@/utilities/repositories/Users";
import { ILandingPage } from "@/utilities/types/LandingPage";
import { IResponseData } from "@/utilities/types/requests";
import { Roles } from "@/utilities/types/Users";
import cn from "@/utils/class-names";
import * as React from "react";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Stepper } from "rizzui";
import DomainCustomization from "./create-update/DomainCustomization";
import SelectProjectLDP from "./create-update/SelectProjectLDP";
import TemplateSelection from "./create-update/TemplateSections";
import ProjectRepository from "@/utilities/repositories/Project";

export interface CreateLDPWithStepperProps {
  backTo?: string;
}

export default function CreateLDPWithStepper({
  backTo,
}: CreateLDPWithStepperProps) {
  const [currentStep, setCurrentStep] = React.useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const locationState = location.state;
  const form = useForm();
  const formValues = form.watch();
  const isLastStep = currentStep === 2;

  const { profile, setProfile } = useProfile();
  const { request: createLandingPage } = useApi<IResponseData<ILandingPage>>({
    request: LandingPagesRepository.createLandingPage,
    enableToast: true,
  });

  const afterSuccessfullyCreated = () => {
    backTo && navigate(backTo);
  };

  const handleAttachOption = async (optionId: string, ldpId: string) => {
    try {
      await LandingPageOptionRepository.attachOption({
        landingPageId: ldpId,
        layoutOptionId: optionId,
      });
      await LandingPageOptionRepository.activeOption({
        landingPageId: ldpId,
        layoutOptionId: optionId,
        active: true,
      });
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  const onCreateLDP = async ({
    domainName,
    domain,
    userId,
  }: {
    domainName: string;
    domain: string;
    userId: string;
  }) => {
    if (
      !formValues?.project?.isProjectExist &&
      profile?.role === Roles.AGENCY
    ) {
      await ProjectRepository.addUserProject(formValues?.project?.id);
    }
    await createLandingPage({
      domain,
      name: domainName,
      projectId: formValues?.project?.id || "",
      userDomainId: userId,
    })
      .then(async (res) => {
        await handleAttachOption(
          formValues?.option?.id || "",
          res?.data?.id || ""
        );

        if (!profile?.isFlowSignUp) {
          const response = await UserRepository.updateMe({
            isFlowSignUp: true,
          });
          if (profile && profile.role === Roles.AGENCY) {
            setProfile({
              ...profile,
              isFlowSignUp: true,
            });
          }
        }

        afterSuccessfullyCreated();
      })
      .catch((err: any) => {
        toast.error(err?.message || "Something went wrong");
      });
  };

  const isValid = useMemo(() => {
    if (currentStep === 0 && !formValues.project) {
      return false;
    }

    if (currentStep === 1 && !formValues.option) {
      return false;
    }

    if (currentStep === 2 && !formValues.domain) {
      return false;
    }

    return true;
  }, [currentStep, formValues]);

  const onNext = async () => {
    if (currentStep === 0 && !formValues.project) {
      toast.error("Please select a project");
      return;
    }

    if (currentStep === 1 && !formValues.option) {
      toast.error("Please select an option");
      return;
    }

    if (currentStep === 2 && !formValues.domain) {
      toast.error("Please select a domain");
      return;
    }

    if (!isLastStep) {
      setCurrentStep(currentStep + 1);
    }
    if (!isValid) {
      toast.error("Please fill all the fields");
      return;
    }
  };

  const onPrev = () => {
    if (currentStep === 0 && backTo) {
      return navigate(backTo);
    }
    setCurrentStep(currentStep - 1);
  };

  React.useEffect(() => {
    if (locationState?.shouldToastError) {
      toast.error(locationState?.message);
    }
  }, [locationState?.shouldToastError, locationState?.message]);

  return (
    <FormProvider {...form}>
      <div className="w-full relative flex flex-1 flex-col mt-8 min-h-[calc(100svh-32px)]">
        <Stepper
          className="px-4 max-w-screen-xl w-full  mx-auto "
          currentIndex={currentStep}
        >
          <Stepper.Step
            title="Project"
            description={form.getValues("project")?.name || "Pick your Project"}
          />
          <Stepper.Step
            title="Option"
            description={form.getValues("option")?.name || "Select an Option"}
          />
          <Stepper.Step title="Domain" description="Configure your domain" />
        </Stepper>

        <div className="max-w-screen-xl mx-auto w-full flex-1 h-auto overflow-auto">
          {currentStep === 0 && <SelectProjectLDP />}
          {currentStep === 1 && (
            <TemplateSelection
              onSelectTemplate={(template) => form.setValue("option", template)}
              activeOptionId={formValues?.option?.id || ""}
            />
          )}
          {currentStep === 2 && (
            <DomainCustomization
              projectId={formValues?.project?.id || ""}
              domainInitialValues={formValues.domain}
              onSave={async (values) => {
                await onCreateLDP({
                  domain: values.domain,
                  domainName: values.domainName,
                  userId: values.userId,
                });
              }}
            />
          )}
        </div>

        <div className="shadow-xl border-t border-gray-200 bg-white -mb-8  p-8 sticky bottom-0 left-0">
          <div className="flex justify-between gap-5">
            <Button
              className={cn(
                "",
                currentStep === 0 &&
                  (!backTo || locationState?.hideBack) &&
                  "invisible"
              )}
              onClick={() => {
                onPrev();
              }}
            >
              {currentStep === 0 && backTo ? "Back" : "Previous Step"}
            </Button>

            {!isLastStep && (
              <Button disabled={isLastStep && !isValid} onClick={onNext}>
                Next Step
              </Button>
            )}
          </div>
        </div>
      </div>
    </FormProvider>
  );
}
