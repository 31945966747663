import useApi from "@/hooks/useApi";
import SettingsRepository from "@/utilities/repositories/Settings";
import { IResponseData } from "@/utilities/types/requests";
import React, { useEffect, useMemo } from "react";
import { Input, InputProps } from "rizzui";
import debounce from "lodash/debounce";
import { SUFFIX_DOMAIN } from "@/config/constants";
import { FieldError } from "react-hook-form";
import { TLandingPageSchemaForm } from "@/utilities/validators/landing-page";

type IDomainInputProps = {
  label?: string;
  isUpdate?: boolean;
  configId?: string;
  setError: (name: keyof TLandingPageSchemaForm, error: FieldError) => void;
  suffix?: string;
} & InputProps;

export type Ref = HTMLInputElement;

const DomainInput = React.forwardRef<Ref, IDomainInputProps>(
  ({ value, label = "", setError, isUpdate, configId, ...rest }, ref) => {
    const {
      request: checkAvailableDomain,
      error,
      response,
    } = useApi<
      IResponseData<{
        data: null;
        message: string;
        statusCode: number;
        success: boolean;
      }>
    >({
      request: SettingsRepository.checkAvailableDomain,
    });

    const debouncedCheckAvailableDomain = useMemo(
      () =>
        debounce(async (value) => {
          if (!isUpdate) {
            setError("domain", { type: "manual", message: "" });
            return;
          }
          if (value) {
            const domainCheck = isUpdate ? value : `${value}${SUFFIX_DOMAIN}`;
            await checkAvailableDomain(domainCheck, configId);
          }
        }, 300),
      [checkAvailableDomain, configId, isUpdate, setError]
    );

    useEffect(() => {
      debouncedCheckAvailableDomain(value);
      // Cleanup the debounce on component unmount
      return () => {
        debouncedCheckAvailableDomain.cancel();
      };
    }, [value, debouncedCheckAvailableDomain, isUpdate]);

    useEffect(() => {
      if (typeof setError === "function" && !!error) {
        setError &&
          setError("domain", { type: "manual", message: error?.message });
      }
    }, [error, setError]);

    return (
      <div className="flex flex-col gap-1">
        <Input
          label={label}
          size="lg"
          ref={ref}
          placeholder="Enter your domain"
          className="col-span-full p-0"
          inputClassName="[&>input]:pe-0"
          value={value}
          {...rest}
        />
        {response?.statusCode === 200 && !error && (
          <div
            role="alert"
            className="text-green-700 text-[14px] font-bold rizzui-input-error-text"
          >
            This domain is available
          </div>
        )}
      </div>
    );
  }
);

export default DomainInput;
