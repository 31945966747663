import { IMAGES } from "@/assets/images";
import { useAuthHelper } from "@/components/auth/helper";
import ImageApp from "@/components/ui/image-app";
import useProfile from "@/hooks/use-profile";
import { FaWhatsapp } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import { Button, Text, Title } from "rizzui";

export default function ContactSupportPage() {
  const locationState = useLocation()?.state;
  const userProfile = useProfile();
  const { logOut } = useAuthHelper();

  const renderButton = () => {
    if (locationState?.key === "PaymentNotVerified") {
      return (
        <Button
          onClick={() => {
            logOut();
          }}
        >
          Sign out
        </Button>
      );
    }

    return (
      <Link to={"/"}>
        <Button>Back To Home</Button>
      </Link>
    );
  };

  return (
    <div className="max-w-screen-xl mx-auto min-h-screen w-full p-4 flex flex-col  items-center justify-center">
      <ImageApp
        className="max-w-[400px] w-full "
        src={IMAGES.CONTACT_SUPPORT}
        inApp
      />
      <Title as="h2" className="text-xl sm:text-2xl mb-4 text-center">
        {locationState?.title || "Contact Support"}
      </Title>
      <Text className="text-center text-sm sm:text-base">
        {locationState?.description ||
          "If you have any questions, please contact our support team."}
      </Text>

      <Button
        className={"mt-3 h-fit"}
        onClick={() => {
          window.open(
            `https://api.whatsapp.com/send/?phone=${"+6568505000"}&text=${encodeURIComponent(" ")}&type=phone_number&app_absent=0`,
            "_blank"
          );
        }}
      >
        <span className={"flex gap-2 items-center"}>
          <FaWhatsapp /> Whatsapp +65 6850 5000
        </span>
      </Button>

      <div className="mt-3">{renderButton()}</div>
    </div>
  );
}
