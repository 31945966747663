import { useModal } from "@/app/shared/modal-views/use-modal";
import SingleImageUpload from "@/components/ui/file-upload/single-image-upload";
import useApi from "@/hooks/useApi";
import PromotionBannerRepository from "@/utilities/repositories/PromotionBanner";
import { IPromotionBaner } from "@/utilities/types/PromotionBanner";
import {
  CreatePromotionBannerInput,
  createPromotionBannerSchema,
} from "@/utils/validators/promotion-banner.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { PiXBold } from "react-icons/pi";
import { ActionIcon, Button, Input, Textarea, Title } from "rizzui";

export interface CreateEditPromotionBannerProps {
  refetchData?: () => void;
  data?: IPromotionBaner;
  sectionId?: string;
}

export default function CreateEditPromotionBanner({
  refetchData,
  sectionId,
  data,
}: CreateEditPromotionBannerProps) {
  const isUpdate = !!data;
  const title = isUpdate ? "Edit Promotion Banner" : "Create Promotion Banner";
  const form = useForm({
    defaultValues: {
      name: "",
      url: "",
      description: "",
      photo: { id: "", url: "" },
    },
    resolver: zodResolver(createPromotionBannerSchema),
  });

  const { closeModal, isOpen } = useModal();

  const { loading, request: createPromotionBanner } = useApi({
    request: PromotionBannerRepository.createPromotionBanner,
  });
  const { loading: loadingUpdate, request: updatePromotionBanner } = useApi({
    request: PromotionBannerRepository.updatePromotionBanner,
  });

  const onSubmit = async (formData: CreatePromotionBannerInput) => {
    const dataToCreateOrUpdate = {
      name: formData?.name || "",
      photoId: formData?.photo?.id || "",
      description: formData?.description || "",
      url: formData?.url || "",
    };
    try {
      if (isUpdate) {
        await updatePromotionBanner(
          data?.sectionId,
          data.id,
          dataToCreateOrUpdate
        );
      } else {
        await createPromotionBanner(sectionId, dataToCreateOrUpdate);
      }
      toast.success("Success");
      refetchData?.();
      if (isOpen) {
        closeModal();
      }
    } catch (error: any) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    form.reset({
      name: typeof data?.name === "string" ? data?.name : data?.name?.en,
      url: data?.url,
      description:
        typeof data?.description === "string"
          ? data?.description
          : data?.description?.en,
      photo: {
        id: data?.photoId || "",
        url: data?.photo?.urls?.[0]?.url || "",
      },
    });
  }, [JSON.stringify(data)]);

  return (
    <form
      noValidate
      className="grid grid-cols-1 gap-6 p-6 @container md:grid-cols-2 [&_.rizzui-input-label]:font-medium [&_.rizzui-input-label]:text-gray-900"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className="col-span-full flex items-center justify-between">
        <Title as="h4" className="font-semibold">
          {title}
        </Title>
        {isOpen && (
          <ActionIcon size="sm" variant="text" onClick={closeModal}>
            <PiXBold className="h-auto w-5" />
          </ActionIcon>
        )}
      </div>
      <div className="[&>label>span]:font-medium  col-span-full">
        <Controller
          control={form.control}
          name="photo"
          render={({ field: { onChange } }) => (
            <SingleImageUpload
              imageUrl={form.watch("photo")?.url || ""}
              title="Photo"
              setValue={onChange}
              uploaderText="Upload logo"
              uploadContainerClass="w-full h-full md:w-1/3"
              error={form?.formState?.errors?.photo?.id?.message}
            />
          )}
        />
      </div>
      <Input
        label="Name"
        size="lg"
        placeholder="Enter name"
        {...form.register("name")}
        className="[&>label>span]:font-medium  col-span-full"
        error={form?.formState?.errors?.name?.message}
      />
      <Input
        label="Url"
        size="lg"
        placeholder="Enter url"
        {...form.register("url")}
        className="[&>label>span]:font-medium  col-span-full"
        error={form?.formState?.errors?.url?.message}
      />
      <Textarea
        label="Description"
        size="lg"
        placeholder="Enter description"
        {...form.register("description")}
        rows={3}
        className="[&>label>span]:font-medium  col-span-full"
        error={form?.formState?.errors?.description?.message}
      />
      <div className="col-span-full flex items-center justify-end gap-4">
        <Button variant="outline" onClick={closeModal} className="w-auto">
          Cancel
        </Button>
        <Button
          type="submit"
          isLoading={loading || loadingUpdate}
          className="w-auto"
          disabled={!form?.formState?.isDirty}
        >
          {title}
        </Button>
      </div>
    </form>
  );
}
