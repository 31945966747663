export const messages = {
  passwordOneUppercase: `The Password must contain at least one uppercase character`,
  passwordOneLowercase: `The Password must contain at least one lowercase character`,
  passwordOneNumeric: `The password must contain at least one numerical character.`,
  passwordRequired: "Password is required",
  passwordLengthMin: "Password must be at least 6 characters",
  passwordLengthMax: `Password can't be more than 32 characters`,
  newPasswordRequired: "New Password is required",
  newPasswordLength: "New Password must be at least 6 characters",
  confirmPasswordRequired: "Confirm Password is required",
  passwordsDidNotMatch: "Passwords don't match",
  nameIsRequired: "Name is required",
  firstNameRequired: "First name is required",
  phoneNumberIsRequired: "Phone Number is required",
  customerNameIsRequired: "Customer name is required",
  lastNameRequired: "Last name is required",
  streetIsRequired: "Street Address is required",
  emailIsRequired: "Email address is required",
  invalidEmail: "Invalid email address",
  roleIsRequired: "Role is required",
  permissionIsRequired: "Permission is required",
  teamIsRequired: "New member must be assigned to a team",
  productNameIsRequired: "Product name is required",
  productTypeIsRequired: "Product type is required",
  priceIsRequired: "Product price is required",
  retailPriceIsRequired: "Retail price is required",
  salePriceIsRequired: "Sale price is required",
  shippingPriceIsRequired: "Shipping price is required",
  cityIsRequired: "City is required",
  stateIsRequired: "State is required",
  countryIsRequired: "Country is required",
  addressLineOneRequired: "Address line 1 is required",
  zipCodeRequired: "ZIP code is required",
  cardHolderNameIsRequired: "Card holder name is required",
  cardNumberIsRequired: "Card Number is required",
  cardExpireIsRequired: "Expire Date is required",
  cvcNumberIsRequired: "CVC Number is required",
  catNameIsRequired: "Category name is required",
  slugIsRequired: "Slug is required",
  addressIsRequired: "Address is required",
  createDateIsRequired: "Create Date is required",
  dueDateIsRequired: "Due Date is required",
  statusIsRequired: "Status is required",
  discountIsRequired: "Discount amount is required",
  taxIsRequired: "Tax amount is required",
  itemNameIsRequired: "Item Name is required",
  itemDescIsRequired: "Item Description is required",
  itemQtyIsRequired: "Item Quantity is required",
  itemPriceIsRequired: "Item Price is required",
  fullNameIsRequired: "Full name is required",
  propertyTypeIsRequired: "Property type is required",
  placeTypeIsRequired: "Place type is required",
  amenitiesAreRequired: "Amenities are required",
  thisFieldIsRequired: "This Field is required",
  propertyNameIsRequired: "Property name is required",
  snippetNameIsRequired: "Snippet name is required",
  snippetDirIsRequired: "You must have to select a snippet folder",
  templateNameIsRequired: "Template name is required",
  templateDirIsRequired: "You must have to select a template folder",
  folderNameIsRequired: "Folder name is required",
  folderNameLengthMin: "Folder name must be at least 3 letters",
  productColorRequired: "Product Color is Required",
  productSizeRequired: "Product Size is Required",
  descriptionIsRequired: "Description is Required",
  locationIsRequired: "Location is Required",
  startDateIsRequired: "Start Date is required",
  startTimeIsRequired: "Start Time is required",
  endDateIsRequired: "End Date is required",
  endTimeIsRequired: "End Time is required",
  roleNameIsRequired: "Role Name is Required",
  roleNameLengthMin: "Role name must be at least 3 letters",
  errorSendingEmail: "Error sending email",
  emailSentSuccessfully: "Your email has been sent successfully.",
  siteTitleRequired: "Site title is required",
  siteDescriptionRequired: "Site description is required",
  colorNameRequired: "Color name is required",
  colorValueRequired: "Color value is required",
  socialUrlReqired: "Social url is required",
  socialLabelReqired: "Social lable is required",
  socialTypeReqired: "Social type is required",
  socialUrlInvalid: "Social url is required",
  unitType: "Unit Type is required",
  urlInvalid: "Url is invalid",
  urlRequired: "Url is required",
  fieldDuplicate: "This field is duplicated",
  fieldInLowercase: "This field must be in lowercase",
};
