import { Controller, useFormContext } from "react-hook-form";
import { Textarea, TextareaProps } from "rizzui";

export interface ScriptFormProps {
  visible?: {
    headerScript?: boolean;
    bodyScript?: boolean;
    enquiryScript?: boolean;
  };

  props?: {
    headerScript?: TextareaProps;
    bodyScript?: TextareaProps;
    enquiryScript?: TextareaProps;
  };
}

//  This component need to use in FormProvider react-hook-form
export default function ScriptsForm({
  visible = {
    bodyScript: true,
    headerScript: true,
    enquiryScript: true,
  },
  props,
}: ScriptFormProps) {
  const { control } = useFormContext();
  return (
    <div data-name="scripts" className="flex flex-col gap-6">
      {visible?.headerScript && (
        <div className="grid grid-cols-12 gap-6">
          <Controller
            control={control}
            name="headerScript"
            render={({ field, fieldState, formState }) => (
              <Textarea
                label="Head Scripts"
                size="lg"
                placeholder="Enter head scripts"
                className="col-span-full"
                {...field}
                {...formState}
                value={field.value || ""}
                error={fieldState?.error?.message || ""}
                {...props?.headerScript}
              />
            )}
          />
        </div>
      )}
      {visible?.bodyScript && (
        <div className="grid grid-cols-12 gap-6">
          <Controller
            control={control}
            name="bodyScript"
            render={({ field, fieldState, formState }) => (
              <Textarea
                label="Body Scripts"
                size="lg"
                placeholder="Enter body scripts"
                className="col-span-full"
                {...field}
                {...formState}
                value={field.value || ""}
                error={fieldState?.error?.message || ""}
                {...props?.bodyScript}
              />
            )}
          />
        </div>
      )}
      {visible?.enquiryScript && (
        <div className="grid grid-cols-12 gap-6">
          <Controller
            control={control}
            name="enquiryScript"
            render={({ field, fieldState, formState }) => (
              <Textarea
                label="Enquiry Scripts"
                size="lg"
                placeholder="Enter Enquiry scripts"
                className="col-span-full"
                {...field}
                {...formState}
                value={field.value || ""}
                error={fieldState?.error?.message || ""}
                {...props?.enquiryScript}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}
