import { ReactNode } from "react";

interface GridContainerProps {
  children: ReactNode;
}

export const GridContainer = ({ children }: GridContainerProps) => {
  return (
    <div className="grid grid-cols-1 auto-rows-fr xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-4">
      {children}
    </div>
  );
};
